import * as Sentry from '@sentry/react';
import * as Tracing from '@sentry/tracing';
import { env, sentryBeforeSend } from '@vette/common-utils';

export const setup = (applicationName: string) => {
  Sentry.init({
    dsn: env.checkEnvVarValue(process.env.NEXT_PUBLIC_SENTRY_DSN),
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    integrations: [new Tracing.Integrations.BrowserTracing()],
    beforeSend: sentryBeforeSend,
  });

  Sentry.setTag('application', applicationName);
};
