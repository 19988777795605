export const theme = {
  colors: {
    black: '#000000',
    black30: '#00000026',
    white: '#FFFFFF',
    grey: '#999999',
    white60: 'rgba(255,255,255, 0.6)',
    yellow: 'rgb(253,251,227)',

    brown40: '#8E3B1F',
    brown10: '#FCEDD8',

    violet40: '#763ED9',
    violet10: '#E1CFFF',

    yellow100: '#C49900',
    yellow90: '#D4A600',
    yellow80: '#EDB900',
    yellow70: '#FFC700',
    yellow60: '#FFD333',
    yellow50: '#FFDC5C',
    yellow40: '#FFE485',
    yellow30: '#FFEDAD',
    yellow20: '#FFF6D6',
    yellow10: '#FFFCF0',

    green100: '#0C1804',
    green90: '#0E2C05',
    green80: '#17420F',
    green70: '#275E1C',
    green60: '#387E2A',
    green50: '#499F38',
    green40: '#6DBE59',
    green30: '#8ED879',
    green20: '#BCEDAB',
    green10: '#E7F9DF',

    purple60: '#763ED9',
    purple40: '#A975FF',
    purple20: '#E9DDFF',
    purple10: '#F4F3FE',

    red100: '#2C0900',
    red90: '#510703',
    red80: '#741011',
    red70: '#A11B1C',
    red60: '#E52A2C',
    red50: '#F65448',
    red40: '#FB8777',
    red30: '#FDB5A9',
    red20: '#FED8D1',
    red10: '#FFF1EF',

    blue100: '#001241',
    blue90: '#00206E',
    blue80: '#0031A0',
    blue70: '#0047D1',
    blue60: '#005FF9',
    blue50: '#7482F9',
    blue40: '#A0A1F8',
    blue30: '#C3C1FA',
    blue20: '#E0DEFC',
    blue10: '#F4F3FE',

    grey100: '#161616',
    grey90: '#262626',
    grey80: '#393939',
    grey70: '#525252',
    grey60: '#6F6F6F',
    grey50: '#8D8D8D',
    grey40: '#A8A8A8',
    grey30: '#C6C6C6',
    grey20: '#E0E0E0',
    grey10: '#F4F4F4',
  },
  styles: {
    gradient: 'linear-gradient(306.46deg, #F8F8FF 34.04%, #FFFFFF 97.33%);',
    shadows: {
      basic:
        '0px 0px 6px 1px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04);',
      card: '0px 0px 24px -1px rgba(0, 0, 0, 0.05), 12px 24px 48px -1px rgba(0, 0, 0, 0.05);',
      modal:
        '0px 0px 40px -1px rgba(0, 0, 0, 0.06), 12px 40px 80px -1px rgba(0, 0, 0, 0.12);',
    },
    height: {
      small: '32px',
      medium: '40px',
      large: '48px',
    },
    zIndexPriority: {
      topOverMost: 999,
      topMost: 998,
      top: 99,
      topSlightly: 9,
      regular: 0,
      bottom: -1,
    },
  },
  config: {
    allowDarkMode: false,
  },
};
