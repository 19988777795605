import styled, { css } from 'styled-components';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Button as AntButton } from 'antd';
import baseTheme from '../../../../../baseAntd';

export type CustomButtonProps = {
  $fontSize?: number;
  $size?: SizeType;
  $iconMargin?: number;
  $flex?: boolean;
  $fullWidth?: boolean;
  $alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
};

export const Button = styled(AntButton)<CustomButtonProps>`
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
  border-radius: 12px;
  display: flex;
  ${({ $flex }) => {
    return (
      $flex &&
      css`
        flex: 1;
      `
    );
  }}

  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: ${({ $alignSelf }) => ($alignSelf ? $alignSelf : 'stretch')};

  box-shadow: none;

  border: none;

  ${({ type, theme }) => {
    switch (type) {
      case 'default':
        return css`
          border: 1px solid ${theme.colors.yellow60};
          background-color: ${theme.colors.white};
        `;
      case 'ghost':
        return css`
          & > * {
            color: ${({ theme }) => theme.colors.grey100};
          }
        `;
      case 'text':
        return css`
          color: ${({ theme }) => theme.colors.grey70};
        `;
      case 'link':
        return css`
          color: ${({ theme }) => theme.colors.grey70};
          text-decoration: underline;
          text-underline-offset: 2px;
        `;
    }

    return;
  }}

  &.ant-btn:not([disabled]):focus {
    border: none;
    ${({ type, theme }) => {
      switch (type) {
        case 'default':
          return css`
            color: ${theme.colors.grey100};
            background-color: ${theme.colors.yellow30};
            border: 1px solid ${theme.colors.yellow60};
          `;
        case 'ghost':
          return css`
            & > * {
              color: ${({ theme }) => theme.colors.grey100};
            }
          `;
      }

      return;
    }}
  }

  &.ant-btn:not([disabled]):hover {
    border: none;
    ${({ type, theme }) => {
      switch (type) {
        case 'primary':
          return css`
            background-color: ${baseTheme['@primary-color']};
          `;
        case 'default':
          return css`
            color: ${theme.colors.grey100};
            background-color: ${theme.colors.yellow20};
            border: 1px solid ${theme.colors.yellow60};
          `;
        case 'ghost':
          return css`
            & > * {
              color: ${({ theme }) => theme.colors.grey70};
            }
          `;
        case 'text':
          return css`
            background-color: #d9d9d9;
            color: ${({ theme }) => theme.colors.grey70};
          `;
        case 'link':
          return css`
            color: ${({ theme }) => theme.colors.grey70};
            text-decoration: underline;
          `;
      }

      return;
    }}
  }

  &.ant-btn[disabled] {
    color: ${({ theme }) => theme.colors.grey30};

    ${({ type, theme }) => {
      switch (type) {
        case 'primary':
          return css`
            background-color: ${theme.colors.yellow20};
          `;
        case 'ghost':
          return css`
            & > * {
              color: ${({ theme }) => theme.colors.grey30};
            }
          `;
        case 'default':
          return css`
            background-color: ${theme.colors.white};
          `;
        default:
          return css`
            color: ${theme.colors.grey30};
          `;
      }
    }}
  }

  ${({ $size, type }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
          padding: 0 25px;

          font-size: ${type === 'link' ? '12px' : '14px'};
        `;

      case 'middle':
        return css`
          height: 40px;
          padding: 0 12px;
          font-size: 15px;
        `;

      case 'large':
        return css`
          height: 48px;
          padding: 0 24px;
          font-size: 15px;
        `;
      default:
        return css`
          height: 40px;
        `;
    }
  }};

  font-size: ${({ $fontSize = 14 }) => $fontSize}px;

  ${({ $iconMargin }) => css`
    & > svg {
      margin-right: ${$iconMargin}px;
    }
  `}
`;
