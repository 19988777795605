import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Claims } from '../firebase';
import { ClientUserPermissionEnum } from '@vette/data-access';

// The actual svg was used because the icon is not available here https://mui.com/material-ui/material-icons/
const PayoutsIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.016 12C18.776 12 21.016 9.76 21.016 7C21.016 4.24 18.776 2 16.016 2C13.256 2 11.016 4.24 11.016 7C11.016 9.76 13.256 12 16.016 12ZM21.466 17.6C21.076 17.2 20.586 17 20.016 17H13.016L10.936 16.27L11.266 15.33L13.016 16H15.816C16.166 16 16.446 15.86 16.676 15.63C16.906 15.4 17.016 15.12 17.016 14.81C17.016 14.27 16.756 13.9 16.236 13.69L8.966 11H7.016V20L14.016 22L22.046 19C22.056 18.47 21.856 18 21.466 17.6ZM5.016 11H1V22H5.016V11Z"
      fill="currentColor"
    />
  </svg>
);

export type SideMenuType = {
  title: string;
  route: string;
  icon: React.ReactNode;
  bottom?: boolean;
  actionType?: string;
  shouldDisplay?: (context: { claims?: Claims }) => boolean;
}[];

export const ADMIN_MENU: SideMenuType = [
  { title: 'Dispatching', route: '/dispatching', icon: <SendIcon /> },
  {
    title: 'Clients',
    route: '/clients',
    icon: <ManageAccountsIcon />,
  },
  {
    title: 'Vetters',
    route: '/vetters',
    icon: <ManageAccountsIcon />,
  },
  { title: 'SMS conversations', route: '/sms', icon: <ChatIcon /> },
  { title: 'Activity history', route: '/activity', icon: <AssignmentIcon /> },
  {
    title: 'Logout',
    route: '/login',
    icon: <LogoutIcon />,
    bottom: true,
    actionType: 'logout',
  },
];

export const APPLICANT_MENU: SideMenuType = [];

export const CLIENT_MENU: SideMenuType = [
  { title: 'Dashboard', route: '/dashboard', icon: <DashboardIcon /> },
  { title: 'Scripts', route: '/scripts', icon: <DescriptionIcon /> },
  { title: 'Jobs', route: '/jobs', icon: <WorkIcon /> },
  { title: 'Applicants', route: '/applicants', icon: <GroupIcon /> },

  {
    title: 'Team Members',
    route: '/team-members',
    icon: <PersonAddAlt1Icon />,
    bottom: true,
    actionType: 'goToTeamMembers',
    shouldDisplay: ({ claims }) =>
      claims?.clientUserPermission === ClientUserPermissionEnum.MANAGE,
  },
  {
    title: 'Settings',
    route: '/settings',
    icon: <SettingsIcon />,
    bottom: true,
    actionType: 'goToSettings',
  },
  {
    title: 'Logout',
    route: '/login',
    icon: <LogoutIcon />,
    bottom: true,
    actionType: 'logout',
  },
];

export const VETTER_MENU: SideMenuType = [
  { title: 'Dashboard', route: '/dashboard', icon: <DashboardIcon /> },
  {
    title: 'Payouts',
    route: '/payouts',
    icon: PayoutsIcon,
  },
  { title: 'Settings', route: '/settings', icon: <SettingsIcon /> },
  {
    title: 'Logout',
    route: '/login',
    icon: <LogoutIcon />,
    bottom: true,
    actionType: 'logout',
  },
];
