import React from 'react';
import * as Sentry from '@sentry/react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';

import { getApolloClient } from '../apollo';
import { Provider as FirebaseAuthProvider } from '../firebase/auth/context';
import { QueryParamProviderComponent } from './QueryParamsProviderComponent';
import { HotjarProvider } from '../hotjar';

type CreateCustomAppOptions = {
  ErrorFallback?: React.ComponentType<{ error: Error }>;
  wrapComponent?: (node: React.ReactNode) => React.ReactNode;
};

export const createCustomApp = ({
  ErrorFallback,
  wrapComponent,
}: CreateCustomAppOptions) =>
  Sentry.withErrorBoundary(
    Sentry.withProfiler(({ Component, pageProps }: AppProps) => {
      const client = pageProps.apolloClient ?? getApolloClient();
      return (
        <FirebaseAuthProvider>
          <ApolloProvider client={client}>
            <QueryParamProviderComponent>
              <HotjarProvider>
                {wrapComponent ? (
                  wrapComponent(<Component {...pageProps} />)
                ) : (
                  <Component {...pageProps} />
                )}
              </HotjarProvider>
            </QueryParamProviderComponent>
          </ApolloProvider>
        </FirebaseAuthProvider>
      );
    }),
    {
      fallback: ({ error }) =>
        ErrorFallback ? (
          <ErrorFallback error={error} />
        ) : (
          <>Something went wrong</>
        ),
    }
  );
