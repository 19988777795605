import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  JSON: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  Upload: any;
  Void: any;
};

export type AcceptUserInviteInput = {
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  invitationKey: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type AdminUpdateSessionInput = {
  recommended?: Maybe<Scalars['Boolean']>;
  status?: Maybe<SessionStatusEnum>;
};

export type AdminUpdateVetterInput = {
  access?: Maybe<VetterAccessEnum>;
};

export type AdminUpsertClientInput = {
  availability: ClientAvailabilityEnum;
  companyName?: Maybe<Scalars['String']>;
  phones: Array<Scalars['String']>;
};

export type AdminUpsertClientLedgerRecordInput = {
  name?: Maybe<Scalars['String']>;
  numberOfSessions: Scalars['Int'];
};

/** Represents an Applicant */
export type Applicant = {
  __typename?: 'Applicant';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastSession?: Maybe<Session>;
  phone: Scalars['String'];
};

/** Returned to applicant if knockout questions not satisfied */
export type ApplicantDidNotPassKnockout = {
  __typename?: 'ApplicantDidNotPassKnockout';
  code: Scalars['Int'];
  sessionId: Scalars['ID'];
};

export enum ApplicantLogEventEnum {
  APPLICANT_SAW_INTERVIEW_NOW_PAGE = 'APPLICANT_SAW_INTERVIEW_NOW_PAGE',
  APPLICANT_SAW_INTERVIEW_NOW_PAGE_AGAIN = 'APPLICANT_SAW_INTERVIEW_NOW_PAGE_AGAIN'
}

/** Represents an applicant event log. */
export type ApplicantLogInput = {
  eventType: ApplicantLogEventEnum;
  jobOfferId: Scalars['String'];
};

export type ApplicationInput = {
  firstName: Scalars['String'];
  jobOfferId: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

/** Represents an attempt to interview for the same job twice */
export type AttemptedToInterviewAgainError = {
  __typename?: 'AttemptedToInterviewAgainError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Represents an action that is not permitted for actor */
export type AuthorizationError = {
  __typename?: 'AuthorizationError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Represents a company that's looking for applicants */
export type Client = {
  __typename?: 'Client';
  availability: ClientAvailabilityEnum;
  clientUsers: Array<User>;
  companyName?: Maybe<Scalars['String']>;
  consumedVettes: Scalars['Int'];
  disabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  remainingVettes: Scalars['Int'];
  sessionsCount: Scalars['Int'];
  totalVettes: Scalars['Int'];
};

export enum ClientAvailabilityEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

/** Represents data from client's dashboard */
export type ClientDashboard = {
  __typename?: 'ClientDashboard';
  available: Scalars['Int'];
  engaged: Scalars['Int'];
  passed: Scalars['Int'];
  vetted: Scalars['Int'];
};

/** Represents a client ledger record */
export type ClientLedgerRecord = {
  __typename?: 'ClientLedgerRecord';
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByAdminId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  numberOfSessions: Scalars['Int'];
};

export type ClientProcessApplicationsInput = {
  channel?: Maybe<Scalars['String']>;
  jobOfferId: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

/** Represents session data for a client */
export type ClientSessionDetailedData = {
  __typename?: 'ClientSessionDetailedData';
  applicant: Scalars['String'];
  channel: Scalars['String'];
  client?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  interviewer: Scalars['String'];
  job: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  passed: Scalars['Int'];
  phone: Scalars['String'];
  status: Scalars['String'];
  vetted: Scalars['Int'];
};

/** Represents session performance data for a client */
export type ClientSessionPerformanceData = {
  __typename?: 'ClientSessionPerformanceData';
  client?: Maybe<Scalars['String']>;
  externalInterviewer: Scalars['Int'];
  internalInterviewer: Scalars['Int'];
  job: Scalars['String'];
  passed: Scalars['Int'];
  vetted: Scalars['Int'];
};

export enum ClientUserPermissionEnum {
  INTERVIEW = 'INTERVIEW',
  MANAGE = 'MANAGE'
}

export type ClientUsersFilterInput = {
  /** Filter by client user permission */
  permission?: Maybe<ClientUserPermissionEnum>;
};

/** Represents an SMS conversation */
export type Conversation = {
  __typename?: 'Conversation';
  applicant?: Maybe<Applicant>;
  createdAt: Scalars['DateTime'];
  disabledAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  fromNumber: Scalars['String'];
  id: Scalars['ID'];
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageAt: Scalars['DateTime'];
  lastName?: Maybe<Scalars['String']>;
  marked: Scalars['Boolean'];
  messages: Array<Message>;
  parsedApplication?: Maybe<ParsedApplication>;
  parsedApplicationId?: Maybe<Scalars['ID']>;
  toNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Represents an SMS conversation group for bulk texting */
export type ConversationGroup = {
  __typename?: 'ConversationGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invocationsCount: Scalars['Int'];
  lastInvokedAt?: Maybe<Scalars['DateTime']>;
  /** Fetches all numbers descibed by `sql` from database */
  members: UnionConversationGroupMembersValidationError;
  name: Scalars['String'];
  sql: Scalars['String'];
};

/** Represents an SMS conversation group entity holding phone numbers */
export type ConversationGroupMembers = {
  __typename?: 'ConversationGroupMembers';
  phones: Array<Scalars['String']>;
};

export type CreateClientUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type CreateJobOfferInput = {
  additionalDescription: Scalars['String'];
  automatedSMSTextNotRecommended?: Maybe<Scalars['String']>;
  automatedSMSTextRecommended?: Maybe<Scalars['String']>;
  availability?: Maybe<JobOfferAvailabilityEnum>;
  clientOfClient?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailRecipients: Array<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  maximumRecommendedApplicants?: Maybe<Scalars['Int']>;
  remoteId?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  scriptId: Scalars['String'];
  supportEmails?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Represents a record of global activity history */
export type GlobalActivityHistory = {
  __typename?: 'GlobalActivityHistory';
  actor: Scalars['String'];
  createdAt: Scalars['DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
};

export type InternalVetterStatistics = {
  __typename?: 'InternalVetterStatistics';
  all?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  incomplete?: Maybe<Scalars['Int']>;
  passed?: Maybe<Scalars['Int']>;
};

export type InterviewNowInput = {
  activity: Array<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  knockoutAnswers?: Maybe<Array<ScriptAnswerInput>>;
  lastName: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  remoteId?: Maybe<Scalars['String']>;
};

export enum InvitationStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING'
}

export type InviteUsersInput = {
  email: Scalars['String'];
  /** Which permission invited user should have */
  permission: ClientUserPermissionEnum;
};

/**
 * Represents a hierarchical grouping of jobs. Can form a tree structure.
 *     Every Client has a root JobGroup, which is the parent of all other JobGroups.
 *     Every JobGroup has a path, which is a string representation of its position in the tree.
 *     Path is composed of names of groups separated by slashes.
 */
export type JobGroup = {
  __typename?: 'JobGroup';
  deletedAt?: Maybe<Scalars['DateTime']>;
  depth: Scalars['Int'];
  id: Scalars['ID'];
  jobOffer?: Maybe<JobOffer>;
  jobOfferId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type JobGroupList = {
  __typename?: 'JobGroupList';
  jobGroups: Array<JobGroup>;
};

/** Represents a job offer */
export type JobOffer = {
  __typename?: 'JobOffer';
  additionalDescription?: Maybe<Scalars['String']>;
  automatedSMSTextNotRecommended?: Maybe<Scalars['String']>;
  automatedSMSTextRecommended?: Maybe<Scalars['String']>;
  availability: JobOfferAvailabilityEnum;
  client: Client;
  clientOfClient?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  disabledAt?: Maybe<Scalars['DateTime']>;
  emailRecipients?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  instruction?: Maybe<Scalars['String']>;
  jobGroup: JobGroup;
  knockoutQuestions: Array<ScriptQuestion>;
  locations: Array<Scalars['String']>;
  maximumRecommendedApplicants?: Maybe<Scalars['Int']>;
  processingInboxEmail: Scalars['String'];
  remoteId?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  scriptId: Scalars['String'];
  supportEmails?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum JobOfferAvailabilityEnum {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  DISABLED = 'DISABLED'
}

/** Represents a job offer interview statistics */
export type JobOfferStatistics = {
  __typename?: 'JobOfferStatistics';
  engaged: Scalars['Int'];
  failed: Scalars['Int'];
  id: Scalars['ID'];
  interviewed: Scalars['Int'];
  passed: Scalars['Int'];
};

/** Represents error due to recommended applicants reached */
export type MaximumPassedApplicantsPerJobExceededError = {
  __typename?: 'MaximumPassedApplicantsPerJobExceededError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Represents a message in an SMS conversation */
export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status?: Maybe<MessageStatusEnum>;
  text: Scalars['String'];
  type: MessageTypeEnum;
};

export enum MessageStatusEnum {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

export enum MessageTypeEnum {
  RECIEVED = 'RECIEVED',
  SENT = 'SENT'
}

export enum ModerationStatusEnum {
  DISABLED = 'DISABLED',
  FLAGGED = 'FLAGGED',
  IN_PROGRESS = 'IN_PROGRESS',
  SKIPPED = 'SKIPPED',
  VERIFIED = 'VERIFIED',
  WAITING = 'WAITING'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvite?: Maybe<UnionUserValidationErrorNotFoundError>;
  acceptVetterSession?: Maybe<UnionNotFoundErrorValidationError>;
  addClientUsersAccess?: Maybe<Array<User>>;
  adminApproveVetter: User;
  adminAssignVetterToClient: User;
  adminCreateClient?: Maybe<UnionClientValidationError>;
  adminCreateClientLedgerRecord?: Maybe<UnionClientLedgerRecordValidationError>;
  adminDeleteClientLedgerRecord?: Maybe<UnionSuccessResultNotFoundError>;
  adminProcessApplication?: Maybe<UnionSuccessResultValidationErrorNotFoundError>;
  adminProcessApplications?: Maybe<Scalars['Void']>;
  adminTerminateSession: UnionSessionNotFoundError;
  adminUpdateClient?: Maybe<UnionClientValidationError>;
  adminUpdateClientLedgerRecord?: Maybe<UnionClientLedgerRecordNotFoundError>;
  adminUpdateSession: UnionSessionNotFoundError;
  adminUpdateVetter: UnionValidationErrorUserAuthorizationError;
  applicantLog?: Maybe<GlobalActivityHistory>;
  archiveScript?: Maybe<UnionNotFoundErrorValidationErrorScript>;
  atsProcessNewApplications?: Maybe<Scalars['Void']>;
  clientProcessApplications?: Maybe<UnionSuccessResultValidationError>;
  cloneJobGroup?: Maybe<JobGroupList>;
  createJobGroup?: Maybe<UnionJobGroupValidationError>;
  createJobOffer?: Maybe<UnionJobOfferValidationError>;
  createNewScriptVersion?: Maybe<UnionValidationErrorScript>;
  createScript?: Maybe<UnionValidationErrorScript>;
  deleteJobGroup?: Maybe<JobGroupList>;
  duplicateScript?: Maybe<UnionValidationErrorScript>;
  generateStripeUrl?: Maybe<UnionStripeUrlValidationError>;
  interviewNow?: Maybe<UnionSessionIdApplicantDidNotPassKnockoutSessionInProgressErrorAttemptedToInterviewAgainErrorWrongScriptVersionErrorValidationErrorMaximumPassedApplicantsPerJobExceededError>;
  inviteUsers?: Maybe<UnionSuccessResultValidationErrorNotFoundError>;
  loginUser?: Maybe<Scalars['String']>;
  logoutUser?: Maybe<Scalars['String']>;
  markAdminConversation?: Maybe<UnionConversationNotFoundError>;
  moveJobGroup?: Maybe<UnionJobGroupListValidationError>;
  rejectVetterSession?: Maybe<UnionNotFoundErrorValidationError>;
  removeClientUserAccess?: Maybe<User>;
  removeTeamMember?: Maybe<SuccessResult>;
  renameJobGroup?: Maybe<UnionJobGroupListValidationError>;
  resendSessionEmail?: Maybe<SuccessResult>;
  /** Sends a bulk text message to provided phone numbers. In case all text messages are successfully sent the mutation resolves in SuccessResult. If some of the messages fail to send the ValidationError is resolved and it contains failed phone numbers as a "fields" field and reason of failure in "messages" field (indexes of "fields" match 1 to 1 with "messages" field) */
  sendBulkSMS?: Maybe<UnionSuccessResultValidationError>;
  sendSMS: UnionValidationErrorSendSmsErrorMessage;
  setOnboardingCompleted: User;
  setStripeAccountConnected: StripeUser;
  signupVetter?: Maybe<UnionUserValidationError>;
  storeVetterAnswer: ScriptAnswer;
  submitVetterInterview?: Maybe<Scalars['Void']>;
  transcriptSession?: Maybe<Session>;
  unflagSession?: Maybe<Session>;
  updateJobOffer?: Maybe<UnionJobOfferValidationError>;
  updateSession?: Maybe<SuccessResult>;
  updateUser?: Maybe<UnionUserValidationError>;
  updateUserPhone?: Maybe<UnionUserValidationError>;
  updateUserPresence?: Maybe<UnionUserValidationError>;
  upsertAdminConversation?: Maybe<Conversation>;
  userAddPushNotificationToken: User;
  userDisablePushNotification: User;
  vetterLog?: Maybe<Scalars['Void']>;
  vetterUpdateSessionStatus: UnionSuccessResultNotFoundErrorValidationError;
};


export type MutationacceptUserInviteArgs = {
  input: AcceptUserInviteInput;
};


export type MutationacceptVetterSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationaddClientUsersAccessArgs = {
  clientId: Scalars['ID'];
  clientUserIds: Array<Scalars['ID']>;
  jobGroupPath: Scalars['String'];
};


export type MutationadminApproveVetterArgs = {
  vetterId: Scalars['ID'];
};


export type MutationadminAssignVetterToClientArgs = {
  clientId?: Maybe<Scalars['ID']>;
  vetterId: Scalars['ID'];
};


export type MutationadminCreateClientArgs = {
  input: AdminUpsertClientInput;
};


export type MutationadminCreateClientLedgerRecordArgs = {
  clientId: Scalars['ID'];
  input: AdminUpsertClientLedgerRecordInput;
};


export type MutationadminDeleteClientLedgerRecordArgs = {
  id: Scalars['ID'];
};


export type MutationadminProcessApplicationArgs = {
  application: ApplicationInput;
};


export type MutationadminProcessApplicationsArgs = {
  applications: Array<ApplicationInput>;
  smsContentTemplate: Scalars['String'];
};


export type MutationadminTerminateSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationadminUpdateClientArgs = {
  clientId: Scalars['ID'];
  input: AdminUpsertClientInput;
};


export type MutationadminUpdateClientLedgerRecordArgs = {
  id: Scalars['ID'];
  input: AdminUpsertClientLedgerRecordInput;
};


export type MutationadminUpdateSessionArgs = {
  input: AdminUpdateSessionInput;
  sessionId: Scalars['ID'];
};


export type MutationadminUpdateVetterArgs = {
  input: AdminUpdateVetterInput;
  vetterId: Scalars['ID'];
};


export type MutationapplicantLogArgs = {
  input: ApplicantLogInput;
};


export type MutationarchiveScriptArgs = {
  scriptId: Scalars['ID'];
};


export type MutationclientProcessApplicationsArgs = {
  applications: Array<ClientProcessApplicationsInput>;
  clientId: Scalars['ID'];
};


export type MutationcloneJobGroupArgs = {
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  parentGroupPath: Scalars['String'];
};


export type MutationcreateJobGroupArgs = {
  clientId: Scalars['ID'];
  name: Scalars['String'];
  parentGroupPath: Scalars['String'];
};


export type MutationcreateJobOfferArgs = {
  clientId: Scalars['ID'];
  input: CreateJobOfferInput;
  parentGroupId: Scalars['ID'];
};


export type MutationcreateNewScriptVersionArgs = {
  input: UpsertScriptInput;
  scriptId: Scalars['ID'];
};


export type MutationcreateScriptArgs = {
  clientId: Scalars['ID'];
  input: UpsertScriptInput;
};


export type MutationdeleteJobGroupArgs = {
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
};


export type MutationduplicateScriptArgs = {
  scriptId: Scalars['ID'];
};


export type MutationgenerateStripeUrlArgs = {
  userId: Scalars['ID'];
};


export type MutationinterviewNowArgs = {
  input: InterviewNowInput;
  jobOfferId: Scalars['ID'];
};


export type MutationinviteUsersArgs = {
  clientId: Scalars['ID'];
  users: Array<InviteUsersInput>;
};


export type MutationmarkAdminConversationArgs = {
  conversationId: Scalars['ID'];
  marked: Scalars['Boolean'];
};


export type MutationmoveJobGroupArgs = {
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  parentGroupPath: Scalars['String'];
};


export type MutationrejectVetterSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationremoveClientUserAccessArgs = {
  clientId: Scalars['ID'];
  clientUserId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
};


export type MutationremoveTeamMemberArgs = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationrenameJobGroupArgs = {
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  name: Scalars['String'];
};


export type MutationresendSessionEmailArgs = {
  additionalEmailRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  sessionId: Scalars['ID'];
};


export type MutationsendBulkSMSArgs = {
  body: Scalars['String'];
  conversationGroupId?: Maybe<Scalars['ID']>;
  phones: Array<Scalars['String']>;
};


export type MutationsendSMSArgs = {
  input: SendSMSInput;
};


export type MutationsetOnboardingCompletedArgs = {
  userId: Scalars['ID'];
};


export type MutationsetStripeAccountConnectedArgs = {
  userId: Scalars['ID'];
};


export type MutationsignupVetterArgs = {
  input: SignupVetterInput;
};


export type MutationstoreVetterAnswerArgs = {
  input: ScriptAnswerInput;
  sessionId: Scalars['ID'];
};


export type MutationsubmitVetterInterviewArgs = {
  input: SubmitVetterInterviewInput;
  sessionId: Scalars['ID'];
};


export type MutationtranscriptSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationunflagSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationupdateJobOfferArgs = {
  input: UpdateJobOfferInput;
  jobOfferId: Scalars['ID'];
};


export type MutationupdateSessionArgs = {
  input: UpdateSessionInput;
  sessionId: Scalars['ID'];
};


export type MutationupdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['ID'];
};


export type MutationupdateUserPhoneArgs = {
  firebaseUid: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationupdateUserPresenceArgs = {
  presence: UserPresenceEnum;
  userId: Scalars['ID'];
};


export type MutationupsertAdminConversationArgs = {
  phone: Scalars['String'];
};


export type MutationuserAddPushNotificationTokenArgs = {
  token: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationuserDisablePushNotificationArgs = {
  userId: Scalars['ID'];
};


export type MutationvetterLogArgs = {
  globalActivityHistoryEvent: VetterDebugLogEventsEnum;
  metadata?: Maybe<Scalars['JSON']>;
  userId: Scalars['ID'];
};


export type MutationvetterUpdateSessionStatusArgs = {
  newSessionStatus: SessionUpdateStatusEnum;
  sessionId: Scalars['ID'];
};

/** Represents a requested resource that doesn't exist */
export type NotFoundError = {
  __typename?: 'NotFoundError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type PaginatedConversation = {
  __typename?: 'PaginatedConversation';
  data: Array<Conversation>;
  pagination: PaginationResult;
};

export type PaginatedGlobalActivityHistory = {
  __typename?: 'PaginatedGlobalActivityHistory';
  data: Array<GlobalActivityHistory>;
  pagination: PaginationResult;
};

export type PaginatedSession = {
  __typename?: 'PaginatedSession';
  data: Array<Session>;
  pagination: PaginationResult;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  data: Array<User>;
  pagination: PaginationResult;
};

export type PaginationInput = {
  skip?: Scalars['Int'];
  take: Scalars['Int'];
};

/** Provides pagination information for list of records */
export type PaginationResult = {
  __typename?: 'PaginationResult';
  currentPage: Scalars['Int'];
  maxPage: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type ParsedApplication = {
  __typename?: 'ParsedApplication';
  id: Scalars['ID'];
  jobOffer: JobOffer;
  jobOfferId: Scalars['ID'];
  lastSession?: Maybe<Session>;
};

export type Query = {
  __typename?: 'Query';
  activityHistory?: Maybe<PaginatedGlobalActivityHistory>;
  client?: Maybe<UnionClientNotFoundError>;
  clientByInvitationKey?: Maybe<UnionClientNotFoundError>;
  clientDashboard: ClientDashboard;
  clientExportDetailedData: Array<ClientSessionDetailedData>;
  clientExportPerformanceData: Array<ClientSessionPerformanceData>;
  clientFieldOptions: Array<Scalars['String']>;
  clientJobsHierarchy?: Maybe<JobGroupList>;
  clientLedgerRecord: UnionClientLedgerRecordNotFoundError;
  clientLedgerRecords?: Maybe<Array<ClientLedgerRecord>>;
  clientUser?: Maybe<UnionUserNotFoundError>;
  clientUsers?: Maybe<Array<User>>;
  clientUsersWithAccessToJobGroup: Array<User>;
  clients?: Maybe<Array<Client>>;
  conversation?: Maybe<UnionConversationNotFoundError>;
  conversationGroup?: Maybe<UnionConversationGroupNotFoundError>;
  conversationGroups?: Maybe<Array<ConversationGroup>>;
  conversations?: Maybe<PaginatedConversation>;
  internalVetterStatistics?: Maybe<InternalVetterStatistics>;
  interviewer?: Maybe<UnionUserNotFoundError>;
  jobOffer?: Maybe<UnionJobOfferNotFoundError>;
  jobOfferStatistics: Array<JobOfferStatistics>;
  jobOffers?: Maybe<Array<JobOffer>>;
  lastSessions: Array<Session>;
  script?: Maybe<UnionScriptNotFoundError>;
  scripts?: Maybe<Array<Script>>;
  session?: Maybe<UnionSessionNotFoundError>;
  sessionScriptQuestions?: Maybe<UnionNotFoundErrorSessionScriptQuestions>;
  sessions?: Maybe<PaginatedSession>;
  sessionsStatistics: SessionsStatistics;
  /** Get your team members */
  teamMembers?: Maybe<PaginatedUser>;
  user?: Maybe<User>;
  userByFirebaseUid?: Maybe<User>;
  userWithPhoneExists?: Maybe<Scalars['Boolean']>;
  vetterDashboard?: Maybe<User>;
  vetters?: Maybe<PaginatedUser>;
  vettersCount: vettersCounts;
};


export type QueryactivityHistoryArgs = {
  pagination: PaginationInput;
};


export type QueryclientArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientByInvitationKeyArgs = {
  invitationKey: Scalars['String'];
};


export type QueryclientDashboardArgs = {
  clientId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};


export type QueryclientExportDetailedDataArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientExportPerformanceDataArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientFieldOptionsArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientJobsHierarchyArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientLedgerRecordArgs = {
  clientLedgerRecordId: Scalars['ID'];
};


export type QueryclientLedgerRecordsArgs = {
  clientId: Scalars['ID'];
};


export type QueryclientUserArgs = {
  id: Scalars['ID'];
};


export type QueryclientUsersArgs = {
  clientId: Scalars['ID'];
  filter?: Maybe<ClientUsersFilterInput>;
};


export type QueryclientUsersWithAccessToJobGroupArgs = {
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
};


export type QueryconversationArgs = {
  id: Scalars['ID'];
};


export type QueryconversationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryconversationsArgs = {
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryinternalVetterStatisticsArgs = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryinterviewerArgs = {
  id: Scalars['ID'];
};


export type QueryjobOfferArgs = {
  id: Scalars['ID'];
};


export type QueryjobOfferStatisticsArgs = {
  clientId: Scalars['ID'];
};


export type QueryjobOffersArgs = {
  clientId: Scalars['ID'];
};


export type QuerylastSessionsArgs = {
  filter?: Maybe<SessionStatusFilterEnum>;
};


export type QueryscriptArgs = {
  id: Scalars['ID'];
};


export type QueryscriptsArgs = {
  clientId: Scalars['ID'];
  status?: Maybe<ScriptStatusEnum>;
};


export type QuerysessionArgs = {
  id: Scalars['ID'];
};


export type QuerysessionScriptQuestionsArgs = {
  sessionId: Scalars['ID'];
};


export type QuerysessionsArgs = {
  clientId: Scalars['ID'];
  filter?: Maybe<SessionsFilterInput>;
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QuerysessionsStatisticsArgs = {
  clientId: Scalars['ID'];
  filter?: Maybe<SessionsFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryteamMembersArgs = {
  clientId: Scalars['ID'];
  filter?: Maybe<TeamMembersFilterInput>;
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryuserArgs = {
  userId: Scalars['ID'];
};


export type QueryuserByFirebaseUidArgs = {
  firebaseUid: Scalars['String'];
};


export type QueryuserWithPhoneExistsArgs = {
  input: UserWithPhoneExistsInput;
};


export type QueryvetterDashboardArgs = {
  userId: Scalars['ID'];
};


export type QueryvettersArgs = {
  filter?: Maybe<VetterApprovalStatusEnum>;
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryvettersCountArgs = {
  searchTerm?: Maybe<Scalars['String']>;
};

/** Represents a Script */
export type Script = {
  __typename?: 'Script';
  archivedAt?: Maybe<Scalars['DateTime']>;
  clientId: Scalars['String'];
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  isTemplate: Scalars['Boolean'];
  jobOffersCount: Scalars['Int'];
  name: Scalars['String'];
  questions: Array<ScriptQuestion>;
  recentVersion: Scalars['Int'];
  status: ScriptStatusEnum;
};


/** Represents a Script */
export type ScriptquestionsArgs = {
  filter?: Maybe<ScriptQuestionsFilterInput>;
};

/** Represents a vetter's answer to one question in our system. */
export type ScriptAnswer = {
  __typename?: 'ScriptAnswer';
  answer: Array<Scalars['String']>;
  id: Scalars['ID'];
  isCorrectAnswer?: Maybe<Scalars['Boolean']>;
  /** Script question this answer is answering. */
  question: ScriptQuestion;
  scriptQuestionId: Scalars['Int'];
};

export type ScriptAnswerInput = {
  answer: Array<Scalars['String']>;
  scriptQuestionId: Scalars['Int'];
};

/** Represents a Script's question */
export type ScriptQuestion = {
  __typename?: 'ScriptQuestion';
  /** Applicant answer to this question in a session */
  answer?: Maybe<ScriptAnswer>;
  description?: Maybe<Scalars['String']>;
  guide?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDealBreaker: Scalars['Boolean'];
  /** The knockout questions are presented to the applicant before the interview */
  isKnockout: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  position: Scalars['Int'];
  title: Scalars['String'];
};


/** Represents a Script's question */
export type ScriptQuestionanswerArgs = {
  sessionId: Scalars['ID'];
};

export type ScriptQuestionsFilterInput = {
  isKnockout?: Maybe<Scalars['Boolean']>;
};

export enum ScriptStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export type SendSMSInput = {
  body: Scalars['String'];
  conversationId: Scalars['String'];
  toNumber: Scalars['String'];
};

/** Represents a SMS that failed to send. */
export type SendSmsError = {
  __typename?: 'SendSmsError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Represents an interview between Applicant and Vetter */
export type Session = {
  __typename?: 'Session';
  activeDuration?: Maybe<Scalars['Int']>;
  /** Applicant being interviewed in this session */
  applicant: Applicant;
  applicantId: Scalars['ID'];
  callSid?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  /** Client user assigned to this session */
  clientUser?: Maybe<User>;
  clientUserId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Job offer for this session */
  jobOffer: JobOffer;
  jobOfferId: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  moderationStatus: ModerationStatusEnum;
  notes?: Maybe<Scalars['String']>;
  /** Session's script's questions */
  questions: Array<ScriptQuestion>;
  reasonForRecommendation?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  recordingSid?: Maybe<Scalars['String']>;
  /** Url with phone call recording */
  recordingUrl?: Maybe<Scalars['String']>;
  reportedIssueDetail?: Maybe<Scalars['String']>;
  reportedIssueType?: Maybe<SessionReportedIssueTypeEnum>;
  scriptVersion?: Maybe<Scalars['Int']>;
  /** Represent transcription status for particular session */
  sessionTranscription?: Maybe<SessionTranscription>;
  status?: Maybe<SessionStatusEnum>;
  /** Vetter interviewed this applicant */
  vetter?: Maybe<User>;
  vetterId?: Maybe<Scalars['ID']>;
};

export enum SessionFinishedStatusEnum {
  SESSION_FINISHED_SUCCESSFULLY = 'SESSION_FINISHED_SUCCESSFULLY',
  SESSION_FINISHED_WITH_AN_ISSUE = 'SESSION_FINISHED_WITH_AN_ISSUE'
}

/** Represents sessionId created after interviewNow invocation. */
export type SessionId = {
  __typename?: 'SessionId';
  id: Scalars['ID'];
};

/** Represents a session that is already in progress */
export type SessionInProgressError = {
  __typename?: 'SessionInProgressError';
  code: Scalars['Int'];
  sessionId: Scalars['ID'];
};

export enum SessionReportedIssueTypeEnum {
  APPLICANT_CANNOT_SPEAK_NOW = 'APPLICANT_CANNOT_SPEAK_NOW',
  APPLICANT_HANG_UP_UNEXPECTEDLY = 'APPLICANT_HANG_UP_UNEXPECTEDLY',
  CALL_LANDED_TO_APPLICANT_VOICEMAIL = 'CALL_LANDED_TO_APPLICANT_VOICEMAIL',
  COULD_NOT_HEAR_EACH_OTHER = 'COULD_NOT_HEAR_EACH_OTHER',
  OTHER = 'OTHER',
  VETTER_HANG_UP_UNEXPECTEDLY = 'VETTER_HANG_UP_UNEXPECTEDLY'
}

/** Represents a Session Script's questions */
export type SessionScriptQuestions = {
  __typename?: 'SessionScriptQuestions';
  questions: Array<ScriptQuestion>;
};

export enum SessionStatusEnum {
  APPLICANT_DID_NOT_PASS_KNOCKOUT = 'APPLICANT_DID_NOT_PASS_KNOCKOUT',
  APPLICANT_DID_NOT_PICKUP_CALL = 'APPLICANT_DID_NOT_PICKUP_CALL',
  APPLICANT_DROPPED_FROM_CALL = 'APPLICANT_DROPPED_FROM_CALL',
  APPLICANT_WAS_NOT_REACHABLE = 'APPLICANT_WAS_NOT_REACHABLE',
  CALL_DROPPED_TIMED_OUT = 'CALL_DROPPED_TIMED_OUT',
  LOOKING_FOR_VETTER = 'LOOKING_FOR_VETTER',
  LOOKING_FOR_VETTER_TIMED_OUT = 'LOOKING_FOR_VETTER_TIMED_OUT',
  SESSION_FINISHED_SUCCESSFULLY = 'SESSION_FINISHED_SUCCESSFULLY',
  SESSION_FINISHED_WITH_AN_ISSUE = 'SESSION_FINISHED_WITH_AN_ISSUE',
  SESSION_GLOBAL_TIMED_OUT = 'SESSION_GLOBAL_TIMED_OUT',
  SESSION_IN_PROGRESS = 'SESSION_IN_PROGRESS',
  SESSION_TERMINATED_BY_ADMIN = 'SESSION_TERMINATED_BY_ADMIN',
  VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL = 'VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL',
  VETTER_DID_NOT_PICKUP_CALL = 'VETTER_DID_NOT_PICKUP_CALL',
  VETTER_DROPPED_FROM_CALL = 'VETTER_DROPPED_FROM_CALL',
  VETTER_REJECTED_SESSION_REQUEST = 'VETTER_REJECTED_SESSION_REQUEST',
  VETTER_REQUEST_TIMED_OUT = 'VETTER_REQUEST_TIMED_OUT',
  WAITING_FOR_APPLICANT_TO_PICKUP_CALL = 'WAITING_FOR_APPLICANT_TO_PICKUP_CALL',
  WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST = 'WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST',
  WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP = 'WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP',
  WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP_TIMED_OUT = 'WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP_TIMED_OUT',
  WAITING_FOR_VETTER_TO_PICKUP_CALL = 'WAITING_FOR_VETTER_TO_PICKUP_CALL',
  WAITING_FOR_VETTER_TO_PRESS_DIGIT = 'WAITING_FOR_VETTER_TO_PRESS_DIGIT',
  WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK = 'WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK',
  WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK_TIMED_OUT = 'WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK_TIMED_OUT'
}

export enum SessionStatusFilterEnum {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type SessionTranscription = {
  __typename?: 'SessionTranscription';
  createdAt: Scalars['DateTime'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  result?: Maybe<Scalars['JSON']>;
  sessionId: Scalars['ID'];
};

export enum SessionUpdateStatusEnum {
  SESSION_IN_PROGRESS = 'SESSION_IN_PROGRESS',
  VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL = 'VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL',
  WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK = 'WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK'
}

export type SessionsFilterInput = {
  flagged?: Maybe<Scalars['Boolean']>;
  interviewers?: Maybe<Array<Scalars['String']>>;
  jobOfferId?: Maybe<Scalars['ID']>;
  recommended?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<SessionStatusEnum>>;
};

export type SessionsStatistics = {
  __typename?: 'SessionsStatistics';
  all: Scalars['Int'];
  failed: Scalars['Int'];
  flagged: Scalars['Int'];
  /** Stringified search parameters for Apollo Cache */
  id: Scalars['ID'];
  incomplete: Scalars['Int'];
  knockoutFailed: Scalars['Int'];
  passed: Scalars['Int'];
};

export type SignupVetterInput = {
  channel?: Maybe<Scalars['String']>;
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

/** Represents a object with url to Stripe. */
export type StripeUrl = {
  __typename?: 'StripeUrl';
  url?: Maybe<Scalars['String']>;
};

/** Represents object describing Stripe connected account. */
export type StripeUser = {
  __typename?: 'StripeUser';
  credit: Scalars['Float'];
  id: Scalars['ID'];
  stripeAccountConnected: Scalars['Boolean'];
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeDashboardUrl?: Maybe<Scalars['String']>;
  user: User;
};

export type SubmitVetterInterviewInput = {
  answers: Array<ScriptAnswerInput>;
  newStatus: SessionFinishedStatusEnum;
  notes?: Maybe<Scalars['String']>;
  reasonForRecommendation?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  reportedIssueDetail?: Maybe<Scalars['String']>;
  reportedIssueType?: Maybe<SessionReportedIssueTypeEnum>;
};

export type Subscription = {
  __typename?: 'Subscription';
  clientUpdated?: Maybe<Client>;
  conversationsListUpdated?: Maybe<Conversation>;
  globalActivityHistoryAdded?: Maybe<GlobalActivityHistory>;
  sessionChanged?: Maybe<Session>;
  vetterUpdated?: Maybe<User>;
};


export type SubscriptionclientUpdatedArgs = {
  clientId?: Maybe<Scalars['ID']>;
};


export type SubscriptionvetterUpdatedArgs = {
  userId?: Maybe<Scalars['ID']>;
};

/** Represents the result of a successful request */
export type SuccessResult = {
  __typename?: 'SuccessResult';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type TeamMembersFilterInput = {
  /** Filter by client user permission */
  permission?: Maybe<ClientUserPermissionEnum>;
};

export type UnionClientLedgerRecordNotFoundError = ClientLedgerRecord | NotFoundError;

export type UnionClientLedgerRecordValidationError = ClientLedgerRecord | ValidationError;

export type UnionClientNotFoundError = Client | NotFoundError;

export type UnionClientValidationError = Client | ValidationError;

export type UnionConversationGroupMembersValidationError = ConversationGroupMembers | ValidationError;

export type UnionConversationGroupNotFoundError = ConversationGroup | NotFoundError;

export type UnionConversationNotFoundError = Conversation | NotFoundError;

export type UnionJobGroupListValidationError = JobGroupList | ValidationError;

export type UnionJobGroupValidationError = JobGroup | ValidationError;

export type UnionJobOfferNotFoundError = JobOffer | NotFoundError;

export type UnionJobOfferValidationError = JobOffer | ValidationError;

export type UnionNotFoundErrorSessionScriptQuestions = NotFoundError | SessionScriptQuestions;

export type UnionNotFoundErrorValidationError = NotFoundError | ValidationError;

export type UnionNotFoundErrorValidationErrorScript = NotFoundError | Script | ValidationError;

export type UnionScriptNotFoundError = NotFoundError | Script;

export type UnionSessionIdApplicantDidNotPassKnockoutSessionInProgressErrorAttemptedToInterviewAgainErrorWrongScriptVersionErrorValidationErrorMaximumPassedApplicantsPerJobExceededError = ApplicantDidNotPassKnockout | AttemptedToInterviewAgainError | MaximumPassedApplicantsPerJobExceededError | SessionId | SessionInProgressError | ValidationError | WrongScriptVersionError;

export type UnionSessionNotFoundError = NotFoundError | Session;

export type UnionStripeUrlValidationError = StripeUrl | ValidationError;

export type UnionSuccessResultNotFoundError = NotFoundError | SuccessResult;

export type UnionSuccessResultNotFoundErrorValidationError = NotFoundError | SuccessResult | ValidationError;

export type UnionSuccessResultValidationError = SuccessResult | ValidationError;

export type UnionSuccessResultValidationErrorNotFoundError = NotFoundError | SuccessResult | ValidationError;

export type UnionUserNotFoundError = NotFoundError | User;

export type UnionUserValidationError = User | ValidationError;

export type UnionUserValidationErrorNotFoundError = NotFoundError | User | ValidationError;

export type UnionValidationErrorScript = Script | ValidationError;

export type UnionValidationErrorSendSmsErrorMessage = Message | SendSmsError | ValidationError;

export type UnionValidationErrorUserAuthorizationError = AuthorizationError | User | ValidationError;

export type UpdateClientInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateJobOfferInput = {
  additionalDescription: Scalars['String'];
  automatedSMSTextNotRecommended?: Maybe<Scalars['String']>;
  automatedSMSTextRecommended?: Maybe<Scalars['String']>;
  availability?: Maybe<JobOfferAvailabilityEnum>;
  clientOfClient?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailRecipients: Array<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  maximumRecommendedApplicants?: Maybe<Scalars['Int']>;
  remoteId?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  scriptId: Scalars['String'];
  supportEmails?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type UpdateSessionInput = {
  moderationStatus?: Maybe<ModerationStatusEnum>;
  notes?: Maybe<Scalars['String']>;
  reasonForRecommendation?: Maybe<Scalars['String']>;
  reportedIssueDetail?: Maybe<Scalars['String']>;
  reportedIssueType?: Maybe<SessionReportedIssueTypeEnum>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoHigh?: Maybe<Scalars['Upload']>;
  photoLow?: Maybe<Scalars['Upload']>;
  photoOriginal?: Maybe<Scalars['Upload']>;
  residence?: Maybe<Scalars['String']>;
};

export type UpdateVetterInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  photoHigh?: Maybe<Scalars['Upload']>;
  photoLow?: Maybe<Scalars['Upload']>;
  photoOriginal?: Maybe<Scalars['Upload']>;
  residence?: Maybe<Scalars['String']>;
};

export type UpsertScriptInput = {
  introduction?: Maybe<Scalars['String']>;
  isTemplate: Scalars['Boolean'];
  name: Scalars['String'];
  questions: Array<UpsertScriptQuestionInput>;
};

export type UpsertScriptQuestionInput = {
  description?: Maybe<Scalars['String']>;
  guide?: Maybe<Scalars['String']>;
  isDealBreaker?: Maybe<Scalars['Boolean']>;
  /** The knockout questions are presented to the applicant before the interview */
  isKnockout?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  position: Scalars['Int'];
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  access: VetterAccessEnum;
  allVetsCount: Scalars['Int'];
  approvalStatus: VetterApprovalStatusEnum;
  approvedAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  clientUserPermission?: Maybe<ClientUserPermissionEnum>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitationKey?: Maybe<Scalars['String']>;
  invitationStatus?: Maybe<InvitationStatusEnum>;
  /** Is currently logged in user? */
  isMe: Scalars['Boolean'];
  /** Whether the user could navigate through app pages. */
  isProfileDataProvided: Scalars['Boolean'];
  /** Whether the interviewer can switch to online. */
  isProfilePhotoProvided: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  /** Path where user has access to. Provided when we call "clientUsersWithAccessToJobGroup". */
  path?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoHigh?: Maybe<Scalars['String']>;
  photoHighUrl?: Maybe<Scalars['String']>;
  photoLow?: Maybe<Scalars['String']>;
  photoLowUrl?: Maybe<Scalars['String']>;
  photoOriginal?: Maybe<Scalars['String']>;
  photoOriginalUrl?: Maybe<Scalars['String']>;
  presence?: Maybe<UserPresenceEnum>;
  pushNotificationsEnabled: Scalars['Boolean'];
  residence?: Maybe<Scalars['String']>;
  role: UserRoleEnum;
  stripeUser?: Maybe<StripeUser>;
};

export enum UserPresenceEnum {
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  VETTER = 'VETTER'
}

export type UserWithPhoneExistsInput = {
  phone: Scalars['String'];
  /** Find user existance under particular role. Appended with admin. */
  userRole?: Maybe<UserRoleEnum>;
};

/** Represents an invocation with unexpected parameter values */
export type ValidationError = {
  __typename?: 'ValidationError';
  code: Scalars['Int'];
  fields: Array<Scalars['String']>;
  messages: Array<Scalars['String']>;
};

export enum VetterAccessEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED'
}

export enum VetterApprovalStatusEnum {
  ACTIVE = 'ACTIVE',
  AWAITING = 'AWAITING'
}

export enum VetterCountFilterEnum {
  AWAITING = 'AWAITING'
}

/** Events the Vetter wants to log when encountered on frontend */
export enum VetterDebugLogEventsEnum {
  VETTER_PROCEEDED_TO_EVALUATE_APPLICANT = 'VETTER_PROCEEDED_TO_EVALUATE_APPLICANT',
  VETTER_SAW_APPLICANT_CONNECTED_TO_SESSION_CONFIRMATION_MODAL = 'VETTER_SAW_APPLICANT_CONNECTED_TO_SESSION_CONFIRMATION_MODAL',
  VETTER_SAW_SESSION_REQUEST_MODAL = 'VETTER_SAW_SESSION_REQUEST_MODAL',
  VETTER_SAW_SESSION_REQUEST_TIMED_OUT_MODAL = 'VETTER_SAW_SESSION_REQUEST_TIMED_OUT_MODAL',
  VETTER_SAW_SESSION_SCRIPT_PAGE = 'VETTER_SAW_SESSION_SCRIPT_PAGE'
}

/** Returned to applicant if knockout questions belong to an old script version. The query that obtained this version should be refetched. */
export type WrongScriptVersionError = {
  __typename?: 'WrongScriptVersionError';
  code: Scalars['Int'];
  recentScriptVersionId: Scalars['ID'];
};

export type vettersCounts = {
  __typename?: 'vettersCounts';
  Active?: Maybe<Scalars['Int']>;
  All?: Maybe<Scalars['Int']>;
  Awaiting?: Maybe<Scalars['Int']>;
};

export type ConversationDataFragment = { __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean };

export type ConversationDetailedDataFragment = { __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean, parsedApplication?: { __typename?: 'ParsedApplication', jobOfferId: string, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined } | null | undefined } | null | undefined, applicant?: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined } | null | undefined, messages: Array<{ __typename?: 'Message', id: string, createdAt: any, text: string, type: MessageTypeEnum, status?: MessageStatusEnum | null | undefined }> };

export type MessageDetailDataFragment = { __typename?: 'Message', id: string, createdAt: any, text: string, type: MessageTypeEnum, status?: MessageStatusEnum | null | undefined };

export type ActivityDataFragment = { __typename?: 'GlobalActivityHistory', id: string, actor: string, createdAt: any, event: string, metadata: any };

export type AdminVettersFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone?: string | null | undefined, presence?: UserPresenceEnum | null | undefined, access: VetterAccessEnum, approvalStatus: VetterApprovalStatusEnum, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, residence?: string | null | undefined, clientId?: string | null | undefined, stripeUser?: { __typename?: 'StripeUser', id: string, credit: number } | null | undefined };

export type SessionDispatchingDataFragment = { __typename?: 'Session', id: string, createdAt: any, status?: SessionStatusEnum | null | undefined, activeDuration?: number | null | undefined, canceled?: boolean | null | undefined, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, phone: string, firstName?: string | null | undefined, lastName?: string | null | undefined }, jobOffer: { __typename?: 'JobOffer', id: string, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, vetter?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone?: string | null | undefined } | null | undefined };

export type AdminUpdateSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  input: AdminUpdateSessionInput;
}>;


export type AdminUpdateSessionMutation = { __typename?: 'Mutation', adminUpdateSession: { __typename?: 'NotFoundError' } | { __typename?: 'Session', id: string, recommended?: boolean | null | undefined } };

export type UnflagSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type UnflagSessionMutation = { __typename?: 'Mutation', unflagSession?: { __typename?: 'Session', id: string, moderationStatus: ModerationStatusEnum } | null | undefined };

export type SendMessageMutationVariables = Exact<{
  input: SendSMSInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendSMS: { __typename?: 'Message', id: string, createdAt: any, text: string, type: MessageTypeEnum, status?: MessageStatusEnum | null | undefined } | { __typename?: 'SendSmsError', message: string, code: number } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } };

export type SendBulkMessageMutationVariables = Exact<{
  body: Scalars['String'];
  phones: Array<Scalars['String']> | Scalars['String'];
  conversationGroupId?: Maybe<Scalars['ID']>;
}>;


export type SendBulkMessageMutation = { __typename?: 'Mutation', sendBulkSMS?: { __typename?: 'SuccessResult', message: string } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string> } | null | undefined };

export type MarkConversationMutationVariables = Exact<{
  conversationId: Scalars['ID'];
  marked: Scalars['Boolean'];
}>;


export type MarkConversationMutation = { __typename?: 'Mutation', markAdminConversation?: { __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean } | { __typename?: 'NotFoundError', message: string } | null | undefined };

export type VetterSetAccessMutationVariables = Exact<{
  vetterId: Scalars['ID'];
  input: AdminUpdateVetterInput;
}>;


export type VetterSetAccessMutation = { __typename?: 'Mutation', adminUpdateVetter: { __typename?: 'AuthorizationError', message: string, code: number } | { __typename?: 'User', id: string, access: VetterAccessEnum, presence?: UserPresenceEnum | null | undefined } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } };

export type AdminCreateClientMutationVariables = Exact<{
  input: AdminUpsertClientInput;
}>;


export type AdminCreateClientMutation = { __typename?: 'Mutation', adminCreateClient?: { __typename?: 'Client', id: string, companyName?: string | null | undefined } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type AdminUpdateClientMutationVariables = Exact<{
  input: AdminUpsertClientInput;
  clientId: Scalars['ID'];
}>;


export type AdminUpdateClientMutation = { __typename?: 'Mutation', adminUpdateClient?: { __typename?: 'Client', id: string, companyName?: string | null | undefined, sessionsCount: number, disabledAt?: any | null | undefined, availability: ClientAvailabilityEnum, clientUsers: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone?: string | null | undefined }> } | { __typename?: 'ValidationError', messages: Array<string>, fields: Array<string>, code: number } | null | undefined };

export type AdminApproveVetterMutationVariables = Exact<{
  vetterId: Scalars['ID'];
}>;


export type AdminApproveVetterMutation = { __typename?: 'Mutation', adminApproveVetter: { __typename?: 'User', id: string, approvalStatus: VetterApprovalStatusEnum } };

export type UpsertAdminConversationMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type UpsertAdminConversationMutation = { __typename?: 'Mutation', upsertAdminConversation?: { __typename?: 'Conversation', id: string, toNumber: string, fromNumber: string, marked: boolean } | null | undefined };

export type AdminCreateClientLedgerRecordMutationVariables = Exact<{
  clientId: Scalars['ID'];
  input: AdminUpsertClientLedgerRecordInput;
}>;


export type AdminCreateClientLedgerRecordMutation = { __typename?: 'Mutation', adminCreateClientLedgerRecord?: { __typename?: 'ClientLedgerRecord', id: string } | { __typename?: 'ValidationError', messages: Array<string> } | null | undefined };

export type AdminUpdateClientLedgerRecordMutationVariables = Exact<{
  clientLedgerRecordId: Scalars['ID'];
  input: AdminUpsertClientLedgerRecordInput;
}>;


export type AdminUpdateClientLedgerRecordMutation = { __typename?: 'Mutation', adminUpdateClientLedgerRecord?: { __typename?: 'ClientLedgerRecord', id: string, name?: string | null | undefined, numberOfSessions: number, createdByAdminId?: string | null | undefined } | { __typename?: 'NotFoundError', message: string } | null | undefined };

export type AdminDeleteClientLedgerRecordMutationVariables = Exact<{
  clientLedgerRecordId: Scalars['ID'];
}>;


export type AdminDeleteClientLedgerRecordMutation = { __typename?: 'Mutation', adminDeleteClientLedgerRecord?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'SuccessResult' } | null | undefined };

export type AdminAssignVetterToClientMutationVariables = Exact<{
  vetterId: Scalars['ID'];
  clientId?: Maybe<Scalars['ID']>;
}>;


export type AdminAssignVetterToClientMutation = { __typename?: 'Mutation', adminAssignVetterToClient: { __typename?: 'User', id: string, clientId?: string | null | undefined } };

export type AdminProcessApplicationMutationVariables = Exact<{
  application: ApplicationInput;
}>;


export type AdminProcessApplicationMutation = { __typename?: 'Mutation', adminProcessApplication?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'SuccessResult', message: string } | { __typename?: 'ValidationError', messages: Array<string> } | null | undefined };

export type AdminProcessApplicationsMutationVariables = Exact<{
  smsContentTemplate: Scalars['String'];
  applications: Array<ApplicationInput> | ApplicationInput;
}>;


export type AdminProcessApplicationsMutation = { __typename?: 'Mutation', adminProcessApplications?: any | null | undefined };

export type AdminTerminateSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type AdminTerminateSessionMutation = { __typename?: 'Mutation', adminTerminateSession: { __typename?: 'NotFoundError' } | { __typename?: 'Session', id: string, createdAt: any, status?: SessionStatusEnum | null | undefined } };

export type ActivityQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type ActivityQuery = { __typename?: 'Query', activityHistory?: { __typename?: 'PaginatedGlobalActivityHistory', data: Array<{ __typename?: 'GlobalActivityHistory', id: string, actor: string, createdAt: any, event: string, metadata: any }>, pagination: { __typename?: 'PaginationResult', total: number } } | null | undefined };

export type ConversationsQueryVariables = Exact<{
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type ConversationsQuery = { __typename?: 'Query', conversations?: { __typename?: 'PaginatedConversation', data: Array<{ __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean }>, pagination: { __typename?: 'PaginationResult', total: number } } | null | undefined };

export type ConversationGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversationGroupsQuery = { __typename?: 'Query', conversationGroups?: Array<{ __typename?: 'ConversationGroup', id: string, name: string }> | null | undefined };

export type SingleConversationGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SingleConversationGroupQuery = { __typename?: 'Query', conversationGroup?: { __typename?: 'ConversationGroup', id: string, description?: string | null | undefined, lastInvokedAt?: any | null | undefined, invocationsCount: number, members: { __typename?: 'ConversationGroupMembers', phones: Array<string> } | { __typename?: 'ValidationError', messages: Array<string> } } | { __typename?: 'NotFoundError' } | null | undefined };

export type ConversationQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type ConversationQuery = { __typename?: 'Query', conversation?: { __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean, parsedApplication?: { __typename?: 'ParsedApplication', jobOfferId: string, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined } | null | undefined } | null | undefined, applicant?: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined } | null | undefined, messages: Array<{ __typename?: 'Message', id: string, createdAt: any, text: string, type: MessageTypeEnum, status?: MessageStatusEnum | null | undefined }> } | { __typename?: 'NotFoundError' } | null | undefined };

export type VettersQueryVariables = Exact<{
  filter?: Maybe<VetterApprovalStatusEnum>;
  pagination: PaginationInput;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type VettersQuery = { __typename?: 'Query', vettersCount: { __typename?: 'vettersCounts', Awaiting?: number | null | undefined, All?: number | null | undefined, Active?: number | null | undefined }, vetters?: { __typename?: 'PaginatedUser', data: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone?: string | null | undefined, presence?: UserPresenceEnum | null | undefined, access: VetterAccessEnum, approvalStatus: VetterApprovalStatusEnum, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, residence?: string | null | undefined, clientId?: string | null | undefined, stripeUser?: { __typename?: 'StripeUser', id: string, credit: number } | null | undefined }>, pagination: { __typename?: 'PaginationResult', currentPage: number, maxPage: number, total: number } } | null | undefined };

export type ClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientsQuery = { __typename?: 'Query', clients?: Array<{ __typename?: 'Client', id: string, availability: ClientAvailabilityEnum, sessionsCount: number, totalVettes: number, remainingVettes: number, companyName?: string | null | undefined }> | null | undefined };

export type ClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientQuery = { __typename?: 'Query', client?: { __typename?: 'Client', id: string, companyName?: string | null | undefined, availability: ClientAvailabilityEnum, clientUsers: Array<{ __typename?: 'User', phone?: string | null | undefined }> } | { __typename?: 'NotFoundError' } | null | undefined };

export type LastSessionsQueryVariables = Exact<{
  filter?: Maybe<SessionStatusFilterEnum>;
}>;


export type LastSessionsQuery = { __typename?: 'Query', lastSessions: Array<{ __typename?: 'Session', id: string, createdAt: any, status?: SessionStatusEnum | null | undefined, activeDuration?: number | null | undefined, canceled?: boolean | null | undefined, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, phone: string, firstName?: string | null | undefined, lastName?: string | null | undefined }, jobOffer: { __typename?: 'JobOffer', id: string, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, vetter?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone?: string | null | undefined } | null | undefined }> };

export type ClientLedgerStatsQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientLedgerStatsQuery = { __typename?: 'Query', client?: { __typename?: 'Client', id: string, totalVettes: number, consumedVettes: number, remainingVettes: number } | { __typename?: 'NotFoundError' } | null | undefined };

export type ClientLedgerRecordsQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientLedgerRecordsQuery = { __typename?: 'Query', clientLedgerRecords?: Array<{ __typename?: 'ClientLedgerRecord', id: string, name?: string | null | undefined, numberOfSessions: number, clientId: string, createdByAdminId?: string | null | undefined, createdAt?: any | null | undefined }> | null | undefined };

export type SingleClientLedgerRecordQueryVariables = Exact<{
  clientLedgerRecordId: Scalars['ID'];
}>;


export type SingleClientLedgerRecordQuery = { __typename?: 'Query', clientLedgerRecord: { __typename?: 'ClientLedgerRecord', id: string, name?: string | null | undefined, numberOfSessions: number } | { __typename?: 'NotFoundError' } };

export type GlobalActivityHistoryAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GlobalActivityHistoryAddedSubscription = { __typename?: 'Subscription', globalActivityHistoryAdded?: { __typename?: 'GlobalActivityHistory', id: string, actor: string, createdAt: any, event: string, metadata: any } | null | undefined };

export type ConversationsListUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConversationsListUpdatedSubscription = { __typename?: 'Subscription', conversationsListUpdated?: { __typename?: 'Conversation', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, toNumber: string, updatedAt: any, lastMessage?: string | null | undefined, lastMessageAt: any, disabledAt?: any | null | undefined, marked: boolean, parsedApplication?: { __typename?: 'ParsedApplication', jobOfferId: string, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined } | null | undefined } | null | undefined, applicant?: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined } | null | undefined, messages: Array<{ __typename?: 'Message', id: string, createdAt: any, text: string, type: MessageTypeEnum, status?: MessageStatusEnum | null | undefined }> } | null | undefined };

export type InterviewerFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined };

export type InterviewNowMutationVariables = Exact<{
  jobOfferId: Scalars['ID'];
  input: InterviewNowInput;
}>;


export type InterviewNowMutation = { __typename?: 'Mutation', interviewNow?: { __typename?: 'ApplicantDidNotPassKnockout', sessionId: string } | { __typename?: 'AttemptedToInterviewAgainError', message: string } | { __typename?: 'MaximumPassedApplicantsPerJobExceededError', message: string } | { __typename?: 'SessionId', id: string } | { __typename?: 'SessionInProgressError', sessionId: string } | { __typename?: 'ValidationError', fields: Array<string> } | { __typename?: 'WrongScriptVersionError' } | null | undefined };

export type ApplicantLogMutationVariables = Exact<{
  input: ApplicantLogInput;
}>;


export type ApplicantLogMutation = { __typename?: 'Mutation', applicantLog?: { __typename?: 'GlobalActivityHistory', id: string } | null | undefined };

export type JobOfferQueryVariables = Exact<{
  jobOfferId: Scalars['ID'];
}>;


export type JobOfferQuery = { __typename?: 'Query', jobOffer?: { __typename?: 'JobOffer', id: string, title: string, description: string, salary?: string | null | undefined, locations: Array<string>, availability: JobOfferAvailabilityEnum, knockoutQuestions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }>, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined, remainingVettes: number } } | { __typename?: 'NotFoundError', message: string } | null | undefined };

export type InterviewerQueryVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type InterviewerQuery = { __typename?: 'Query', interviewer?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined } | null | undefined };

export type ScriptQuestionDataFragment = { __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string };

export type ScriptDataFragment = { __typename?: 'Script', id: string, introduction?: string | null | undefined, isTemplate: boolean, jobOffersCount: number, name: string, recentVersion: number, status: ScriptStatusEnum, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> };

export type OnValidationErrorFragment = { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> };

export type JobOfferDataFragment = { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } };

export type JobStatisticsFragment = { __typename?: 'JobOfferStatistics', id: string, engaged: number, failed: number, passed: number, interviewed: number };

export type JobDataFragment = { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined };

export type JobGroupDataFragment = { __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined };

export type SessionScriptQuestionAndAnswerDataFragment = { __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, title: string, metadata?: any | null | undefined, isDealBreaker: boolean, isKnockout: boolean, answer?: { __typename?: 'ScriptAnswer', id: string, answer: Array<string> } | null | undefined };

export type SessionTranscriptionDataFragment = { __typename?: 'SessionTranscription', id: string, sessionId: string, createdAt: any, finishedAt?: any | null | undefined, result?: any | null | undefined };

export type ClientUserDataFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined };

export type ClientUserAccessDataFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, path?: string | null | undefined, phone?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined, isMe: boolean, role: UserRoleEnum, clientUserPermission?: ClientUserPermissionEnum | null | undefined, clientId?: string | null | undefined };

export type CreateScriptMutationVariables = Exact<{
  clientId: Scalars['ID'];
  input: UpsertScriptInput;
}>;


export type CreateScriptMutation = { __typename?: 'Mutation', createScript?: { __typename?: 'Script', id: string, introduction?: string | null | undefined, isTemplate: boolean, jobOffersCount: number, name: string, recentVersion: number, status: ScriptStatusEnum, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> } | { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> } | null | undefined };

export type CreateNewScriptVersionMutationVariables = Exact<{
  scriptId: Scalars['ID'];
  input: UpsertScriptInput;
}>;


export type CreateNewScriptVersionMutation = { __typename?: 'Mutation', createNewScriptVersion?: { __typename?: 'Script', id: string, introduction?: string | null | undefined, isTemplate: boolean, jobOffersCount: number, name: string, recentVersion: number, status: ScriptStatusEnum, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> } | { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> } | null | undefined };

export type DuplicateScriptMutationVariables = Exact<{
  scriptId: Scalars['ID'];
}>;


export type DuplicateScriptMutation = { __typename?: 'Mutation', duplicateScript?: { __typename?: 'Script', id: string, introduction?: string | null | undefined, isTemplate: boolean, jobOffersCount: number, name: string, recentVersion: number, status: ScriptStatusEnum, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> } | { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> } | null | undefined };

export type ArchiveScriptMutationVariables = Exact<{
  scriptId: Scalars['ID'];
}>;


export type ArchiveScriptMutation = { __typename?: 'Mutation', archiveScript?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'Script', id: string } | { __typename?: 'ValidationError' } | null | undefined };

export type CreateJobOfferMutationVariables = Exact<{
  clientId: Scalars['ID'];
  input: CreateJobOfferInput;
  parentGroupId: Scalars['ID'];
}>;


export type CreateJobOfferMutation = { __typename?: 'Mutation', createJobOffer?: { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type UpdateJobOfferMutationVariables = Exact<{
  jobOfferId: Scalars['ID'];
  input: UpdateJobOfferInput;
}>;


export type UpdateJobOfferMutation = { __typename?: 'Mutation', updateJobOffer?: { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type ResendSessionEmailMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type ResendSessionEmailMutation = { __typename?: 'Mutation', resendSessionEmail?: { __typename?: 'SuccessResult', message: string } | null | undefined };

export type TranscriptSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type TranscriptSessionMutation = { __typename?: 'Mutation', transcriptSession?: { __typename?: 'Session', id: string, sessionTranscription?: { __typename?: 'SessionTranscription', id: string, sessionId: string, createdAt: any, finishedAt?: any | null | undefined, result?: any | null | undefined } | null | undefined } | null | undefined };

export type CreateJobGroupMutationVariables = Exact<{
  clientId: Scalars['ID'];
  parentGroupPath: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateJobGroupMutation = { __typename?: 'Mutation', createJobGroup?: { __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type RenameJobGroupMutationVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  name: Scalars['String'];
}>;


export type RenameJobGroupMutation = { __typename?: 'Mutation', renameJobGroup?: { __typename?: 'JobGroupList', jobGroups: Array<{ __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined }> } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type DeleteJobGroupMutationVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
}>;


export type DeleteJobGroupMutation = { __typename?: 'Mutation', deleteJobGroup?: { __typename?: 'JobGroupList', jobGroups: Array<{ __typename?: 'JobGroup', id: string }> } | null | undefined };

export type AddClientUsersAccessMutationVariables = Exact<{
  clientId: Scalars['ID'];
  clientUserIds: Array<Scalars['ID']> | Scalars['ID'];
  jobGroupPath: Scalars['String'];
}>;


export type AddClientUsersAccessMutation = { __typename?: 'Mutation', addClientUsersAccess?: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, path?: string | null | undefined, phone?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined, isMe: boolean, role: UserRoleEnum, clientUserPermission?: ClientUserPermissionEnum | null | undefined, clientId?: string | null | undefined }> | null | undefined };

export type RemoveClientUserAccessMutationVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  clientUserId: Scalars['ID'];
}>;


export type RemoveClientUserAccessMutation = { __typename?: 'Mutation', removeClientUserAccess?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, path?: string | null | undefined, phone?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined, isMe: boolean, role: UserRoleEnum, clientUserPermission?: ClientUserPermissionEnum | null | undefined, clientId?: string | null | undefined } | null | undefined };

export type MoveJobGroupMutationVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  parentGroupPath: Scalars['String'];
}>;


export type MoveJobGroupMutation = { __typename?: 'Mutation', moveJobGroup?: { __typename?: 'JobGroupList', jobGroups: Array<{ __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined }> } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string>, code: number } | null | undefined };

export type CloneJobGroupMutationVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
  parentGroupPath: Scalars['String'];
}>;


export type CloneJobGroupMutation = { __typename?: 'Mutation', cloneJobGroup?: { __typename?: 'JobGroupList', jobGroups: Array<{ __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined }> } | null | undefined };

export type InviteUsersMutationVariables = Exact<{
  clientId: Scalars['ID'];
  users: Array<InviteUsersInput> | InviteUsersInput;
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUsers?: { __typename?: 'NotFoundError' } | { __typename?: 'SuccessResult', message: string } | { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> } | null | undefined };

export type RemoveTeamMemberMutationVariables = Exact<{
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember?: { __typename?: 'SuccessResult', message: string, code: number } | null | undefined };

export type AcceptUserInviteMutationVariables = Exact<{
  input: AcceptUserInviteInput;
}>;


export type AcceptUserInviteMutation = { __typename?: 'Mutation', acceptUserInvite?: { __typename?: 'NotFoundError', code: number, message: string } | { __typename?: 'User', id: string, phone?: string | null | undefined, role: UserRoleEnum, lastName?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined } | { __typename?: 'ValidationError', code: number, fields: Array<string>, messages: Array<string> } | null | undefined };

export type UpdateSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  input: UpdateSessionInput;
}>;


export type UpdateSessionMutation = { __typename?: 'Mutation', updateSession?: { __typename?: 'SuccessResult', code: number, message: string } | null | undefined };

export type ClientNameQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientNameQuery = { __typename?: 'Query', client?: { __typename?: 'Client', id: string, companyName?: string | null | undefined } | { __typename?: 'NotFoundError' } | null | undefined };

export type ScriptsQueryVariables = Exact<{
  clientId: Scalars['ID'];
  status?: Maybe<ScriptStatusEnum>;
}>;


export type ScriptsQuery = { __typename?: 'Query', scripts?: Array<{ __typename?: 'Script', id: string, name: string, status: ScriptStatusEnum, jobOffersCount: number }> | null | undefined };

export type ScriptQueryVariables = Exact<{
  scriptId: Scalars['ID'];
}>;


export type ScriptQuery = { __typename?: 'Query', script?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'Script', id: string, introduction?: string | null | undefined, isTemplate: boolean, jobOffersCount: number, name: string, recentVersion: number, status: ScriptStatusEnum, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> } | null | undefined };

export type JobsHierarchyQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type JobsHierarchyQuery = { __typename?: 'Query', clientJobsHierarchy?: { __typename?: 'JobGroupList', jobGroups: Array<{ __typename?: 'JobGroup', id: string, name: string, path: string, depth: number, jobOffer?: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, maximumRecommendedApplicants?: number | null | undefined } | null | undefined }> } | null | undefined };

export type JobsHierarchyStatisticsQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type JobsHierarchyStatisticsQuery = { __typename?: 'Query', jobOfferStatistics: Array<{ __typename?: 'JobOfferStatistics', id: string, engaged: number, failed: number, passed: number, interviewed: number }> };

export type SingleJobOfferQueryVariables = Exact<{
  jobOfferId: Scalars['ID'];
}>;


export type SingleJobOfferQuery = { __typename?: 'Query', jobOffer?: { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } } | { __typename?: 'NotFoundError', message: string } | null | undefined };

export type ScriptsClientAndBranchFieldOptionsQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ScriptsClientAndBranchFieldOptionsQuery = { __typename?: 'Query', clientFieldOptions: Array<string>, scripts?: Array<{ __typename?: 'Script', id: string, name: string }> | null | undefined };

export type SessionQueryVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type SessionQuery = { __typename?: 'Query', session?: { __typename?: 'NotFoundError' } | { __typename?: 'Session', id: string, status?: SessionStatusEnum | null | undefined, recommended?: boolean | null | undefined, reportedIssueType?: SessionReportedIssueTypeEnum | null | undefined, reportedIssueDetail?: string | null | undefined, reasonForRecommendation?: string | null | undefined, notes?: string | null | undefined, canceled?: boolean | null | undefined, createdAt: any, location?: string | null | undefined, moderationStatus: ModerationStatusEnum, recordingUrl?: string | null | undefined, sessionTranscription?: { __typename?: 'SessionTranscription', id: string, sessionId: string, createdAt: any, finishedAt?: any | null | undefined, result?: any | null | undefined } | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, clientOfClient?: string | null | undefined, locations: Array<string>, title: string, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } }, applicant: { __typename?: 'Applicant', id: string, email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string }, vetter?: { __typename?: 'User', id: string, firstName?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined } | null | undefined, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, title: string, metadata?: any | null | undefined, isDealBreaker: boolean, isKnockout: boolean, answer?: { __typename?: 'ScriptAnswer', id: string, answer: Array<string> } | null | undefined }> } | null | undefined };

export type ClientDashboardQueryVariables = Exact<{
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type ClientDashboardQuery = { __typename?: 'Query', clientDashboard: { __typename?: 'ClientDashboard', available: number, engaged: number, passed: number, vetted: number }, user?: { __typename?: 'User', firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined };

export type ClientExportDetailedDataQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientExportDetailedDataQuery = { __typename?: 'Query', clientExportDetailedData: Array<{ __typename?: 'ClientSessionDetailedData', applicant: string, channel: string, client?: string | null | undefined, createdAt: any, job: string, location?: string | null | undefined, passed: number, phone: string, status: string, vetted: number, interviewer: string }> };

export type ClientExportPerformanceDataQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientExportPerformanceDataQuery = { __typename?: 'Query', clientExportPerformanceData: Array<{ __typename?: 'ClientSessionPerformanceData', client?: string | null | undefined, job: string, passed: number, vetted: number, internalInterviewer: number, externalInterviewer: number }> };

export type ScriptQuestionsPreviewQueryVariables = Exact<{
  scriptId: Scalars['ID'];
}>;


export type ScriptQuestionsPreviewQuery = { __typename?: 'Query', script?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'Script', id: string, questions: Array<{ __typename?: 'ScriptQuestion', id: number, description?: string | null | undefined, guide?: string | null | undefined, isKnockout: boolean, isDealBreaker: boolean, metadata?: any | null | undefined, position: number, title: string }> } | null | undefined };

export type SessionsQueryVariables = Exact<{
  clientId: Scalars['ID'];
  filter: SessionsFilterInput;
  searchTerm?: Maybe<Scalars['String']>;
  pagination: PaginationInput;
}>;


export type SessionsQuery = { __typename?: 'Query', sessions?: { __typename?: 'PaginatedSession', data: Array<{ __typename?: 'Session', id: string, recommended?: boolean | null | undefined, canceled?: boolean | null | undefined, clientUserId?: string | null | undefined, createdAt: any, reportedIssueType?: SessionReportedIssueTypeEnum | null | undefined, reportedIssueDetail?: string | null | undefined, status?: SessionStatusEnum | null | undefined, moderationStatus: ModerationStatusEnum, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string }, jobOffer: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } }, vetter?: { __typename?: 'User', role: UserRoleEnum, firstName?: string | null | undefined, lastName?: string | null | undefined, clientId?: string | null | undefined, photoLowUrl?: string | null | undefined } | null | undefined }>, pagination: { __typename?: 'PaginationResult', currentPage: number, maxPage: number, total: number } } | null | undefined };

export type SessionsStatisticsQueryVariables = Exact<{
  clientId: Scalars['ID'];
  filter: SessionsFilterInput;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type SessionsStatisticsQuery = { __typename?: 'Query', sessionsStatistics: { __typename?: 'SessionsStatistics', id: string, all: number, passed: number, failed: number, incomplete: number, knockoutFailed: number, flagged: number } };

export type ClientUsersQueryVariables = Exact<{
  clientId: Scalars['ID'];
  filter?: Maybe<ClientUsersFilterInput>;
}>;


export type ClientUsersQuery = { __typename?: 'Query', clientUsers?: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined }> | null | undefined };

export type ClientUsersWithAccessToJobGroupQueryVariables = Exact<{
  clientId: Scalars['ID'];
  jobGroupPath: Scalars['String'];
}>;


export type ClientUsersWithAccessToJobGroupQuery = { __typename?: 'Query', clientUsersWithAccessToJobGroup: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, path?: string | null | undefined, phone?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined, isMe: boolean, role: UserRoleEnum, clientUserPermission?: ClientUserPermissionEnum | null | undefined, clientId?: string | null | undefined }> };

export type TeamMembersQueryVariables = Exact<{
  clientId: Scalars['ID'];
  pagination: PaginationInput;
  filter?: Maybe<TeamMembersFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type TeamMembersQuery = { __typename?: 'Query', teamMembers?: { __typename?: 'PaginatedUser', data: Array<{ __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, path?: string | null | undefined, phone?: string | null | undefined, invitationStatus?: InvitationStatusEnum | null | undefined, isMe: boolean, role: UserRoleEnum, clientUserPermission?: ClientUserPermissionEnum | null | undefined, clientId?: string | null | undefined }>, pagination: { __typename?: 'PaginationResult', currentPage: number, maxPage: number, total: number } } | null | undefined };

export type ClientByInvitationKeyQueryVariables = Exact<{
  invitationKey: Scalars['String'];
}>;


export type ClientByInvitationKeyQuery = { __typename?: 'Query', clientByInvitationKey?: { __typename?: 'Client', id: string, companyName?: string | null | undefined } | { __typename?: 'NotFoundError', code: number, message: string } | null | undefined };

export type ClientUpdatedSubscriptionVariables = Exact<{
  clientId?: Maybe<Scalars['ID']>;
}>;


export type ClientUpdatedSubscription = { __typename?: 'Subscription', clientUpdated?: { __typename?: 'Client', id: string } | null | undefined };

export type OnNotFoundFragment = { __typename?: 'NotFoundError', message: string };

export type PaginationFragmentFragment = { __typename?: 'PaginationResult', currentPage: number, maxPage: number, total: number };

export type ApplicantDataFragment = { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined };

export type ParsedApplicationFragment = { __typename?: 'ParsedApplication', jobOfferId: string, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined } | null | undefined };

export type SessionDataFragment = { __typename?: 'Session', id: string, recommended?: boolean | null | undefined, canceled?: boolean | null | undefined, clientUserId?: string | null | undefined, createdAt: any, reportedIssueType?: SessionReportedIssueTypeEnum | null | undefined, reportedIssueDetail?: string | null | undefined, status?: SessionStatusEnum | null | undefined, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined }, jobOffer: { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } } };

export type SessionMinifiedDataFragment = { __typename?: 'Session', id: string, recommended?: boolean | null | undefined, canceled?: boolean | null | undefined, clientUserId?: string | null | undefined, createdAt: any, reportedIssueType?: SessionReportedIssueTypeEnum | null | undefined, reportedIssueDetail?: string | null | undefined, status?: SessionStatusEnum | null | undefined, moderationStatus: ModerationStatusEnum, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string }, jobOffer: { __typename?: 'JobOffer', id: string, title: string, clientOfClient?: string | null | undefined, availability: JobOfferAvailabilityEnum, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } }, vetter?: { __typename?: 'User', role: UserRoleEnum, firstName?: string | null | undefined, lastName?: string | null | undefined, clientId?: string | null | undefined, photoLowUrl?: string | null | undefined } | null | undefined };

export type SessionChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SessionChangedSubscription = { __typename?: 'Subscription', sessionChanged?: { __typename?: 'Session', id: string, status?: SessionStatusEnum | null | undefined } | null | undefined };

export type UserDataFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined, photoOriginalUrl?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, isProfileDataProvided: boolean, isProfilePhotoProvided: boolean, onboardingCompleted?: boolean | null | undefined, approvalStatus: VetterApprovalStatusEnum, residence?: string | null | undefined, pushNotificationsEnabled: boolean, stripeUser?: { __typename?: 'StripeUser', id: string, stripeAccountConnected: boolean } | null | undefined };

export type UserForHotjarFragment = { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, role: UserRoleEnum, client?: { __typename?: 'Client', id: string, companyName?: string | null | undefined } | null | undefined };

export type LoginUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: string | null | undefined };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser?: string | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined, photoOriginalUrl?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, isProfileDataProvided: boolean, isProfilePhotoProvided: boolean, onboardingCompleted?: boolean | null | undefined, approvalStatus: VetterApprovalStatusEnum, residence?: string | null | undefined, pushNotificationsEnabled: boolean, stripeUser?: { __typename?: 'StripeUser', id: string, stripeAccountConnected: boolean } | null | undefined } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string> } | null | undefined };

export type DisablePushNotificationMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DisablePushNotificationMutation = { __typename?: 'Mutation', userDisablePushNotification: { __typename?: 'User', id: string, pushNotificationsEnabled: boolean } };

export type AddPushNotificationTokenMutationVariables = Exact<{
  userId: Scalars['ID'];
  token: Scalars['String'];
}>;


export type AddPushNotificationTokenMutation = { __typename?: 'Mutation', userAddPushNotificationToken: { __typename?: 'User', id: string, pushNotificationsEnabled: boolean } };

export type UpdatePresenceMutationVariables = Exact<{
  userId: Scalars['ID'];
  presence: UserPresenceEnum;
}>;


export type UpdatePresenceMutation = { __typename?: 'Mutation', updateUserPresence?: { __typename?: 'User', id: string, presence?: UserPresenceEnum | null | undefined } | { __typename?: 'ValidationError', fields: Array<string>, messages: Array<string> } | null | undefined };

export type SetStripeAccountConnectedMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type SetStripeAccountConnectedMutation = { __typename?: 'Mutation', setStripeAccountConnected: { __typename?: 'StripeUser', id: string, stripeAccountConnected: boolean } };

export type SetOnboardingCompletedMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type SetOnboardingCompletedMutation = { __typename?: 'Mutation', setOnboardingCompleted: { __typename?: 'User', id: string, onboardingCompleted?: boolean | null | undefined } };

export type UpdateUserPhoneMutationVariables = Exact<{
  firebaseUid: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UpdateUserPhoneMutation = { __typename?: 'Mutation', updateUserPhone?: { __typename?: 'User', id: string, phone?: string | null | undefined } | { __typename?: 'ValidationError', messages: Array<string> } | null | undefined };

export type UserWithPhoneExistsQueryVariables = Exact<{
  input: UserWithPhoneExistsInput;
}>;


export type UserWithPhoneExistsQuery = { __typename?: 'Query', userWithPhoneExists?: boolean | null | undefined };

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined, photoOriginalUrl?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, isProfileDataProvided: boolean, isProfilePhotoProvided: boolean, onboardingCompleted?: boolean | null | undefined, approvalStatus: VetterApprovalStatusEnum, residence?: string | null | undefined, pushNotificationsEnabled: boolean, stripeUser?: { __typename?: 'StripeUser', id: string, stripeAccountConnected: boolean } | null | undefined } | null | undefined };

export type UserByFirebaseUidForHotjarQueryVariables = Exact<{
  firebaseUid: Scalars['String'];
}>;


export type UserByFirebaseUidForHotjarQuery = { __typename?: 'Query', userByFirebaseUid?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, role: UserRoleEnum, client?: { __typename?: 'Client', id: string, companyName?: string | null | undefined } | null | undefined } | null | undefined };

export type UserPresenceQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserPresenceQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, presence?: UserPresenceEnum | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, photoHighUrl?: string | null | undefined, isProfileDataProvided: boolean, isProfilePhotoProvided: boolean, approvalStatus: VetterApprovalStatusEnum, onboardingCompleted?: boolean | null | undefined } | null | undefined };

export type QuestionFragment = { __typename?: 'ScriptQuestion', description?: string | null | undefined, guide?: string | null | undefined, id: number, metadata?: any | null | undefined, position: number, title: string };

export type StoreVetterAnswerMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  input: ScriptAnswerInput;
}>;


export type StoreVetterAnswerMutation = { __typename?: 'Mutation', storeVetterAnswer: { __typename?: 'ScriptAnswer', id: string } };

export type VetterUpdateSessionStatusMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  newSessionStatus: SessionUpdateStatusEnum;
}>;


export type VetterUpdateSessionStatusMutation = { __typename?: 'Mutation', vetterUpdateSessionStatus: { __typename?: 'NotFoundError' } | { __typename?: 'SuccessResult', message: string } | { __typename?: 'ValidationError', fields: Array<string>, code: number, messages: Array<string> } };

export type SignupVetterMutationVariables = Exact<{
  input: SignupVetterInput;
}>;


export type SignupVetterMutation = { __typename?: 'Mutation', signupVetter?: { __typename?: 'User', id: string } | { __typename?: 'ValidationError', code: number, fields: Array<string>, messages: Array<string> } | null | undefined };

export type AcceptVetterSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type AcceptVetterSessionMutation = { __typename?: 'Mutation', acceptVetterSession?: { __typename?: 'NotFoundError', code: number, message: string } | { __typename?: 'ValidationError', code: number, fields: Array<string>, messages: Array<string> } | null | undefined };

export type RejectVetterSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type RejectVetterSessionMutation = { __typename?: 'Mutation', rejectVetterSession?: { __typename?: 'NotFoundError', code: number, message: string } | { __typename?: 'ValidationError', code: number, fields: Array<string>, messages: Array<string> } | null | undefined };

export type SubmitVetterInterviewMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  input: SubmitVetterInterviewInput;
}>;


export type SubmitVetterInterviewMutation = { __typename?: 'Mutation', submitVetterInterview?: any | null | undefined };

export type GenerateStripeUrlMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GenerateStripeUrlMutation = { __typename?: 'Mutation', generateStripeUrl?: { __typename?: 'StripeUrl', url?: string | null | undefined } | { __typename?: 'ValidationError', messages: Array<string> } | null | undefined };

export type VetterLogMutationVariables = Exact<{
  userId: Scalars['ID'];
  globalActivityHistoryEvent: VetterDebugLogEventsEnum;
  metadata?: Maybe<Scalars['JSON']>;
}>;


export type VetterLogMutation = { __typename?: 'Mutation', vetterLog?: any | null | undefined };

export type InterviewQueryVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type InterviewQuery = { __typename?: 'Query', sessionScriptQuestions?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'SessionScriptQuestions', questions: Array<{ __typename?: 'ScriptQuestion', description?: string | null | undefined, guide?: string | null | undefined, id: number, metadata?: any | null | undefined, position: number, title: string }> } | null | undefined, session?: { __typename?: 'NotFoundError', message: string } | { __typename?: 'Session', id: string, location?: string | null | undefined, scriptVersion?: number | null | undefined, applicant: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone: string }, jobOffer: { __typename?: 'JobOffer', id: string, additionalDescription?: string | null | undefined, description: string, locations: Array<string>, salary?: string | null | undefined, title: string, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } }, vetter?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined } | null | undefined };

export type InternalVetterStatisticsQueryVariables = Exact<{
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type InternalVetterStatisticsQuery = { __typename?: 'Query', internalVetterStatistics?: { __typename?: 'InternalVetterStatistics', all?: number | null | undefined, passed?: number | null | undefined, failed?: number | null | undefined, incomplete?: number | null | undefined } | null | undefined };

export type VetterDashboardQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type VetterDashboardQuery = { __typename?: 'Query', vetterDashboard?: { __typename?: 'User', id: string, allVetsCount: number, onboardingCompleted?: boolean | null | undefined, approvalStatus: VetterApprovalStatusEnum, stripeUser?: { __typename?: 'StripeUser', id: string, credit: number, stripeDashboardUrl?: string | null | undefined } | null | undefined } | null | undefined };

export type InternalVetterSessionsQueryVariables = Exact<{
  clientId: Scalars['ID'];
  pagination: PaginationInput;
}>;


export type InternalVetterSessionsQuery = { __typename?: 'Query', sessions?: { __typename?: 'PaginatedSession', data: Array<{ __typename?: 'Session', id: string, recommended?: boolean | null | undefined, canceled?: boolean | null | undefined, clientUserId?: string | null | undefined, createdAt: any, reportedIssueType?: SessionReportedIssueTypeEnum | null | undefined, reportedIssueDetail?: string | null | undefined, status?: SessionStatusEnum | null | undefined, location?: string | null | undefined, applicant: { __typename?: 'Applicant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, phone: string, lastSession?: { __typename?: 'Session', id: string, location?: string | null | undefined, jobOffer: { __typename?: 'JobOffer', id: string, title: string, salary?: string | null | undefined, remoteId?: string | null | undefined, locations: Array<string>, description: string, supportEmails?: Array<string> | null | undefined, client: { __typename?: 'Client', id: string, companyName?: string | null | undefined } } } | null | undefined }, jobOffer: { __typename?: 'JobOffer', id: string, title: string, availability: JobOfferAvailabilityEnum, locations: Array<string>, salary?: string | null | undefined, remoteId?: string | null | undefined, description: string, additionalDescription?: string | null | undefined, clientOfClient?: string | null | undefined, emailRecipients?: Array<string> | null | undefined, supportEmails?: Array<string> | null | undefined, scriptId: string, processingInboxEmail: string, url: string, maximumRecommendedApplicants?: number | null | undefined, automatedSMSTextRecommended?: string | null | undefined, automatedSMSTextNotRecommended?: string | null | undefined, jobGroup: { __typename?: 'JobGroup', id: string, path: string, deletedAt?: any | null | undefined } } }>, pagination: { __typename?: 'PaginationResult', currentPage: number, maxPage: number, total: number } } | null | undefined };

export type VetterUpdatedSubscriptionVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type VetterUpdatedSubscription = { __typename?: 'Subscription', vetterUpdated?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined, photoHighUrl?: string | null | undefined, photoLowUrl?: string | null | undefined, clientId?: string | null | undefined, presence?: UserPresenceEnum | null | undefined, disabledAt?: any | null | undefined } | null | undefined };

export const ConversationDataFragmentDoc = gql`
    fragment ConversationData on Conversation {
  id
  firstName
  lastName
  toNumber
  updatedAt
  lastMessage
  lastMessageAt
  disabledAt
  marked
}
    `;
export const ParsedApplicationFragmentDoc = gql`
    fragment ParsedApplication on ParsedApplication {
  jobOfferId
  jobOffer {
    id
    title
    salary
    locations
    description
    supportEmails
    client {
      id
      companyName
    }
  }
  lastSession {
    id
    location
  }
}
    `;
export const ApplicantDataFragmentDoc = gql`
    fragment ApplicantData on Applicant {
  id
  firstName
  lastName
  phone
  lastSession {
    id
    location
    jobOffer {
      id
      title
      salary
      remoteId
      locations
      description
      supportEmails
      client {
        id
        companyName
      }
    }
  }
}
    `;
export const MessageDetailDataFragmentDoc = gql`
    fragment MessageDetailData on Message {
  id
  createdAt
  text
  type
  status
}
    `;
export const ConversationDetailedDataFragmentDoc = gql`
    fragment ConversationDetailedData on Conversation {
  ...ConversationData
  parsedApplication {
    ...ParsedApplication
  }
  applicant {
    ...ApplicantData
  }
  messages {
    ...MessageDetailData
  }
}
    ${ConversationDataFragmentDoc}
${ParsedApplicationFragmentDoc}
${ApplicantDataFragmentDoc}
${MessageDetailDataFragmentDoc}`;
export const ActivityDataFragmentDoc = gql`
    fragment ActivityData on GlobalActivityHistory {
  id
  actor
  createdAt
  event
  metadata
}
    `;
export const AdminVettersFragmentDoc = gql`
    fragment AdminVetters on User {
  id
  firstName
  lastName
  phone
  presence
  access
  approvalStatus
  photoHighUrl
  photoLowUrl
  residence
  clientId
  stripeUser {
    id
    credit
  }
}
    `;
export const SessionDispatchingDataFragmentDoc = gql`
    fragment SessionDispatchingData on Session {
  id
  createdAt
  status
  activeDuration
  canceled
  location
  applicant {
    id
    phone
    firstName
    lastName
  }
  jobOffer {
    id
    client {
      id
      companyName
    }
  }
  vetter {
    id
    firstName
    lastName
    phone
  }
}
    `;
export const InterviewerFragmentDoc = gql`
    fragment Interviewer on User {
  id
  firstName
  lastName
  photoHighUrl
  photoLowUrl
}
    `;
export const ScriptQuestionDataFragmentDoc = gql`
    fragment ScriptQuestionData on ScriptQuestion {
  id
  description
  guide
  isKnockout
  isDealBreaker
  metadata
  position
  title
}
    `;
export const ScriptDataFragmentDoc = gql`
    fragment ScriptData on Script {
  id
  introduction
  isTemplate
  jobOffersCount
  name
  recentVersion
  status
  questions {
    ...ScriptQuestionData
  }
}
    ${ScriptQuestionDataFragmentDoc}`;
export const OnValidationErrorFragmentDoc = gql`
    fragment OnValidationError on ValidationError {
  fields
  code
  messages
}
    `;
export const JobStatisticsFragmentDoc = gql`
    fragment JobStatistics on JobOfferStatistics {
  id
  engaged
  failed
  passed
  interviewed
}
    `;
export const JobDataFragmentDoc = gql`
    fragment JobData on JobOffer {
  id
  title
  clientOfClient
  availability
  maximumRecommendedApplicants
}
    `;
export const JobGroupDataFragmentDoc = gql`
    fragment JobGroupData on JobGroup {
  id
  name
  path
  depth
  jobOffer {
    ...JobData
  }
}
    ${JobDataFragmentDoc}`;
export const SessionScriptQuestionAndAnswerDataFragmentDoc = gql`
    fragment SessionScriptQuestionAndAnswerData on ScriptQuestion {
  id
  description
  title
  metadata
  isDealBreaker
  isKnockout
  answer(sessionId: $sessionId) {
    id
    answer
  }
}
    `;
export const SessionTranscriptionDataFragmentDoc = gql`
    fragment SessionTranscriptionData on SessionTranscription {
  id
  sessionId
  createdAt
  finishedAt
  result
}
    `;
export const ClientUserDataFragmentDoc = gql`
    fragment ClientUserData on User {
  id
  firstName
  lastName
  email
  phone
}
    `;
export const ClientUserAccessDataFragmentDoc = gql`
    fragment ClientUserAccessData on User {
  id
  firstName
  lastName
  email
  path
  phone
  invitationStatus
  isMe
  role
  clientUserPermission
  clientId
}
    `;
export const OnNotFoundFragmentDoc = gql`
    fragment OnNotFound on NotFoundError {
  message
}
    `;
export const PaginationFragmentFragmentDoc = gql`
    fragment PaginationFragment on PaginationResult {
  currentPage
  maxPage
  total
}
    `;
export const JobOfferDataFragmentDoc = gql`
    fragment JobOfferData on JobOffer {
  id
  title
  availability
  locations
  salary
  remoteId
  description
  additionalDescription
  clientOfClient
  emailRecipients
  supportEmails
  scriptId
  processingInboxEmail
  url
  maximumRecommendedApplicants
  automatedSMSTextRecommended
  automatedSMSTextNotRecommended
  jobGroup {
    id
    path
    deletedAt
  }
}
    `;
export const SessionDataFragmentDoc = gql`
    fragment SessionData on Session {
  id
  recommended
  canceled
  clientUserId
  createdAt
  reportedIssueType
  reportedIssueDetail
  status
  location
  applicant {
    ...ApplicantData
  }
  jobOffer {
    ...JobOfferData
  }
}
    ${ApplicantDataFragmentDoc}
${JobOfferDataFragmentDoc}`;
export const SessionMinifiedDataFragmentDoc = gql`
    fragment SessionMinifiedData on Session {
  id
  recommended
  canceled
  clientUserId
  createdAt
  reportedIssueType
  reportedIssueDetail
  status
  moderationStatus
  location
  applicant {
    id
    firstName
    lastName
    phone
  }
  jobOffer {
    id
    title
    clientOfClient
    availability
    jobGroup {
      id
      path
      deletedAt
    }
  }
  vetter {
    role
    firstName
    lastName
    clientId
    photoLowUrl
  }
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  firstName
  lastName
  email
  phone
  photoOriginalUrl
  photoHighUrl
  photoLowUrl
  isProfileDataProvided
  isProfilePhotoProvided
  onboardingCompleted
  approvalStatus
  residence
  pushNotificationsEnabled
  stripeUser {
    id
    stripeAccountConnected
  }
}
    `;
export const UserForHotjarFragmentDoc = gql`
    fragment UserForHotjar on User {
  id
  firstName
  lastName
  role
  client {
    id
    companyName
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on ScriptQuestion {
  description
  guide
  id
  metadata
  position
  title
}
    `;
export const AdminUpdateSessionDocument = gql`
    mutation AdminUpdateSession($sessionId: ID!, $input: AdminUpdateSessionInput!) {
  adminUpdateSession(sessionId: $sessionId, input: $input) {
    ... on Session {
      id
      recommended
    }
  }
}
    `;
export type AdminUpdateSessionMutationFn = Apollo.MutationFunction<AdminUpdateSessionMutation, AdminUpdateSessionMutationVariables>;

/**
 * __useAdminUpdateSessionMutation__
 *
 * To run a mutation, you first call `useAdminUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateSessionMutation, { data, loading, error }] = useAdminUpdateSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateSessionMutation, AdminUpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateSessionMutation, AdminUpdateSessionMutationVariables>(AdminUpdateSessionDocument, options);
      }
export type AdminUpdateSessionMutationHookResult = ReturnType<typeof useAdminUpdateSessionMutation>;
export type AdminUpdateSessionMutationResult = Apollo.MutationResult<AdminUpdateSessionMutation>;
export type AdminUpdateSessionMutationOptions = Apollo.BaseMutationOptions<AdminUpdateSessionMutation, AdminUpdateSessionMutationVariables>;
export const UnflagSessionDocument = gql`
    mutation UnflagSession($sessionId: ID!) {
  unflagSession(sessionId: $sessionId) {
    ... on Session {
      id
      moderationStatus
    }
  }
}
    `;
export type UnflagSessionMutationFn = Apollo.MutationFunction<UnflagSessionMutation, UnflagSessionMutationVariables>;

/**
 * __useUnflagSessionMutation__
 *
 * To run a mutation, you first call `useUnflagSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnflagSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unflagSessionMutation, { data, loading, error }] = useUnflagSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUnflagSessionMutation(baseOptions?: Apollo.MutationHookOptions<UnflagSessionMutation, UnflagSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnflagSessionMutation, UnflagSessionMutationVariables>(UnflagSessionDocument, options);
      }
export type UnflagSessionMutationHookResult = ReturnType<typeof useUnflagSessionMutation>;
export type UnflagSessionMutationResult = Apollo.MutationResult<UnflagSessionMutation>;
export type UnflagSessionMutationOptions = Apollo.BaseMutationOptions<UnflagSessionMutation, UnflagSessionMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: SendSMSInput!) {
  sendSMS(input: $input) {
    ... on Message {
      ...MessageDetailData
    }
    ... on ValidationError {
      fields
      messages
      code
    }
    ... on SendSmsError {
      message
      code
    }
  }
}
    ${MessageDetailDataFragmentDoc}`;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendBulkMessageDocument = gql`
    mutation SendBulkMessage($body: String!, $phones: [String!]!, $conversationGroupId: ID) {
  sendBulkSMS(
    body: $body
    phones: $phones
    conversationGroupId: $conversationGroupId
  ) {
    ... on SuccessResult {
      message
    }
    ... on ValidationError {
      fields
      messages
    }
  }
}
    `;
export type SendBulkMessageMutationFn = Apollo.MutationFunction<SendBulkMessageMutation, SendBulkMessageMutationVariables>;

/**
 * __useSendBulkMessageMutation__
 *
 * To run a mutation, you first call `useSendBulkMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBulkMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBulkMessageMutation, { data, loading, error }] = useSendBulkMessageMutation({
 *   variables: {
 *      body: // value for 'body'
 *      phones: // value for 'phones'
 *      conversationGroupId: // value for 'conversationGroupId'
 *   },
 * });
 */
export function useSendBulkMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendBulkMessageMutation, SendBulkMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendBulkMessageMutation, SendBulkMessageMutationVariables>(SendBulkMessageDocument, options);
      }
export type SendBulkMessageMutationHookResult = ReturnType<typeof useSendBulkMessageMutation>;
export type SendBulkMessageMutationResult = Apollo.MutationResult<SendBulkMessageMutation>;
export type SendBulkMessageMutationOptions = Apollo.BaseMutationOptions<SendBulkMessageMutation, SendBulkMessageMutationVariables>;
export const MarkConversationDocument = gql`
    mutation MarkConversation($conversationId: ID!, $marked: Boolean!) {
  markAdminConversation(conversationId: $conversationId, marked: $marked) {
    ...OnNotFound
    ...ConversationData
  }
}
    ${OnNotFoundFragmentDoc}
${ConversationDataFragmentDoc}`;
export type MarkConversationMutationFn = Apollo.MutationFunction<MarkConversationMutation, MarkConversationMutationVariables>;

/**
 * __useMarkConversationMutation__
 *
 * To run a mutation, you first call `useMarkConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationMutation, { data, loading, error }] = useMarkConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      marked: // value for 'marked'
 *   },
 * });
 */
export function useMarkConversationMutation(baseOptions?: Apollo.MutationHookOptions<MarkConversationMutation, MarkConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkConversationMutation, MarkConversationMutationVariables>(MarkConversationDocument, options);
      }
export type MarkConversationMutationHookResult = ReturnType<typeof useMarkConversationMutation>;
export type MarkConversationMutationResult = Apollo.MutationResult<MarkConversationMutation>;
export type MarkConversationMutationOptions = Apollo.BaseMutationOptions<MarkConversationMutation, MarkConversationMutationVariables>;
export const VetterSetAccessDocument = gql`
    mutation VetterSetAccess($vetterId: ID!, $input: AdminUpdateVetterInput!) {
  adminUpdateVetter(input: $input, vetterId: $vetterId) {
    ... on User {
      id
      access
      presence
    }
    ... on ValidationError {
      fields
      messages
      code
    }
    ... on AuthorizationError {
      message
      code
    }
  }
}
    `;
export type VetterSetAccessMutationFn = Apollo.MutationFunction<VetterSetAccessMutation, VetterSetAccessMutationVariables>;

/**
 * __useVetterSetAccessMutation__
 *
 * To run a mutation, you first call `useVetterSetAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVetterSetAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vetterSetAccessMutation, { data, loading, error }] = useVetterSetAccessMutation({
 *   variables: {
 *      vetterId: // value for 'vetterId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVetterSetAccessMutation(baseOptions?: Apollo.MutationHookOptions<VetterSetAccessMutation, VetterSetAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VetterSetAccessMutation, VetterSetAccessMutationVariables>(VetterSetAccessDocument, options);
      }
export type VetterSetAccessMutationHookResult = ReturnType<typeof useVetterSetAccessMutation>;
export type VetterSetAccessMutationResult = Apollo.MutationResult<VetterSetAccessMutation>;
export type VetterSetAccessMutationOptions = Apollo.BaseMutationOptions<VetterSetAccessMutation, VetterSetAccessMutationVariables>;
export const AdminCreateClientDocument = gql`
    mutation AdminCreateClient($input: AdminUpsertClientInput!) {
  adminCreateClient(input: $input) {
    ... on Client {
      id
      companyName
    }
    ... on ValidationError {
      fields
      messages
      code
    }
  }
}
    `;
export type AdminCreateClientMutationFn = Apollo.MutationFunction<AdminCreateClientMutation, AdminCreateClientMutationVariables>;

/**
 * __useAdminCreateClientMutation__
 *
 * To run a mutation, you first call `useAdminCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateClientMutation, { data, loading, error }] = useAdminCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateClientMutation, AdminCreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateClientMutation, AdminCreateClientMutationVariables>(AdminCreateClientDocument, options);
      }
export type AdminCreateClientMutationHookResult = ReturnType<typeof useAdminCreateClientMutation>;
export type AdminCreateClientMutationResult = Apollo.MutationResult<AdminCreateClientMutation>;
export type AdminCreateClientMutationOptions = Apollo.BaseMutationOptions<AdminCreateClientMutation, AdminCreateClientMutationVariables>;
export const AdminUpdateClientDocument = gql`
    mutation AdminUpdateClient($input: AdminUpsertClientInput!, $clientId: ID!) {
  adminUpdateClient(input: $input, clientId: $clientId) {
    ... on Client {
      id
      companyName
      sessionsCount
      disabledAt
      availability
      clientUsers {
        id
        firstName
        lastName
        phone
      }
    }
    ... on ValidationError {
      messages
      fields
      code
    }
  }
}
    `;
export type AdminUpdateClientMutationFn = Apollo.MutationFunction<AdminUpdateClientMutation, AdminUpdateClientMutationVariables>;

/**
 * __useAdminUpdateClientMutation__
 *
 * To run a mutation, you first call `useAdminUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateClientMutation, { data, loading, error }] = useAdminUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAdminUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateClientMutation, AdminUpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateClientMutation, AdminUpdateClientMutationVariables>(AdminUpdateClientDocument, options);
      }
export type AdminUpdateClientMutationHookResult = ReturnType<typeof useAdminUpdateClientMutation>;
export type AdminUpdateClientMutationResult = Apollo.MutationResult<AdminUpdateClientMutation>;
export type AdminUpdateClientMutationOptions = Apollo.BaseMutationOptions<AdminUpdateClientMutation, AdminUpdateClientMutationVariables>;
export const AdminApproveVetterDocument = gql`
    mutation AdminApproveVetter($vetterId: ID!) {
  adminApproveVetter(vetterId: $vetterId) {
    ... on User {
      id
      approvalStatus
    }
  }
}
    `;
export type AdminApproveVetterMutationFn = Apollo.MutationFunction<AdminApproveVetterMutation, AdminApproveVetterMutationVariables>;

/**
 * __useAdminApproveVetterMutation__
 *
 * To run a mutation, you first call `useAdminApproveVetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminApproveVetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminApproveVetterMutation, { data, loading, error }] = useAdminApproveVetterMutation({
 *   variables: {
 *      vetterId: // value for 'vetterId'
 *   },
 * });
 */
export function useAdminApproveVetterMutation(baseOptions?: Apollo.MutationHookOptions<AdminApproveVetterMutation, AdminApproveVetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminApproveVetterMutation, AdminApproveVetterMutationVariables>(AdminApproveVetterDocument, options);
      }
export type AdminApproveVetterMutationHookResult = ReturnType<typeof useAdminApproveVetterMutation>;
export type AdminApproveVetterMutationResult = Apollo.MutationResult<AdminApproveVetterMutation>;
export type AdminApproveVetterMutationOptions = Apollo.BaseMutationOptions<AdminApproveVetterMutation, AdminApproveVetterMutationVariables>;
export const UpsertAdminConversationDocument = gql`
    mutation UpsertAdminConversation($phone: String!) {
  upsertAdminConversation(phone: $phone) {
    id
    toNumber
    fromNumber
    marked
  }
}
    `;
export type UpsertAdminConversationMutationFn = Apollo.MutationFunction<UpsertAdminConversationMutation, UpsertAdminConversationMutationVariables>;

/**
 * __useUpsertAdminConversationMutation__
 *
 * To run a mutation, you first call `useUpsertAdminConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAdminConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAdminConversationMutation, { data, loading, error }] = useUpsertAdminConversationMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpsertAdminConversationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAdminConversationMutation, UpsertAdminConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAdminConversationMutation, UpsertAdminConversationMutationVariables>(UpsertAdminConversationDocument, options);
      }
export type UpsertAdminConversationMutationHookResult = ReturnType<typeof useUpsertAdminConversationMutation>;
export type UpsertAdminConversationMutationResult = Apollo.MutationResult<UpsertAdminConversationMutation>;
export type UpsertAdminConversationMutationOptions = Apollo.BaseMutationOptions<UpsertAdminConversationMutation, UpsertAdminConversationMutationVariables>;
export const AdminCreateClientLedgerRecordDocument = gql`
    mutation AdminCreateClientLedgerRecord($clientId: ID!, $input: AdminUpsertClientLedgerRecordInput!) {
  adminCreateClientLedgerRecord(clientId: $clientId, input: $input) {
    ... on ClientLedgerRecord {
      id
    }
    ... on ValidationError {
      messages
    }
  }
}
    `;
export type AdminCreateClientLedgerRecordMutationFn = Apollo.MutationFunction<AdminCreateClientLedgerRecordMutation, AdminCreateClientLedgerRecordMutationVariables>;

/**
 * __useAdminCreateClientLedgerRecordMutation__
 *
 * To run a mutation, you first call `useAdminCreateClientLedgerRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateClientLedgerRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateClientLedgerRecordMutation, { data, loading, error }] = useAdminCreateClientLedgerRecordMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateClientLedgerRecordMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateClientLedgerRecordMutation, AdminCreateClientLedgerRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateClientLedgerRecordMutation, AdminCreateClientLedgerRecordMutationVariables>(AdminCreateClientLedgerRecordDocument, options);
      }
export type AdminCreateClientLedgerRecordMutationHookResult = ReturnType<typeof useAdminCreateClientLedgerRecordMutation>;
export type AdminCreateClientLedgerRecordMutationResult = Apollo.MutationResult<AdminCreateClientLedgerRecordMutation>;
export type AdminCreateClientLedgerRecordMutationOptions = Apollo.BaseMutationOptions<AdminCreateClientLedgerRecordMutation, AdminCreateClientLedgerRecordMutationVariables>;
export const AdminUpdateClientLedgerRecordDocument = gql`
    mutation AdminUpdateClientLedgerRecord($clientLedgerRecordId: ID!, $input: AdminUpsertClientLedgerRecordInput!) {
  adminUpdateClientLedgerRecord(id: $clientLedgerRecordId, input: $input) {
    ... on ClientLedgerRecord {
      id
      name
      numberOfSessions
      createdByAdminId
    }
    ... on NotFoundError {
      message
    }
  }
}
    `;
export type AdminUpdateClientLedgerRecordMutationFn = Apollo.MutationFunction<AdminUpdateClientLedgerRecordMutation, AdminUpdateClientLedgerRecordMutationVariables>;

/**
 * __useAdminUpdateClientLedgerRecordMutation__
 *
 * To run a mutation, you first call `useAdminUpdateClientLedgerRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateClientLedgerRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateClientLedgerRecordMutation, { data, loading, error }] = useAdminUpdateClientLedgerRecordMutation({
 *   variables: {
 *      clientLedgerRecordId: // value for 'clientLedgerRecordId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateClientLedgerRecordMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateClientLedgerRecordMutation, AdminUpdateClientLedgerRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateClientLedgerRecordMutation, AdminUpdateClientLedgerRecordMutationVariables>(AdminUpdateClientLedgerRecordDocument, options);
      }
export type AdminUpdateClientLedgerRecordMutationHookResult = ReturnType<typeof useAdminUpdateClientLedgerRecordMutation>;
export type AdminUpdateClientLedgerRecordMutationResult = Apollo.MutationResult<AdminUpdateClientLedgerRecordMutation>;
export type AdminUpdateClientLedgerRecordMutationOptions = Apollo.BaseMutationOptions<AdminUpdateClientLedgerRecordMutation, AdminUpdateClientLedgerRecordMutationVariables>;
export const AdminDeleteClientLedgerRecordDocument = gql`
    mutation AdminDeleteClientLedgerRecord($clientLedgerRecordId: ID!) {
  adminDeleteClientLedgerRecord(id: $clientLedgerRecordId) {
    ... on NotFoundError {
      message
    }
  }
}
    `;
export type AdminDeleteClientLedgerRecordMutationFn = Apollo.MutationFunction<AdminDeleteClientLedgerRecordMutation, AdminDeleteClientLedgerRecordMutationVariables>;

/**
 * __useAdminDeleteClientLedgerRecordMutation__
 *
 * To run a mutation, you first call `useAdminDeleteClientLedgerRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteClientLedgerRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteClientLedgerRecordMutation, { data, loading, error }] = useAdminDeleteClientLedgerRecordMutation({
 *   variables: {
 *      clientLedgerRecordId: // value for 'clientLedgerRecordId'
 *   },
 * });
 */
export function useAdminDeleteClientLedgerRecordMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteClientLedgerRecordMutation, AdminDeleteClientLedgerRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteClientLedgerRecordMutation, AdminDeleteClientLedgerRecordMutationVariables>(AdminDeleteClientLedgerRecordDocument, options);
      }
export type AdminDeleteClientLedgerRecordMutationHookResult = ReturnType<typeof useAdminDeleteClientLedgerRecordMutation>;
export type AdminDeleteClientLedgerRecordMutationResult = Apollo.MutationResult<AdminDeleteClientLedgerRecordMutation>;
export type AdminDeleteClientLedgerRecordMutationOptions = Apollo.BaseMutationOptions<AdminDeleteClientLedgerRecordMutation, AdminDeleteClientLedgerRecordMutationVariables>;
export const AdminAssignVetterToClientDocument = gql`
    mutation AdminAssignVetterToClient($vetterId: ID!, $clientId: ID) {
  adminAssignVetterToClient(vetterId: $vetterId, clientId: $clientId) {
    id
    clientId
  }
}
    `;
export type AdminAssignVetterToClientMutationFn = Apollo.MutationFunction<AdminAssignVetterToClientMutation, AdminAssignVetterToClientMutationVariables>;

/**
 * __useAdminAssignVetterToClientMutation__
 *
 * To run a mutation, you first call `useAdminAssignVetterToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignVetterToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignVetterToClientMutation, { data, loading, error }] = useAdminAssignVetterToClientMutation({
 *   variables: {
 *      vetterId: // value for 'vetterId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAdminAssignVetterToClientMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssignVetterToClientMutation, AdminAssignVetterToClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAssignVetterToClientMutation, AdminAssignVetterToClientMutationVariables>(AdminAssignVetterToClientDocument, options);
      }
export type AdminAssignVetterToClientMutationHookResult = ReturnType<typeof useAdminAssignVetterToClientMutation>;
export type AdminAssignVetterToClientMutationResult = Apollo.MutationResult<AdminAssignVetterToClientMutation>;
export type AdminAssignVetterToClientMutationOptions = Apollo.BaseMutationOptions<AdminAssignVetterToClientMutation, AdminAssignVetterToClientMutationVariables>;
export const AdminProcessApplicationDocument = gql`
    mutation AdminProcessApplication($application: ApplicationInput!) {
  adminProcessApplication(application: $application) {
    ... on SuccessResult {
      message
    }
    ... on ValidationError {
      messages
    }
    ... on NotFoundError {
      message
    }
  }
}
    `;
export type AdminProcessApplicationMutationFn = Apollo.MutationFunction<AdminProcessApplicationMutation, AdminProcessApplicationMutationVariables>;

/**
 * __useAdminProcessApplicationMutation__
 *
 * To run a mutation, you first call `useAdminProcessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminProcessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminProcessApplicationMutation, { data, loading, error }] = useAdminProcessApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useAdminProcessApplicationMutation(baseOptions?: Apollo.MutationHookOptions<AdminProcessApplicationMutation, AdminProcessApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminProcessApplicationMutation, AdminProcessApplicationMutationVariables>(AdminProcessApplicationDocument, options);
      }
export type AdminProcessApplicationMutationHookResult = ReturnType<typeof useAdminProcessApplicationMutation>;
export type AdminProcessApplicationMutationResult = Apollo.MutationResult<AdminProcessApplicationMutation>;
export type AdminProcessApplicationMutationOptions = Apollo.BaseMutationOptions<AdminProcessApplicationMutation, AdminProcessApplicationMutationVariables>;
export const AdminProcessApplicationsDocument = gql`
    mutation AdminProcessApplications($smsContentTemplate: String!, $applications: [ApplicationInput!]!) {
  adminProcessApplications(
    smsContentTemplate: $smsContentTemplate
    applications: $applications
  )
}
    `;
export type AdminProcessApplicationsMutationFn = Apollo.MutationFunction<AdminProcessApplicationsMutation, AdminProcessApplicationsMutationVariables>;

/**
 * __useAdminProcessApplicationsMutation__
 *
 * To run a mutation, you first call `useAdminProcessApplicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminProcessApplicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminProcessApplicationsMutation, { data, loading, error }] = useAdminProcessApplicationsMutation({
 *   variables: {
 *      smsContentTemplate: // value for 'smsContentTemplate'
 *      applications: // value for 'applications'
 *   },
 * });
 */
export function useAdminProcessApplicationsMutation(baseOptions?: Apollo.MutationHookOptions<AdminProcessApplicationsMutation, AdminProcessApplicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminProcessApplicationsMutation, AdminProcessApplicationsMutationVariables>(AdminProcessApplicationsDocument, options);
      }
export type AdminProcessApplicationsMutationHookResult = ReturnType<typeof useAdminProcessApplicationsMutation>;
export type AdminProcessApplicationsMutationResult = Apollo.MutationResult<AdminProcessApplicationsMutation>;
export type AdminProcessApplicationsMutationOptions = Apollo.BaseMutationOptions<AdminProcessApplicationsMutation, AdminProcessApplicationsMutationVariables>;
export const AdminTerminateSessionDocument = gql`
    mutation AdminTerminateSession($sessionId: ID!) {
  adminTerminateSession(sessionId: $sessionId) {
    ... on Session {
      id
      createdAt
      status
    }
  }
}
    `;
export type AdminTerminateSessionMutationFn = Apollo.MutationFunction<AdminTerminateSessionMutation, AdminTerminateSessionMutationVariables>;

/**
 * __useAdminTerminateSessionMutation__
 *
 * To run a mutation, you first call `useAdminTerminateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTerminateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTerminateSessionMutation, { data, loading, error }] = useAdminTerminateSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAdminTerminateSessionMutation(baseOptions?: Apollo.MutationHookOptions<AdminTerminateSessionMutation, AdminTerminateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminTerminateSessionMutation, AdminTerminateSessionMutationVariables>(AdminTerminateSessionDocument, options);
      }
export type AdminTerminateSessionMutationHookResult = ReturnType<typeof useAdminTerminateSessionMutation>;
export type AdminTerminateSessionMutationResult = Apollo.MutationResult<AdminTerminateSessionMutation>;
export type AdminTerminateSessionMutationOptions = Apollo.BaseMutationOptions<AdminTerminateSessionMutation, AdminTerminateSessionMutationVariables>;
export const ActivityDocument = gql`
    query Activity($pagination: PaginationInput!) {
  activityHistory(pagination: $pagination) {
    data {
      ...ActivityData
    }
    pagination {
      total
    }
  }
}
    ${ActivityDataFragmentDoc}`;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useActivityQuery(baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const ConversationsDocument = gql`
    query Conversations($pagination: PaginationInput!, $searchTerm: String) {
  conversations(pagination: $pagination, searchTerm: $searchTerm) {
    data {
      ...ConversationData
    }
    pagination {
      total
    }
  }
}
    ${ConversationDataFragmentDoc}`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const ConversationGroupsDocument = gql`
    query ConversationGroups {
  conversationGroups {
    id
    name
  }
}
    `;

/**
 * __useConversationGroupsQuery__
 *
 * To run a query within a React component, call `useConversationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversationGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ConversationGroupsQuery, ConversationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationGroupsQuery, ConversationGroupsQueryVariables>(ConversationGroupsDocument, options);
      }
export function useConversationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationGroupsQuery, ConversationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationGroupsQuery, ConversationGroupsQueryVariables>(ConversationGroupsDocument, options);
        }
export type ConversationGroupsQueryHookResult = ReturnType<typeof useConversationGroupsQuery>;
export type ConversationGroupsLazyQueryHookResult = ReturnType<typeof useConversationGroupsLazyQuery>;
export type ConversationGroupsQueryResult = Apollo.QueryResult<ConversationGroupsQuery, ConversationGroupsQueryVariables>;
export const SingleConversationGroupDocument = gql`
    query SingleConversationGroup($id: ID!) {
  conversationGroup(id: $id) {
    ... on ConversationGroup {
      id
      description
      lastInvokedAt
      invocationsCount
      members {
        ... on ConversationGroupMembers {
          phones
        }
        ... on ValidationError {
          messages
        }
      }
    }
  }
}
    `;

/**
 * __useSingleConversationGroupQuery__
 *
 * To run a query within a React component, call `useSingleConversationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleConversationGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleConversationGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleConversationGroupQuery(baseOptions: Apollo.QueryHookOptions<SingleConversationGroupQuery, SingleConversationGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleConversationGroupQuery, SingleConversationGroupQueryVariables>(SingleConversationGroupDocument, options);
      }
export function useSingleConversationGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleConversationGroupQuery, SingleConversationGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleConversationGroupQuery, SingleConversationGroupQueryVariables>(SingleConversationGroupDocument, options);
        }
export type SingleConversationGroupQueryHookResult = ReturnType<typeof useSingleConversationGroupQuery>;
export type SingleConversationGroupLazyQueryHookResult = ReturnType<typeof useSingleConversationGroupLazyQuery>;
export type SingleConversationGroupQueryResult = Apollo.QueryResult<SingleConversationGroupQuery, SingleConversationGroupQueryVariables>;
export const ConversationDocument = gql`
    query Conversation($conversationId: ID!) {
  conversation(id: $conversationId) {
    ... on Conversation {
      ...ConversationDetailedData
    }
  }
}
    ${ConversationDetailedDataFragmentDoc}`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const VettersDocument = gql`
    query Vetters($filter: VetterApprovalStatusEnum, $pagination: PaginationInput!, $searchTerm: String) {
  vettersCount(searchTerm: $searchTerm) {
    Awaiting
    All
    Active
  }
  vetters(filter: $filter, pagination: $pagination, searchTerm: $searchTerm) {
    data {
      ...AdminVetters
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${AdminVettersFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useVettersQuery__
 *
 * To run a query within a React component, call `useVettersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVettersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useVettersQuery(baseOptions: Apollo.QueryHookOptions<VettersQuery, VettersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VettersQuery, VettersQueryVariables>(VettersDocument, options);
      }
export function useVettersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VettersQuery, VettersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VettersQuery, VettersQueryVariables>(VettersDocument, options);
        }
export type VettersQueryHookResult = ReturnType<typeof useVettersQuery>;
export type VettersLazyQueryHookResult = ReturnType<typeof useVettersLazyQuery>;
export type VettersQueryResult = Apollo.QueryResult<VettersQuery, VettersQueryVariables>;
export const ClientsDocument = gql`
    query Clients {
  clients {
    id
    availability
    sessionsCount
    totalVettes
    remainingVettes
    companyName
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ClientDocument = gql`
    query Client($clientId: ID!) {
  client(clientId: $clientId) {
    ... on Client {
      id
      companyName
      availability
      clientUsers {
        phone
      }
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const LastSessionsDocument = gql`
    query LastSessions($filter: SessionStatusFilterEnum) {
  lastSessions(filter: $filter) {
    ...SessionDispatchingData
  }
}
    ${SessionDispatchingDataFragmentDoc}`;

/**
 * __useLastSessionsQuery__
 *
 * To run a query within a React component, call `useLastSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastSessionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLastSessionsQuery(baseOptions?: Apollo.QueryHookOptions<LastSessionsQuery, LastSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastSessionsQuery, LastSessionsQueryVariables>(LastSessionsDocument, options);
      }
export function useLastSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastSessionsQuery, LastSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastSessionsQuery, LastSessionsQueryVariables>(LastSessionsDocument, options);
        }
export type LastSessionsQueryHookResult = ReturnType<typeof useLastSessionsQuery>;
export type LastSessionsLazyQueryHookResult = ReturnType<typeof useLastSessionsLazyQuery>;
export type LastSessionsQueryResult = Apollo.QueryResult<LastSessionsQuery, LastSessionsQueryVariables>;
export const ClientLedgerStatsDocument = gql`
    query ClientLedgerStats($clientId: ID!) {
  client(clientId: $clientId) {
    ... on Client {
      id
      totalVettes
      consumedVettes
      remainingVettes
    }
  }
}
    `;

/**
 * __useClientLedgerStatsQuery__
 *
 * To run a query within a React component, call `useClientLedgerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLedgerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLedgerStatsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientLedgerStatsQuery(baseOptions: Apollo.QueryHookOptions<ClientLedgerStatsQuery, ClientLedgerStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientLedgerStatsQuery, ClientLedgerStatsQueryVariables>(ClientLedgerStatsDocument, options);
      }
export function useClientLedgerStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientLedgerStatsQuery, ClientLedgerStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientLedgerStatsQuery, ClientLedgerStatsQueryVariables>(ClientLedgerStatsDocument, options);
        }
export type ClientLedgerStatsQueryHookResult = ReturnType<typeof useClientLedgerStatsQuery>;
export type ClientLedgerStatsLazyQueryHookResult = ReturnType<typeof useClientLedgerStatsLazyQuery>;
export type ClientLedgerStatsQueryResult = Apollo.QueryResult<ClientLedgerStatsQuery, ClientLedgerStatsQueryVariables>;
export const ClientLedgerRecordsDocument = gql`
    query ClientLedgerRecords($clientId: ID!) {
  clientLedgerRecords(clientId: $clientId) {
    id
    name
    numberOfSessions
    clientId
    createdByAdminId
    createdAt
  }
}
    `;

/**
 * __useClientLedgerRecordsQuery__
 *
 * To run a query within a React component, call `useClientLedgerRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLedgerRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLedgerRecordsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientLedgerRecordsQuery(baseOptions: Apollo.QueryHookOptions<ClientLedgerRecordsQuery, ClientLedgerRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientLedgerRecordsQuery, ClientLedgerRecordsQueryVariables>(ClientLedgerRecordsDocument, options);
      }
export function useClientLedgerRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientLedgerRecordsQuery, ClientLedgerRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientLedgerRecordsQuery, ClientLedgerRecordsQueryVariables>(ClientLedgerRecordsDocument, options);
        }
export type ClientLedgerRecordsQueryHookResult = ReturnType<typeof useClientLedgerRecordsQuery>;
export type ClientLedgerRecordsLazyQueryHookResult = ReturnType<typeof useClientLedgerRecordsLazyQuery>;
export type ClientLedgerRecordsQueryResult = Apollo.QueryResult<ClientLedgerRecordsQuery, ClientLedgerRecordsQueryVariables>;
export const SingleClientLedgerRecordDocument = gql`
    query SingleClientLedgerRecord($clientLedgerRecordId: ID!) {
  clientLedgerRecord(clientLedgerRecordId: $clientLedgerRecordId) {
    ... on ClientLedgerRecord {
      id
      name
      numberOfSessions
    }
  }
}
    `;

/**
 * __useSingleClientLedgerRecordQuery__
 *
 * To run a query within a React component, call `useSingleClientLedgerRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleClientLedgerRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleClientLedgerRecordQuery({
 *   variables: {
 *      clientLedgerRecordId: // value for 'clientLedgerRecordId'
 *   },
 * });
 */
export function useSingleClientLedgerRecordQuery(baseOptions: Apollo.QueryHookOptions<SingleClientLedgerRecordQuery, SingleClientLedgerRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleClientLedgerRecordQuery, SingleClientLedgerRecordQueryVariables>(SingleClientLedgerRecordDocument, options);
      }
export function useSingleClientLedgerRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleClientLedgerRecordQuery, SingleClientLedgerRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleClientLedgerRecordQuery, SingleClientLedgerRecordQueryVariables>(SingleClientLedgerRecordDocument, options);
        }
export type SingleClientLedgerRecordQueryHookResult = ReturnType<typeof useSingleClientLedgerRecordQuery>;
export type SingleClientLedgerRecordLazyQueryHookResult = ReturnType<typeof useSingleClientLedgerRecordLazyQuery>;
export type SingleClientLedgerRecordQueryResult = Apollo.QueryResult<SingleClientLedgerRecordQuery, SingleClientLedgerRecordQueryVariables>;
export const GlobalActivityHistoryAddedDocument = gql`
    subscription GlobalActivityHistoryAdded {
  globalActivityHistoryAdded {
    ... on GlobalActivityHistory {
      ...ActivityData
    }
  }
}
    ${ActivityDataFragmentDoc}`;

/**
 * __useGlobalActivityHistoryAddedSubscription__
 *
 * To run a query within a React component, call `useGlobalActivityHistoryAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGlobalActivityHistoryAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalActivityHistoryAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGlobalActivityHistoryAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GlobalActivityHistoryAddedSubscription, GlobalActivityHistoryAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GlobalActivityHistoryAddedSubscription, GlobalActivityHistoryAddedSubscriptionVariables>(GlobalActivityHistoryAddedDocument, options);
      }
export type GlobalActivityHistoryAddedSubscriptionHookResult = ReturnType<typeof useGlobalActivityHistoryAddedSubscription>;
export type GlobalActivityHistoryAddedSubscriptionResult = Apollo.SubscriptionResult<GlobalActivityHistoryAddedSubscription>;
export const ConversationsListUpdatedDocument = gql`
    subscription ConversationsListUpdated {
  conversationsListUpdated {
    ... on Conversation {
      ...ConversationDetailedData
    }
  }
}
    ${ConversationDetailedDataFragmentDoc}`;

/**
 * __useConversationsListUpdatedSubscription__
 *
 * To run a query within a React component, call `useConversationsListUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationsListUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsListUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConversationsListUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ConversationsListUpdatedSubscription, ConversationsListUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ConversationsListUpdatedSubscription, ConversationsListUpdatedSubscriptionVariables>(ConversationsListUpdatedDocument, options);
      }
export type ConversationsListUpdatedSubscriptionHookResult = ReturnType<typeof useConversationsListUpdatedSubscription>;
export type ConversationsListUpdatedSubscriptionResult = Apollo.SubscriptionResult<ConversationsListUpdatedSubscription>;
export const InterviewNowDocument = gql`
    mutation InterviewNow($jobOfferId: ID!, $input: InterviewNowInput!) {
  interviewNow(jobOfferId: $jobOfferId, input: $input) {
    ... on SessionId {
      id
    }
    ... on ApplicantDidNotPassKnockout {
      sessionId
    }
    ... on SessionInProgressError {
      sessionId
    }
    ... on ValidationError {
      fields
    }
    ... on AttemptedToInterviewAgainError {
      message
    }
    ... on MaximumPassedApplicantsPerJobExceededError {
      message
    }
  }
}
    `;
export type InterviewNowMutationFn = Apollo.MutationFunction<InterviewNowMutation, InterviewNowMutationVariables>;

/**
 * __useInterviewNowMutation__
 *
 * To run a mutation, you first call `useInterviewNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewNowMutation, { data, loading, error }] = useInterviewNowMutation({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewNowMutation(baseOptions?: Apollo.MutationHookOptions<InterviewNowMutation, InterviewNowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewNowMutation, InterviewNowMutationVariables>(InterviewNowDocument, options);
      }
export type InterviewNowMutationHookResult = ReturnType<typeof useInterviewNowMutation>;
export type InterviewNowMutationResult = Apollo.MutationResult<InterviewNowMutation>;
export type InterviewNowMutationOptions = Apollo.BaseMutationOptions<InterviewNowMutation, InterviewNowMutationVariables>;
export const ApplicantLogDocument = gql`
    mutation ApplicantLog($input: ApplicantLogInput!) {
  applicantLog(input: $input) {
    id
  }
}
    `;
export type ApplicantLogMutationFn = Apollo.MutationFunction<ApplicantLogMutation, ApplicantLogMutationVariables>;

/**
 * __useApplicantLogMutation__
 *
 * To run a mutation, you first call `useApplicantLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicantLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicantLogMutation, { data, loading, error }] = useApplicantLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicantLogMutation(baseOptions?: Apollo.MutationHookOptions<ApplicantLogMutation, ApplicantLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicantLogMutation, ApplicantLogMutationVariables>(ApplicantLogDocument, options);
      }
export type ApplicantLogMutationHookResult = ReturnType<typeof useApplicantLogMutation>;
export type ApplicantLogMutationResult = Apollo.MutationResult<ApplicantLogMutation>;
export type ApplicantLogMutationOptions = Apollo.BaseMutationOptions<ApplicantLogMutation, ApplicantLogMutationVariables>;
export const JobOfferDocument = gql`
    query JobOffer($jobOfferId: ID!) {
  jobOffer(id: $jobOfferId) {
    ... on JobOffer {
      id
      title
      description
      salary
      locations
      availability
      knockoutQuestions {
        ...ScriptQuestionData
      }
      client {
        id
        companyName
        remainingVettes
      }
    }
    ...OnNotFound
  }
}
    ${ScriptQuestionDataFragmentDoc}
${OnNotFoundFragmentDoc}`;

/**
 * __useJobOfferQuery__
 *
 * To run a query within a React component, call `useJobOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOfferQuery({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *   },
 * });
 */
export function useJobOfferQuery(baseOptions: Apollo.QueryHookOptions<JobOfferQuery, JobOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobOfferQuery, JobOfferQueryVariables>(JobOfferDocument, options);
      }
export function useJobOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobOfferQuery, JobOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobOfferQuery, JobOfferQueryVariables>(JobOfferDocument, options);
        }
export type JobOfferQueryHookResult = ReturnType<typeof useJobOfferQuery>;
export type JobOfferLazyQueryHookResult = ReturnType<typeof useJobOfferLazyQuery>;
export type JobOfferQueryResult = Apollo.QueryResult<JobOfferQuery, JobOfferQueryVariables>;
export const InterviewerDocument = gql`
    query Interviewer($sessionId: ID!) {
  interviewer(id: $sessionId) {
    ...Interviewer
    ... on NotFoundError {
      message
    }
  }
}
    ${InterviewerFragmentDoc}`;

/**
 * __useInterviewerQuery__
 *
 * To run a query within a React component, call `useInterviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useInterviewerQuery(baseOptions: Apollo.QueryHookOptions<InterviewerQuery, InterviewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerQuery, InterviewerQueryVariables>(InterviewerDocument, options);
      }
export function useInterviewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerQuery, InterviewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerQuery, InterviewerQueryVariables>(InterviewerDocument, options);
        }
export type InterviewerQueryHookResult = ReturnType<typeof useInterviewerQuery>;
export type InterviewerLazyQueryHookResult = ReturnType<typeof useInterviewerLazyQuery>;
export type InterviewerQueryResult = Apollo.QueryResult<InterviewerQuery, InterviewerQueryVariables>;
export const CreateScriptDocument = gql`
    mutation CreateScript($clientId: ID!, $input: UpsertScriptInput!) {
  createScript(clientId: $clientId, input: $input) {
    ...OnValidationError
    ...ScriptData
  }
}
    ${OnValidationErrorFragmentDoc}
${ScriptDataFragmentDoc}`;
export type CreateScriptMutationFn = Apollo.MutationFunction<CreateScriptMutation, CreateScriptMutationVariables>;

/**
 * __useCreateScriptMutation__
 *
 * To run a mutation, you first call `useCreateScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScriptMutation, { data, loading, error }] = useCreateScriptMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScriptMutation(baseOptions?: Apollo.MutationHookOptions<CreateScriptMutation, CreateScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScriptMutation, CreateScriptMutationVariables>(CreateScriptDocument, options);
      }
export type CreateScriptMutationHookResult = ReturnType<typeof useCreateScriptMutation>;
export type CreateScriptMutationResult = Apollo.MutationResult<CreateScriptMutation>;
export type CreateScriptMutationOptions = Apollo.BaseMutationOptions<CreateScriptMutation, CreateScriptMutationVariables>;
export const CreateNewScriptVersionDocument = gql`
    mutation CreateNewScriptVersion($scriptId: ID!, $input: UpsertScriptInput!) {
  createNewScriptVersion(scriptId: $scriptId, input: $input) {
    ...OnValidationError
    ...ScriptData
  }
}
    ${OnValidationErrorFragmentDoc}
${ScriptDataFragmentDoc}`;
export type CreateNewScriptVersionMutationFn = Apollo.MutationFunction<CreateNewScriptVersionMutation, CreateNewScriptVersionMutationVariables>;

/**
 * __useCreateNewScriptVersionMutation__
 *
 * To run a mutation, you first call `useCreateNewScriptVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewScriptVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewScriptVersionMutation, { data, loading, error }] = useCreateNewScriptVersionMutation({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewScriptVersionMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewScriptVersionMutation, CreateNewScriptVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewScriptVersionMutation, CreateNewScriptVersionMutationVariables>(CreateNewScriptVersionDocument, options);
      }
export type CreateNewScriptVersionMutationHookResult = ReturnType<typeof useCreateNewScriptVersionMutation>;
export type CreateNewScriptVersionMutationResult = Apollo.MutationResult<CreateNewScriptVersionMutation>;
export type CreateNewScriptVersionMutationOptions = Apollo.BaseMutationOptions<CreateNewScriptVersionMutation, CreateNewScriptVersionMutationVariables>;
export const DuplicateScriptDocument = gql`
    mutation DuplicateScript($scriptId: ID!) {
  duplicateScript(scriptId: $scriptId) {
    ...OnValidationError
    ...ScriptData
  }
}
    ${OnValidationErrorFragmentDoc}
${ScriptDataFragmentDoc}`;
export type DuplicateScriptMutationFn = Apollo.MutationFunction<DuplicateScriptMutation, DuplicateScriptMutationVariables>;

/**
 * __useDuplicateScriptMutation__
 *
 * To run a mutation, you first call `useDuplicateScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateScriptMutation, { data, loading, error }] = useDuplicateScriptMutation({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useDuplicateScriptMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateScriptMutation, DuplicateScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateScriptMutation, DuplicateScriptMutationVariables>(DuplicateScriptDocument, options);
      }
export type DuplicateScriptMutationHookResult = ReturnType<typeof useDuplicateScriptMutation>;
export type DuplicateScriptMutationResult = Apollo.MutationResult<DuplicateScriptMutation>;
export type DuplicateScriptMutationOptions = Apollo.BaseMutationOptions<DuplicateScriptMutation, DuplicateScriptMutationVariables>;
export const ArchiveScriptDocument = gql`
    mutation ArchiveScript($scriptId: ID!) {
  archiveScript(scriptId: $scriptId) {
    ... on NotFoundError {
      message
    }
    ... on Script {
      id
    }
  }
}
    `;
export type ArchiveScriptMutationFn = Apollo.MutationFunction<ArchiveScriptMutation, ArchiveScriptMutationVariables>;

/**
 * __useArchiveScriptMutation__
 *
 * To run a mutation, you first call `useArchiveScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScriptMutation, { data, loading, error }] = useArchiveScriptMutation({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useArchiveScriptMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveScriptMutation, ArchiveScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveScriptMutation, ArchiveScriptMutationVariables>(ArchiveScriptDocument, options);
      }
export type ArchiveScriptMutationHookResult = ReturnType<typeof useArchiveScriptMutation>;
export type ArchiveScriptMutationResult = Apollo.MutationResult<ArchiveScriptMutation>;
export type ArchiveScriptMutationOptions = Apollo.BaseMutationOptions<ArchiveScriptMutation, ArchiveScriptMutationVariables>;
export const CreateJobOfferDocument = gql`
    mutation CreateJobOffer($clientId: ID!, $input: CreateJobOfferInput!, $parentGroupId: ID!) {
  createJobOffer(
    clientId: $clientId
    input: $input
    parentGroupId: $parentGroupId
  ) {
    ...JobOfferData
    ... on ValidationError {
      fields
      messages
      code
    }
  }
}
    ${JobOfferDataFragmentDoc}`;
export type CreateJobOfferMutationFn = Apollo.MutationFunction<CreateJobOfferMutation, CreateJobOfferMutationVariables>;

/**
 * __useCreateJobOfferMutation__
 *
 * To run a mutation, you first call `useCreateJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobOfferMutation, { data, loading, error }] = useCreateJobOfferMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *      parentGroupId: // value for 'parentGroupId'
 *   },
 * });
 */
export function useCreateJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobOfferMutation, CreateJobOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobOfferMutation, CreateJobOfferMutationVariables>(CreateJobOfferDocument, options);
      }
export type CreateJobOfferMutationHookResult = ReturnType<typeof useCreateJobOfferMutation>;
export type CreateJobOfferMutationResult = Apollo.MutationResult<CreateJobOfferMutation>;
export type CreateJobOfferMutationOptions = Apollo.BaseMutationOptions<CreateJobOfferMutation, CreateJobOfferMutationVariables>;
export const UpdateJobOfferDocument = gql`
    mutation UpdateJobOffer($jobOfferId: ID!, $input: UpdateJobOfferInput!) {
  updateJobOffer(jobOfferId: $jobOfferId, input: $input) {
    ...JobOfferData
    ... on ValidationError {
      fields
      messages
      code
    }
  }
}
    ${JobOfferDataFragmentDoc}`;
export type UpdateJobOfferMutationFn = Apollo.MutationFunction<UpdateJobOfferMutation, UpdateJobOfferMutationVariables>;

/**
 * __useUpdateJobOfferMutation__
 *
 * To run a mutation, you first call `useUpdateJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobOfferMutation, { data, loading, error }] = useUpdateJobOfferMutation({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobOfferMutation, UpdateJobOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobOfferMutation, UpdateJobOfferMutationVariables>(UpdateJobOfferDocument, options);
      }
export type UpdateJobOfferMutationHookResult = ReturnType<typeof useUpdateJobOfferMutation>;
export type UpdateJobOfferMutationResult = Apollo.MutationResult<UpdateJobOfferMutation>;
export type UpdateJobOfferMutationOptions = Apollo.BaseMutationOptions<UpdateJobOfferMutation, UpdateJobOfferMutationVariables>;
export const ResendSessionEmailDocument = gql`
    mutation ResendSessionEmail($sessionId: ID!) {
  resendSessionEmail(sessionId: $sessionId) {
    ... on SuccessResult {
      message
    }
  }
}
    `;
export type ResendSessionEmailMutationFn = Apollo.MutationFunction<ResendSessionEmailMutation, ResendSessionEmailMutationVariables>;

/**
 * __useResendSessionEmailMutation__
 *
 * To run a mutation, you first call `useResendSessionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSessionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSessionEmailMutation, { data, loading, error }] = useResendSessionEmailMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useResendSessionEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendSessionEmailMutation, ResendSessionEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendSessionEmailMutation, ResendSessionEmailMutationVariables>(ResendSessionEmailDocument, options);
      }
export type ResendSessionEmailMutationHookResult = ReturnType<typeof useResendSessionEmailMutation>;
export type ResendSessionEmailMutationResult = Apollo.MutationResult<ResendSessionEmailMutation>;
export type ResendSessionEmailMutationOptions = Apollo.BaseMutationOptions<ResendSessionEmailMutation, ResendSessionEmailMutationVariables>;
export const TranscriptSessionDocument = gql`
    mutation TranscriptSession($sessionId: ID!) {
  transcriptSession(sessionId: $sessionId) {
    ... on Session {
      id
      sessionTranscription {
        ...SessionTranscriptionData
      }
    }
  }
}
    ${SessionTranscriptionDataFragmentDoc}`;
export type TranscriptSessionMutationFn = Apollo.MutationFunction<TranscriptSessionMutation, TranscriptSessionMutationVariables>;

/**
 * __useTranscriptSessionMutation__
 *
 * To run a mutation, you first call `useTranscriptSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranscriptSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transcriptSessionMutation, { data, loading, error }] = useTranscriptSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useTranscriptSessionMutation(baseOptions?: Apollo.MutationHookOptions<TranscriptSessionMutation, TranscriptSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranscriptSessionMutation, TranscriptSessionMutationVariables>(TranscriptSessionDocument, options);
      }
export type TranscriptSessionMutationHookResult = ReturnType<typeof useTranscriptSessionMutation>;
export type TranscriptSessionMutationResult = Apollo.MutationResult<TranscriptSessionMutation>;
export type TranscriptSessionMutationOptions = Apollo.BaseMutationOptions<TranscriptSessionMutation, TranscriptSessionMutationVariables>;
export const CreateJobGroupDocument = gql`
    mutation CreateJobGroup($clientId: ID!, $parentGroupPath: String!, $name: String!) {
  createJobGroup(
    clientId: $clientId
    parentGroupPath: $parentGroupPath
    name: $name
  ) {
    ... on ValidationError {
      fields
      messages
      code
    }
    ...JobGroupData
  }
}
    ${JobGroupDataFragmentDoc}`;
export type CreateJobGroupMutationFn = Apollo.MutationFunction<CreateJobGroupMutation, CreateJobGroupMutationVariables>;

/**
 * __useCreateJobGroupMutation__
 *
 * To run a mutation, you first call `useCreateJobGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobGroupMutation, { data, loading, error }] = useCreateJobGroupMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      parentGroupPath: // value for 'parentGroupPath'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateJobGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobGroupMutation, CreateJobGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobGroupMutation, CreateJobGroupMutationVariables>(CreateJobGroupDocument, options);
      }
export type CreateJobGroupMutationHookResult = ReturnType<typeof useCreateJobGroupMutation>;
export type CreateJobGroupMutationResult = Apollo.MutationResult<CreateJobGroupMutation>;
export type CreateJobGroupMutationOptions = Apollo.BaseMutationOptions<CreateJobGroupMutation, CreateJobGroupMutationVariables>;
export const RenameJobGroupDocument = gql`
    mutation RenameJobGroup($clientId: ID!, $jobGroupPath: String!, $name: String!) {
  renameJobGroup(clientId: $clientId, jobGroupPath: $jobGroupPath, name: $name) {
    ... on ValidationError {
      fields
      messages
      code
    }
    ... on JobGroupList {
      jobGroups {
        ...JobGroupData
      }
    }
  }
}
    ${JobGroupDataFragmentDoc}`;
export type RenameJobGroupMutationFn = Apollo.MutationFunction<RenameJobGroupMutation, RenameJobGroupMutationVariables>;

/**
 * __useRenameJobGroupMutation__
 *
 * To run a mutation, you first call `useRenameJobGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameJobGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameJobGroupMutation, { data, loading, error }] = useRenameJobGroupMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameJobGroupMutation(baseOptions?: Apollo.MutationHookOptions<RenameJobGroupMutation, RenameJobGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameJobGroupMutation, RenameJobGroupMutationVariables>(RenameJobGroupDocument, options);
      }
export type RenameJobGroupMutationHookResult = ReturnType<typeof useRenameJobGroupMutation>;
export type RenameJobGroupMutationResult = Apollo.MutationResult<RenameJobGroupMutation>;
export type RenameJobGroupMutationOptions = Apollo.BaseMutationOptions<RenameJobGroupMutation, RenameJobGroupMutationVariables>;
export const DeleteJobGroupDocument = gql`
    mutation DeleteJobGroup($clientId: ID!, $jobGroupPath: String!) {
  deleteJobGroup(clientId: $clientId, jobGroupPath: $jobGroupPath) {
    ... on JobGroupList {
      jobGroups {
        ... on JobGroup {
          id
        }
      }
    }
  }
}
    `;
export type DeleteJobGroupMutationFn = Apollo.MutationFunction<DeleteJobGroupMutation, DeleteJobGroupMutationVariables>;

/**
 * __useDeleteJobGroupMutation__
 *
 * To run a mutation, you first call `useDeleteJobGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobGroupMutation, { data, loading, error }] = useDeleteJobGroupMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *   },
 * });
 */
export function useDeleteJobGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobGroupMutation, DeleteJobGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobGroupMutation, DeleteJobGroupMutationVariables>(DeleteJobGroupDocument, options);
      }
export type DeleteJobGroupMutationHookResult = ReturnType<typeof useDeleteJobGroupMutation>;
export type DeleteJobGroupMutationResult = Apollo.MutationResult<DeleteJobGroupMutation>;
export type DeleteJobGroupMutationOptions = Apollo.BaseMutationOptions<DeleteJobGroupMutation, DeleteJobGroupMutationVariables>;
export const AddClientUsersAccessDocument = gql`
    mutation AddClientUsersAccess($clientId: ID!, $clientUserIds: [ID!]!, $jobGroupPath: String!) {
  addClientUsersAccess(
    clientId: $clientId
    clientUserIds: $clientUserIds
    jobGroupPath: $jobGroupPath
  ) {
    ...ClientUserAccessData
  }
}
    ${ClientUserAccessDataFragmentDoc}`;
export type AddClientUsersAccessMutationFn = Apollo.MutationFunction<AddClientUsersAccessMutation, AddClientUsersAccessMutationVariables>;

/**
 * __useAddClientUsersAccessMutation__
 *
 * To run a mutation, you first call `useAddClientUsersAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientUsersAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientUsersAccessMutation, { data, loading, error }] = useAddClientUsersAccessMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      clientUserIds: // value for 'clientUserIds'
 *      jobGroupPath: // value for 'jobGroupPath'
 *   },
 * });
 */
export function useAddClientUsersAccessMutation(baseOptions?: Apollo.MutationHookOptions<AddClientUsersAccessMutation, AddClientUsersAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientUsersAccessMutation, AddClientUsersAccessMutationVariables>(AddClientUsersAccessDocument, options);
      }
export type AddClientUsersAccessMutationHookResult = ReturnType<typeof useAddClientUsersAccessMutation>;
export type AddClientUsersAccessMutationResult = Apollo.MutationResult<AddClientUsersAccessMutation>;
export type AddClientUsersAccessMutationOptions = Apollo.BaseMutationOptions<AddClientUsersAccessMutation, AddClientUsersAccessMutationVariables>;
export const RemoveClientUserAccessDocument = gql`
    mutation RemoveClientUserAccess($clientId: ID!, $jobGroupPath: String!, $clientUserId: ID!) {
  removeClientUserAccess(
    clientId: $clientId
    jobGroupPath: $jobGroupPath
    clientUserId: $clientUserId
  ) {
    ...ClientUserAccessData
  }
}
    ${ClientUserAccessDataFragmentDoc}`;
export type RemoveClientUserAccessMutationFn = Apollo.MutationFunction<RemoveClientUserAccessMutation, RemoveClientUserAccessMutationVariables>;

/**
 * __useRemoveClientUserAccessMutation__
 *
 * To run a mutation, you first call `useRemoveClientUserAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientUserAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientUserAccessMutation, { data, loading, error }] = useRemoveClientUserAccessMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *      clientUserId: // value for 'clientUserId'
 *   },
 * });
 */
export function useRemoveClientUserAccessMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientUserAccessMutation, RemoveClientUserAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientUserAccessMutation, RemoveClientUserAccessMutationVariables>(RemoveClientUserAccessDocument, options);
      }
export type RemoveClientUserAccessMutationHookResult = ReturnType<typeof useRemoveClientUserAccessMutation>;
export type RemoveClientUserAccessMutationResult = Apollo.MutationResult<RemoveClientUserAccessMutation>;
export type RemoveClientUserAccessMutationOptions = Apollo.BaseMutationOptions<RemoveClientUserAccessMutation, RemoveClientUserAccessMutationVariables>;
export const MoveJobGroupDocument = gql`
    mutation MoveJobGroup($clientId: ID!, $jobGroupPath: String!, $parentGroupPath: String!) {
  moveJobGroup(
    clientId: $clientId
    jobGroupPath: $jobGroupPath
    parentGroupPath: $parentGroupPath
  ) {
    ... on ValidationError {
      fields
      messages
      code
    }
    ... on JobGroupList {
      jobGroups {
        ...JobGroupData
      }
    }
  }
}
    ${JobGroupDataFragmentDoc}`;
export type MoveJobGroupMutationFn = Apollo.MutationFunction<MoveJobGroupMutation, MoveJobGroupMutationVariables>;

/**
 * __useMoveJobGroupMutation__
 *
 * To run a mutation, you first call `useMoveJobGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveJobGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveJobGroupMutation, { data, loading, error }] = useMoveJobGroupMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *      parentGroupPath: // value for 'parentGroupPath'
 *   },
 * });
 */
export function useMoveJobGroupMutation(baseOptions?: Apollo.MutationHookOptions<MoveJobGroupMutation, MoveJobGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveJobGroupMutation, MoveJobGroupMutationVariables>(MoveJobGroupDocument, options);
      }
export type MoveJobGroupMutationHookResult = ReturnType<typeof useMoveJobGroupMutation>;
export type MoveJobGroupMutationResult = Apollo.MutationResult<MoveJobGroupMutation>;
export type MoveJobGroupMutationOptions = Apollo.BaseMutationOptions<MoveJobGroupMutation, MoveJobGroupMutationVariables>;
export const CloneJobGroupDocument = gql`
    mutation CloneJobGroup($clientId: ID!, $jobGroupPath: String!, $parentGroupPath: String!) {
  cloneJobGroup(
    clientId: $clientId
    jobGroupPath: $jobGroupPath
    parentGroupPath: $parentGroupPath
  ) {
    ... on JobGroupList {
      jobGroups {
        ...JobGroupData
      }
    }
  }
}
    ${JobGroupDataFragmentDoc}`;
export type CloneJobGroupMutationFn = Apollo.MutationFunction<CloneJobGroupMutation, CloneJobGroupMutationVariables>;

/**
 * __useCloneJobGroupMutation__
 *
 * To run a mutation, you first call `useCloneJobGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneJobGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneJobGroupMutation, { data, loading, error }] = useCloneJobGroupMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *      parentGroupPath: // value for 'parentGroupPath'
 *   },
 * });
 */
export function useCloneJobGroupMutation(baseOptions?: Apollo.MutationHookOptions<CloneJobGroupMutation, CloneJobGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneJobGroupMutation, CloneJobGroupMutationVariables>(CloneJobGroupDocument, options);
      }
export type CloneJobGroupMutationHookResult = ReturnType<typeof useCloneJobGroupMutation>;
export type CloneJobGroupMutationResult = Apollo.MutationResult<CloneJobGroupMutation>;
export type CloneJobGroupMutationOptions = Apollo.BaseMutationOptions<CloneJobGroupMutation, CloneJobGroupMutationVariables>;
export const InviteUsersDocument = gql`
    mutation InviteUsers($clientId: ID!, $users: [InviteUsersInput!]!) {
  inviteUsers(clientId: $clientId, users: $users) {
    ...OnValidationError
    ... on SuccessResult {
      message
    }
  }
}
    ${OnValidationErrorFragmentDoc}`;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($userId: ID!, $clientId: ID!) {
  removeTeamMember(userId: $userId, clientId: $clientId) {
    message
    code
  }
}
    `;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, options);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const AcceptUserInviteDocument = gql`
    mutation AcceptUserInvite($input: AcceptUserInviteInput!) {
  acceptUserInvite(input: $input) {
    ... on User {
      id
      phone
      role
      lastName
      invitationStatus
    }
    ... on ValidationError {
      code
      fields
      messages
    }
    ... on NotFoundError {
      code
      message
    }
  }
}
    `;
export type AcceptUserInviteMutationFn = Apollo.MutationFunction<AcceptUserInviteMutation, AcceptUserInviteMutationVariables>;

/**
 * __useAcceptUserInviteMutation__
 *
 * To run a mutation, you first call `useAcceptUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptUserInviteMutation, { data, loading, error }] = useAcceptUserInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptUserInviteMutation, AcceptUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptUserInviteMutation, AcceptUserInviteMutationVariables>(AcceptUserInviteDocument, options);
      }
export type AcceptUserInviteMutationHookResult = ReturnType<typeof useAcceptUserInviteMutation>;
export type AcceptUserInviteMutationResult = Apollo.MutationResult<AcceptUserInviteMutation>;
export type AcceptUserInviteMutationOptions = Apollo.BaseMutationOptions<AcceptUserInviteMutation, AcceptUserInviteMutationVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($sessionId: ID!, $input: UpdateSessionInput!) {
  updateSession(sessionId: $sessionId, input: $input) {
    ... on SuccessResult {
      code
      message
    }
  }
}
    `;
export type UpdateSessionMutationFn = Apollo.MutationFunction<UpdateSessionMutation, UpdateSessionMutationVariables>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionMutation, UpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, options);
      }
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;
export const ClientNameDocument = gql`
    query ClientName($clientId: ID!) {
  client(clientId: $clientId) {
    ... on Client {
      id
      companyName
    }
  }
}
    `;

/**
 * __useClientNameQuery__
 *
 * To run a query within a React component, call `useClientNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientNameQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientNameQuery(baseOptions: Apollo.QueryHookOptions<ClientNameQuery, ClientNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientNameQuery, ClientNameQueryVariables>(ClientNameDocument, options);
      }
export function useClientNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientNameQuery, ClientNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientNameQuery, ClientNameQueryVariables>(ClientNameDocument, options);
        }
export type ClientNameQueryHookResult = ReturnType<typeof useClientNameQuery>;
export type ClientNameLazyQueryHookResult = ReturnType<typeof useClientNameLazyQuery>;
export type ClientNameQueryResult = Apollo.QueryResult<ClientNameQuery, ClientNameQueryVariables>;
export const ScriptsDocument = gql`
    query Scripts($clientId: ID!, $status: ScriptStatusEnum) {
  scripts(clientId: $clientId, status: $status) {
    id
    name
    status
    jobOffersCount
  }
}
    `;

/**
 * __useScriptsQuery__
 *
 * To run a query within a React component, call `useScriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useScriptsQuery(baseOptions: Apollo.QueryHookOptions<ScriptsQuery, ScriptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptsQuery, ScriptsQueryVariables>(ScriptsDocument, options);
      }
export function useScriptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptsQuery, ScriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptsQuery, ScriptsQueryVariables>(ScriptsDocument, options);
        }
export type ScriptsQueryHookResult = ReturnType<typeof useScriptsQuery>;
export type ScriptsLazyQueryHookResult = ReturnType<typeof useScriptsLazyQuery>;
export type ScriptsQueryResult = Apollo.QueryResult<ScriptsQuery, ScriptsQueryVariables>;
export const ScriptDocument = gql`
    query Script($scriptId: ID!) {
  script(id: $scriptId) {
    ... on Script {
      ...ScriptData
    }
    ...OnNotFound
  }
}
    ${ScriptDataFragmentDoc}
${OnNotFoundFragmentDoc}`;

/**
 * __useScriptQuery__
 *
 * To run a query within a React component, call `useScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptQuery({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useScriptQuery(baseOptions: Apollo.QueryHookOptions<ScriptQuery, ScriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptQuery, ScriptQueryVariables>(ScriptDocument, options);
      }
export function useScriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptQuery, ScriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptQuery, ScriptQueryVariables>(ScriptDocument, options);
        }
export type ScriptQueryHookResult = ReturnType<typeof useScriptQuery>;
export type ScriptLazyQueryHookResult = ReturnType<typeof useScriptLazyQuery>;
export type ScriptQueryResult = Apollo.QueryResult<ScriptQuery, ScriptQueryVariables>;
export const JobsHierarchyDocument = gql`
    query JobsHierarchy($clientId: ID!) {
  clientJobsHierarchy(clientId: $clientId) {
    ... on JobGroupList {
      jobGroups {
        ...JobGroupData
      }
    }
  }
}
    ${JobGroupDataFragmentDoc}`;

/**
 * __useJobsHierarchyQuery__
 *
 * To run a query within a React component, call `useJobsHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsHierarchyQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useJobsHierarchyQuery(baseOptions: Apollo.QueryHookOptions<JobsHierarchyQuery, JobsHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsHierarchyQuery, JobsHierarchyQueryVariables>(JobsHierarchyDocument, options);
      }
export function useJobsHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsHierarchyQuery, JobsHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsHierarchyQuery, JobsHierarchyQueryVariables>(JobsHierarchyDocument, options);
        }
export type JobsHierarchyQueryHookResult = ReturnType<typeof useJobsHierarchyQuery>;
export type JobsHierarchyLazyQueryHookResult = ReturnType<typeof useJobsHierarchyLazyQuery>;
export type JobsHierarchyQueryResult = Apollo.QueryResult<JobsHierarchyQuery, JobsHierarchyQueryVariables>;
export const JobsHierarchyStatisticsDocument = gql`
    query JobsHierarchyStatistics($clientId: ID!) {
  jobOfferStatistics(clientId: $clientId) {
    ...JobStatistics
  }
}
    ${JobStatisticsFragmentDoc}`;

/**
 * __useJobsHierarchyStatisticsQuery__
 *
 * To run a query within a React component, call `useJobsHierarchyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsHierarchyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsHierarchyStatisticsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useJobsHierarchyStatisticsQuery(baseOptions: Apollo.QueryHookOptions<JobsHierarchyStatisticsQuery, JobsHierarchyStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsHierarchyStatisticsQuery, JobsHierarchyStatisticsQueryVariables>(JobsHierarchyStatisticsDocument, options);
      }
export function useJobsHierarchyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsHierarchyStatisticsQuery, JobsHierarchyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsHierarchyStatisticsQuery, JobsHierarchyStatisticsQueryVariables>(JobsHierarchyStatisticsDocument, options);
        }
export type JobsHierarchyStatisticsQueryHookResult = ReturnType<typeof useJobsHierarchyStatisticsQuery>;
export type JobsHierarchyStatisticsLazyQueryHookResult = ReturnType<typeof useJobsHierarchyStatisticsLazyQuery>;
export type JobsHierarchyStatisticsQueryResult = Apollo.QueryResult<JobsHierarchyStatisticsQuery, JobsHierarchyStatisticsQueryVariables>;
export const SingleJobOfferDocument = gql`
    query SingleJobOffer($jobOfferId: ID!) {
  jobOffer(id: $jobOfferId) {
    ...JobOfferData
    ...OnNotFound
  }
}
    ${JobOfferDataFragmentDoc}
${OnNotFoundFragmentDoc}`;

/**
 * __useSingleJobOfferQuery__
 *
 * To run a query within a React component, call `useSingleJobOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleJobOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleJobOfferQuery({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *   },
 * });
 */
export function useSingleJobOfferQuery(baseOptions: Apollo.QueryHookOptions<SingleJobOfferQuery, SingleJobOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleJobOfferQuery, SingleJobOfferQueryVariables>(SingleJobOfferDocument, options);
      }
export function useSingleJobOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleJobOfferQuery, SingleJobOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleJobOfferQuery, SingleJobOfferQueryVariables>(SingleJobOfferDocument, options);
        }
export type SingleJobOfferQueryHookResult = ReturnType<typeof useSingleJobOfferQuery>;
export type SingleJobOfferLazyQueryHookResult = ReturnType<typeof useSingleJobOfferLazyQuery>;
export type SingleJobOfferQueryResult = Apollo.QueryResult<SingleJobOfferQuery, SingleJobOfferQueryVariables>;
export const ScriptsClientAndBranchFieldOptionsDocument = gql`
    query ScriptsClientAndBranchFieldOptions($clientId: ID!) {
  scripts(clientId: $clientId, status: ACTIVE) {
    id
    name
  }
  clientFieldOptions(clientId: $clientId)
}
    `;

/**
 * __useScriptsClientAndBranchFieldOptionsQuery__
 *
 * To run a query within a React component, call `useScriptsClientAndBranchFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptsClientAndBranchFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptsClientAndBranchFieldOptionsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useScriptsClientAndBranchFieldOptionsQuery(baseOptions: Apollo.QueryHookOptions<ScriptsClientAndBranchFieldOptionsQuery, ScriptsClientAndBranchFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptsClientAndBranchFieldOptionsQuery, ScriptsClientAndBranchFieldOptionsQueryVariables>(ScriptsClientAndBranchFieldOptionsDocument, options);
      }
export function useScriptsClientAndBranchFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptsClientAndBranchFieldOptionsQuery, ScriptsClientAndBranchFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptsClientAndBranchFieldOptionsQuery, ScriptsClientAndBranchFieldOptionsQueryVariables>(ScriptsClientAndBranchFieldOptionsDocument, options);
        }
export type ScriptsClientAndBranchFieldOptionsQueryHookResult = ReturnType<typeof useScriptsClientAndBranchFieldOptionsQuery>;
export type ScriptsClientAndBranchFieldOptionsLazyQueryHookResult = ReturnType<typeof useScriptsClientAndBranchFieldOptionsLazyQuery>;
export type ScriptsClientAndBranchFieldOptionsQueryResult = Apollo.QueryResult<ScriptsClientAndBranchFieldOptionsQuery, ScriptsClientAndBranchFieldOptionsQueryVariables>;
export const SessionDocument = gql`
    query Session($sessionId: ID!) {
  session(id: $sessionId) {
    ... on Session {
      id
      status
      recommended
      reportedIssueType
      reportedIssueDetail
      reasonForRecommendation
      notes
      canceled
      createdAt
      location
      moderationStatus
      recordingUrl
      sessionTranscription {
        ...SessionTranscriptionData
      }
      jobOffer {
        id
        clientOfClient
        locations
        title
        jobGroup {
          id
          path
          deletedAt
        }
      }
      applicant {
        id
        email
        firstName
        lastName
        phone
      }
      vetter {
        id
        firstName
        photoHighUrl
        photoLowUrl
      }
      questions {
        ...SessionScriptQuestionAndAnswerData
      }
    }
  }
}
    ${SessionTranscriptionDataFragmentDoc}
${SessionScriptQuestionAndAnswerDataFragmentDoc}`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionQuery(baseOptions: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const ClientDashboardDocument = gql`
    query ClientDashboard($clientId: ID!, $userId: ID!) {
  clientDashboard(clientId: $clientId, userId: $userId) {
    available
    engaged
    passed
    vetted
  }
  user(userId: $userId) {
    firstName
    lastName
  }
}
    `;

/**
 * __useClientDashboardQuery__
 *
 * To run a query within a React component, call `useClientDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDashboardQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useClientDashboardQuery(baseOptions: Apollo.QueryHookOptions<ClientDashboardQuery, ClientDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDashboardQuery, ClientDashboardQueryVariables>(ClientDashboardDocument, options);
      }
export function useClientDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDashboardQuery, ClientDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDashboardQuery, ClientDashboardQueryVariables>(ClientDashboardDocument, options);
        }
export type ClientDashboardQueryHookResult = ReturnType<typeof useClientDashboardQuery>;
export type ClientDashboardLazyQueryHookResult = ReturnType<typeof useClientDashboardLazyQuery>;
export type ClientDashboardQueryResult = Apollo.QueryResult<ClientDashboardQuery, ClientDashboardQueryVariables>;
export const ClientExportDetailedDataDocument = gql`
    query ClientExportDetailedData($clientId: ID!) {
  clientExportDetailedData(clientId: $clientId) {
    applicant
    channel
    client
    createdAt
    job
    location
    passed
    phone
    status
    vetted
    interviewer
  }
}
    `;

/**
 * __useClientExportDetailedDataQuery__
 *
 * To run a query within a React component, call `useClientExportDetailedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientExportDetailedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientExportDetailedDataQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientExportDetailedDataQuery(baseOptions: Apollo.QueryHookOptions<ClientExportDetailedDataQuery, ClientExportDetailedDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientExportDetailedDataQuery, ClientExportDetailedDataQueryVariables>(ClientExportDetailedDataDocument, options);
      }
export function useClientExportDetailedDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientExportDetailedDataQuery, ClientExportDetailedDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientExportDetailedDataQuery, ClientExportDetailedDataQueryVariables>(ClientExportDetailedDataDocument, options);
        }
export type ClientExportDetailedDataQueryHookResult = ReturnType<typeof useClientExportDetailedDataQuery>;
export type ClientExportDetailedDataLazyQueryHookResult = ReturnType<typeof useClientExportDetailedDataLazyQuery>;
export type ClientExportDetailedDataQueryResult = Apollo.QueryResult<ClientExportDetailedDataQuery, ClientExportDetailedDataQueryVariables>;
export const ClientExportPerformanceDataDocument = gql`
    query ClientExportPerformanceData($clientId: ID!) {
  clientExportPerformanceData(clientId: $clientId) {
    client
    job
    passed
    vetted
    internalInterviewer
    externalInterviewer
  }
}
    `;

/**
 * __useClientExportPerformanceDataQuery__
 *
 * To run a query within a React component, call `useClientExportPerformanceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientExportPerformanceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientExportPerformanceDataQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientExportPerformanceDataQuery(baseOptions: Apollo.QueryHookOptions<ClientExportPerformanceDataQuery, ClientExportPerformanceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientExportPerformanceDataQuery, ClientExportPerformanceDataQueryVariables>(ClientExportPerformanceDataDocument, options);
      }
export function useClientExportPerformanceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientExportPerformanceDataQuery, ClientExportPerformanceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientExportPerformanceDataQuery, ClientExportPerformanceDataQueryVariables>(ClientExportPerformanceDataDocument, options);
        }
export type ClientExportPerformanceDataQueryHookResult = ReturnType<typeof useClientExportPerformanceDataQuery>;
export type ClientExportPerformanceDataLazyQueryHookResult = ReturnType<typeof useClientExportPerformanceDataLazyQuery>;
export type ClientExportPerformanceDataQueryResult = Apollo.QueryResult<ClientExportPerformanceDataQuery, ClientExportPerformanceDataQueryVariables>;
export const ScriptQuestionsPreviewDocument = gql`
    query ScriptQuestionsPreview($scriptId: ID!) {
  script(id: $scriptId) {
    ...OnNotFound
    ... on Script {
      id
      questions {
        ...ScriptQuestionData
      }
    }
  }
}
    ${OnNotFoundFragmentDoc}
${ScriptQuestionDataFragmentDoc}`;

/**
 * __useScriptQuestionsPreviewQuery__
 *
 * To run a query within a React component, call `useScriptQuestionsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptQuestionsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptQuestionsPreviewQuery({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useScriptQuestionsPreviewQuery(baseOptions: Apollo.QueryHookOptions<ScriptQuestionsPreviewQuery, ScriptQuestionsPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptQuestionsPreviewQuery, ScriptQuestionsPreviewQueryVariables>(ScriptQuestionsPreviewDocument, options);
      }
export function useScriptQuestionsPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptQuestionsPreviewQuery, ScriptQuestionsPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptQuestionsPreviewQuery, ScriptQuestionsPreviewQueryVariables>(ScriptQuestionsPreviewDocument, options);
        }
export type ScriptQuestionsPreviewQueryHookResult = ReturnType<typeof useScriptQuestionsPreviewQuery>;
export type ScriptQuestionsPreviewLazyQueryHookResult = ReturnType<typeof useScriptQuestionsPreviewLazyQuery>;
export type ScriptQuestionsPreviewQueryResult = Apollo.QueryResult<ScriptQuestionsPreviewQuery, ScriptQuestionsPreviewQueryVariables>;
export const SessionsDocument = gql`
    query Sessions($clientId: ID!, $filter: SessionsFilterInput!, $searchTerm: String, $pagination: PaginationInput!) {
  sessions(
    clientId: $clientId
    filter: $filter
    searchTerm: $searchTerm
    pagination: $pagination
  ) {
    data {
      ...SessionMinifiedData
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${SessionMinifiedDataFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      filter: // value for 'filter'
 *      searchTerm: // value for 'searchTerm'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSessionsQuery(baseOptions: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
      }
export function useSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
        }
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<typeof useSessionsLazyQuery>;
export type SessionsQueryResult = Apollo.QueryResult<SessionsQuery, SessionsQueryVariables>;
export const SessionsStatisticsDocument = gql`
    query SessionsStatistics($clientId: ID!, $filter: SessionsFilterInput!, $searchTerm: String) {
  sessionsStatistics(
    clientId: $clientId
    filter: $filter
    searchTerm: $searchTerm
  ) {
    id
    all
    passed
    failed
    incomplete
    knockoutFailed
    flagged
  }
}
    `;

/**
 * __useSessionsStatisticsQuery__
 *
 * To run a query within a React component, call `useSessionsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsStatisticsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      filter: // value for 'filter'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSessionsStatisticsQuery(baseOptions: Apollo.QueryHookOptions<SessionsStatisticsQuery, SessionsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsStatisticsQuery, SessionsStatisticsQueryVariables>(SessionsStatisticsDocument, options);
      }
export function useSessionsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsStatisticsQuery, SessionsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsStatisticsQuery, SessionsStatisticsQueryVariables>(SessionsStatisticsDocument, options);
        }
export type SessionsStatisticsQueryHookResult = ReturnType<typeof useSessionsStatisticsQuery>;
export type SessionsStatisticsLazyQueryHookResult = ReturnType<typeof useSessionsStatisticsLazyQuery>;
export type SessionsStatisticsQueryResult = Apollo.QueryResult<SessionsStatisticsQuery, SessionsStatisticsQueryVariables>;
export const ClientUsersDocument = gql`
    query ClientUsers($clientId: ID!, $filter: ClientUsersFilterInput) {
  clientUsers(clientId: $clientId, filter: $filter) {
    ...ClientUserData
  }
}
    ${ClientUserDataFragmentDoc}`;

/**
 * __useClientUsersQuery__
 *
 * To run a query within a React component, call `useClientUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUsersQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClientUsersQuery(baseOptions: Apollo.QueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
      }
export function useClientUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
        }
export type ClientUsersQueryHookResult = ReturnType<typeof useClientUsersQuery>;
export type ClientUsersLazyQueryHookResult = ReturnType<typeof useClientUsersLazyQuery>;
export type ClientUsersQueryResult = Apollo.QueryResult<ClientUsersQuery, ClientUsersQueryVariables>;
export const ClientUsersWithAccessToJobGroupDocument = gql`
    query ClientUsersWithAccessToJobGroup($clientId: ID!, $jobGroupPath: String!) {
  clientUsersWithAccessToJobGroup(
    clientId: $clientId
    jobGroupPath: $jobGroupPath
  ) {
    ...ClientUserAccessData
  }
}
    ${ClientUserAccessDataFragmentDoc}`;

/**
 * __useClientUsersWithAccessToJobGroupQuery__
 *
 * To run a query within a React component, call `useClientUsersWithAccessToJobGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientUsersWithAccessToJobGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUsersWithAccessToJobGroupQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      jobGroupPath: // value for 'jobGroupPath'
 *   },
 * });
 */
export function useClientUsersWithAccessToJobGroupQuery(baseOptions: Apollo.QueryHookOptions<ClientUsersWithAccessToJobGroupQuery, ClientUsersWithAccessToJobGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientUsersWithAccessToJobGroupQuery, ClientUsersWithAccessToJobGroupQueryVariables>(ClientUsersWithAccessToJobGroupDocument, options);
      }
export function useClientUsersWithAccessToJobGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientUsersWithAccessToJobGroupQuery, ClientUsersWithAccessToJobGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientUsersWithAccessToJobGroupQuery, ClientUsersWithAccessToJobGroupQueryVariables>(ClientUsersWithAccessToJobGroupDocument, options);
        }
export type ClientUsersWithAccessToJobGroupQueryHookResult = ReturnType<typeof useClientUsersWithAccessToJobGroupQuery>;
export type ClientUsersWithAccessToJobGroupLazyQueryHookResult = ReturnType<typeof useClientUsersWithAccessToJobGroupLazyQuery>;
export type ClientUsersWithAccessToJobGroupQueryResult = Apollo.QueryResult<ClientUsersWithAccessToJobGroupQuery, ClientUsersWithAccessToJobGroupQueryVariables>;
export const TeamMembersDocument = gql`
    query TeamMembers($clientId: ID!, $pagination: PaginationInput!, $filter: TeamMembersFilterInput, $searchTerm: String) {
  teamMembers(
    clientId: $clientId
    pagination: $pagination
    filter: $filter
    searchTerm: $searchTerm
  ) {
    data {
      ...ClientUserAccessData
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ClientUserAccessDataFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const ClientByInvitationKeyDocument = gql`
    query ClientByInvitationKey($invitationKey: String!) {
  clientByInvitationKey(invitationKey: $invitationKey) {
    ... on Client {
      id
      companyName
    }
    ... on NotFoundError {
      code
      message
    }
  }
}
    `;

/**
 * __useClientByInvitationKeyQuery__
 *
 * To run a query within a React component, call `useClientByInvitationKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientByInvitationKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientByInvitationKeyQuery({
 *   variables: {
 *      invitationKey: // value for 'invitationKey'
 *   },
 * });
 */
export function useClientByInvitationKeyQuery(baseOptions: Apollo.QueryHookOptions<ClientByInvitationKeyQuery, ClientByInvitationKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientByInvitationKeyQuery, ClientByInvitationKeyQueryVariables>(ClientByInvitationKeyDocument, options);
      }
export function useClientByInvitationKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientByInvitationKeyQuery, ClientByInvitationKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientByInvitationKeyQuery, ClientByInvitationKeyQueryVariables>(ClientByInvitationKeyDocument, options);
        }
export type ClientByInvitationKeyQueryHookResult = ReturnType<typeof useClientByInvitationKeyQuery>;
export type ClientByInvitationKeyLazyQueryHookResult = ReturnType<typeof useClientByInvitationKeyLazyQuery>;
export type ClientByInvitationKeyQueryResult = Apollo.QueryResult<ClientByInvitationKeyQuery, ClientByInvitationKeyQueryVariables>;
export const ClientUpdatedDocument = gql`
    subscription ClientUpdated($clientId: ID) {
  clientUpdated(clientId: $clientId) {
    ... on Client {
      id
    }
  }
}
    `;

/**
 * __useClientUpdatedSubscription__
 *
 * To run a query within a React component, call `useClientUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUpdatedSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ClientUpdatedSubscription, ClientUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientUpdatedSubscription, ClientUpdatedSubscriptionVariables>(ClientUpdatedDocument, options);
      }
export type ClientUpdatedSubscriptionHookResult = ReturnType<typeof useClientUpdatedSubscription>;
export type ClientUpdatedSubscriptionResult = Apollo.SubscriptionResult<ClientUpdatedSubscription>;
export const SessionChangedDocument = gql`
    subscription SessionChanged {
  sessionChanged {
    ... on Session {
      id
      status
    }
  }
}
    `;

/**
 * __useSessionChangedSubscription__
 *
 * To run a query within a React component, call `useSessionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSessionChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SessionChangedSubscription, SessionChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SessionChangedSubscription, SessionChangedSubscriptionVariables>(SessionChangedDocument, options);
      }
export type SessionChangedSubscriptionHookResult = ReturnType<typeof useSessionChangedSubscription>;
export type SessionChangedSubscriptionResult = Apollo.SubscriptionResult<SessionChangedSubscription>;
export const LoginUserDocument = gql`
    mutation LoginUser {
  loginUser
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
  updateUser(userId: $userId, input: $input) {
    ... on User {
      ...UserData
    }
    ... on ValidationError {
      fields
      messages
    }
  }
}
    ${UserDataFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DisablePushNotificationDocument = gql`
    mutation DisablePushNotification($userId: ID!) {
  userDisablePushNotification(userId: $userId) {
    id
    pushNotificationsEnabled
  }
}
    `;
export type DisablePushNotificationMutationFn = Apollo.MutationFunction<DisablePushNotificationMutation, DisablePushNotificationMutationVariables>;

/**
 * __useDisablePushNotificationMutation__
 *
 * To run a mutation, you first call `useDisablePushNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePushNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePushNotificationMutation, { data, loading, error }] = useDisablePushNotificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisablePushNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DisablePushNotificationMutation, DisablePushNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePushNotificationMutation, DisablePushNotificationMutationVariables>(DisablePushNotificationDocument, options);
      }
export type DisablePushNotificationMutationHookResult = ReturnType<typeof useDisablePushNotificationMutation>;
export type DisablePushNotificationMutationResult = Apollo.MutationResult<DisablePushNotificationMutation>;
export type DisablePushNotificationMutationOptions = Apollo.BaseMutationOptions<DisablePushNotificationMutation, DisablePushNotificationMutationVariables>;
export const AddPushNotificationTokenDocument = gql`
    mutation AddPushNotificationToken($userId: ID!, $token: String!) {
  userAddPushNotificationToken(userId: $userId, token: $token) {
    id
    pushNotificationsEnabled
  }
}
    `;
export type AddPushNotificationTokenMutationFn = Apollo.MutationFunction<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>;

/**
 * __useAddPushNotificationTokenMutation__
 *
 * To run a mutation, you first call `useAddPushNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPushNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPushNotificationTokenMutation, { data, loading, error }] = useAddPushNotificationTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddPushNotificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>(AddPushNotificationTokenDocument, options);
      }
export type AddPushNotificationTokenMutationHookResult = ReturnType<typeof useAddPushNotificationTokenMutation>;
export type AddPushNotificationTokenMutationResult = Apollo.MutationResult<AddPushNotificationTokenMutation>;
export type AddPushNotificationTokenMutationOptions = Apollo.BaseMutationOptions<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>;
export const UpdatePresenceDocument = gql`
    mutation UpdatePresence($userId: ID!, $presence: UserPresenceEnum!) {
  updateUserPresence(userId: $userId, presence: $presence) {
    ... on User {
      id
      presence
    }
    ... on ValidationError {
      fields
      messages
    }
  }
}
    `;
export type UpdatePresenceMutationFn = Apollo.MutationFunction<UpdatePresenceMutation, UpdatePresenceMutationVariables>;

/**
 * __useUpdatePresenceMutation__
 *
 * To run a mutation, you first call `useUpdatePresenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresenceMutation, { data, loading, error }] = useUpdatePresenceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      presence: // value for 'presence'
 *   },
 * });
 */
export function useUpdatePresenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePresenceMutation, UpdatePresenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePresenceMutation, UpdatePresenceMutationVariables>(UpdatePresenceDocument, options);
      }
export type UpdatePresenceMutationHookResult = ReturnType<typeof useUpdatePresenceMutation>;
export type UpdatePresenceMutationResult = Apollo.MutationResult<UpdatePresenceMutation>;
export type UpdatePresenceMutationOptions = Apollo.BaseMutationOptions<UpdatePresenceMutation, UpdatePresenceMutationVariables>;
export const SetStripeAccountConnectedDocument = gql`
    mutation SetStripeAccountConnected($userId: ID!) {
  setStripeAccountConnected(userId: $userId) {
    ... on StripeUser {
      id
      stripeAccountConnected
    }
  }
}
    `;
export type SetStripeAccountConnectedMutationFn = Apollo.MutationFunction<SetStripeAccountConnectedMutation, SetStripeAccountConnectedMutationVariables>;

/**
 * __useSetStripeAccountConnectedMutation__
 *
 * To run a mutation, you first call `useSetStripeAccountConnectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStripeAccountConnectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStripeAccountConnectedMutation, { data, loading, error }] = useSetStripeAccountConnectedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetStripeAccountConnectedMutation(baseOptions?: Apollo.MutationHookOptions<SetStripeAccountConnectedMutation, SetStripeAccountConnectedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStripeAccountConnectedMutation, SetStripeAccountConnectedMutationVariables>(SetStripeAccountConnectedDocument, options);
      }
export type SetStripeAccountConnectedMutationHookResult = ReturnType<typeof useSetStripeAccountConnectedMutation>;
export type SetStripeAccountConnectedMutationResult = Apollo.MutationResult<SetStripeAccountConnectedMutation>;
export type SetStripeAccountConnectedMutationOptions = Apollo.BaseMutationOptions<SetStripeAccountConnectedMutation, SetStripeAccountConnectedMutationVariables>;
export const SetOnboardingCompletedDocument = gql`
    mutation SetOnboardingCompleted($userId: ID!) {
  setOnboardingCompleted(userId: $userId) {
    ... on User {
      id
      onboardingCompleted
    }
  }
}
    `;
export type SetOnboardingCompletedMutationFn = Apollo.MutationFunction<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>;

/**
 * __useSetOnboardingCompletedMutation__
 *
 * To run a mutation, you first call `useSetOnboardingCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOnboardingCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOnboardingCompletedMutation, { data, loading, error }] = useSetOnboardingCompletedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetOnboardingCompletedMutation(baseOptions?: Apollo.MutationHookOptions<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>(SetOnboardingCompletedDocument, options);
      }
export type SetOnboardingCompletedMutationHookResult = ReturnType<typeof useSetOnboardingCompletedMutation>;
export type SetOnboardingCompletedMutationResult = Apollo.MutationResult<SetOnboardingCompletedMutation>;
export type SetOnboardingCompletedMutationOptions = Apollo.BaseMutationOptions<SetOnboardingCompletedMutation, SetOnboardingCompletedMutationVariables>;
export const UpdateUserPhoneDocument = gql`
    mutation UpdateUserPhone($firebaseUid: String!, $phone: String!) {
  updateUserPhone(firebaseUid: $firebaseUid, phone: $phone) {
    ... on ValidationError {
      messages
    }
    ... on User {
      id
      phone
    }
  }
}
    `;
export type UpdateUserPhoneMutationFn = Apollo.MutationFunction<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>;

/**
 * __useUpdateUserPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneMutation, { data, loading, error }] = useUpdateUserPhoneMutation({
 *   variables: {
 *      firebaseUid: // value for 'firebaseUid'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserPhoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>(UpdateUserPhoneDocument, options);
      }
export type UpdateUserPhoneMutationHookResult = ReturnType<typeof useUpdateUserPhoneMutation>;
export type UpdateUserPhoneMutationResult = Apollo.MutationResult<UpdateUserPhoneMutation>;
export type UpdateUserPhoneMutationOptions = Apollo.BaseMutationOptions<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>;
export const UserWithPhoneExistsDocument = gql`
    query UserWithPhoneExists($input: UserWithPhoneExistsInput!) {
  userWithPhoneExists(input: $input)
}
    `;

/**
 * __useUserWithPhoneExistsQuery__
 *
 * To run a query within a React component, call `useUserWithPhoneExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithPhoneExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithPhoneExistsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserWithPhoneExistsQuery(baseOptions: Apollo.QueryHookOptions<UserWithPhoneExistsQuery, UserWithPhoneExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithPhoneExistsQuery, UserWithPhoneExistsQueryVariables>(UserWithPhoneExistsDocument, options);
      }
export function useUserWithPhoneExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithPhoneExistsQuery, UserWithPhoneExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithPhoneExistsQuery, UserWithPhoneExistsQueryVariables>(UserWithPhoneExistsDocument, options);
        }
export type UserWithPhoneExistsQueryHookResult = ReturnType<typeof useUserWithPhoneExistsQuery>;
export type UserWithPhoneExistsLazyQueryHookResult = ReturnType<typeof useUserWithPhoneExistsLazyQuery>;
export type UserWithPhoneExistsQueryResult = Apollo.QueryResult<UserWithPhoneExistsQuery, UserWithPhoneExistsQueryVariables>;
export const UserDocument = gql`
    query User($userId: ID!) {
  user(userId: $userId) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserByFirebaseUidForHotjarDocument = gql`
    query UserByFirebaseUidForHotjar($firebaseUid: String!) {
  userByFirebaseUid(firebaseUid: $firebaseUid) {
    ...UserForHotjar
  }
}
    ${UserForHotjarFragmentDoc}`;

/**
 * __useUserByFirebaseUidForHotjarQuery__
 *
 * To run a query within a React component, call `useUserByFirebaseUidForHotjarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByFirebaseUidForHotjarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByFirebaseUidForHotjarQuery({
 *   variables: {
 *      firebaseUid: // value for 'firebaseUid'
 *   },
 * });
 */
export function useUserByFirebaseUidForHotjarQuery(baseOptions: Apollo.QueryHookOptions<UserByFirebaseUidForHotjarQuery, UserByFirebaseUidForHotjarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByFirebaseUidForHotjarQuery, UserByFirebaseUidForHotjarQueryVariables>(UserByFirebaseUidForHotjarDocument, options);
      }
export function useUserByFirebaseUidForHotjarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByFirebaseUidForHotjarQuery, UserByFirebaseUidForHotjarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByFirebaseUidForHotjarQuery, UserByFirebaseUidForHotjarQueryVariables>(UserByFirebaseUidForHotjarDocument, options);
        }
export type UserByFirebaseUidForHotjarQueryHookResult = ReturnType<typeof useUserByFirebaseUidForHotjarQuery>;
export type UserByFirebaseUidForHotjarLazyQueryHookResult = ReturnType<typeof useUserByFirebaseUidForHotjarLazyQuery>;
export type UserByFirebaseUidForHotjarQueryResult = Apollo.QueryResult<UserByFirebaseUidForHotjarQuery, UserByFirebaseUidForHotjarQueryVariables>;
export const UserPresenceDocument = gql`
    query UserPresence($userId: ID!) {
  user(userId: $userId) {
    id
    presence
    firstName
    lastName
    photoHighUrl
    isProfileDataProvided
    isProfilePhotoProvided
    approvalStatus
    onboardingCompleted
  }
}
    `;

/**
 * __useUserPresenceQuery__
 *
 * To run a query within a React component, call `useUserPresenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPresenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPresenceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPresenceQuery(baseOptions: Apollo.QueryHookOptions<UserPresenceQuery, UserPresenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPresenceQuery, UserPresenceQueryVariables>(UserPresenceDocument, options);
      }
export function useUserPresenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPresenceQuery, UserPresenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPresenceQuery, UserPresenceQueryVariables>(UserPresenceDocument, options);
        }
export type UserPresenceQueryHookResult = ReturnType<typeof useUserPresenceQuery>;
export type UserPresenceLazyQueryHookResult = ReturnType<typeof useUserPresenceLazyQuery>;
export type UserPresenceQueryResult = Apollo.QueryResult<UserPresenceQuery, UserPresenceQueryVariables>;
export const StoreVetterAnswerDocument = gql`
    mutation StoreVetterAnswer($sessionId: ID!, $input: ScriptAnswerInput!) {
  storeVetterAnswer(sessionId: $sessionId, input: $input) {
    id
  }
}
    `;
export type StoreVetterAnswerMutationFn = Apollo.MutationFunction<StoreVetterAnswerMutation, StoreVetterAnswerMutationVariables>;

/**
 * __useStoreVetterAnswerMutation__
 *
 * To run a mutation, you first call `useStoreVetterAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreVetterAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeVetterAnswerMutation, { data, loading, error }] = useStoreVetterAnswerMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreVetterAnswerMutation(baseOptions?: Apollo.MutationHookOptions<StoreVetterAnswerMutation, StoreVetterAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreVetterAnswerMutation, StoreVetterAnswerMutationVariables>(StoreVetterAnswerDocument, options);
      }
export type StoreVetterAnswerMutationHookResult = ReturnType<typeof useStoreVetterAnswerMutation>;
export type StoreVetterAnswerMutationResult = Apollo.MutationResult<StoreVetterAnswerMutation>;
export type StoreVetterAnswerMutationOptions = Apollo.BaseMutationOptions<StoreVetterAnswerMutation, StoreVetterAnswerMutationVariables>;
export const VetterUpdateSessionStatusDocument = gql`
    mutation VetterUpdateSessionStatus($sessionId: ID!, $newSessionStatus: SessionUpdateStatusEnum!) {
  vetterUpdateSessionStatus(
    sessionId: $sessionId
    newSessionStatus: $newSessionStatus
  ) {
    ...OnValidationError
    ... on SuccessResult {
      message
    }
  }
}
    ${OnValidationErrorFragmentDoc}`;
export type VetterUpdateSessionStatusMutationFn = Apollo.MutationFunction<VetterUpdateSessionStatusMutation, VetterUpdateSessionStatusMutationVariables>;

/**
 * __useVetterUpdateSessionStatusMutation__
 *
 * To run a mutation, you first call `useVetterUpdateSessionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVetterUpdateSessionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vetterUpdateSessionStatusMutation, { data, loading, error }] = useVetterUpdateSessionStatusMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      newSessionStatus: // value for 'newSessionStatus'
 *   },
 * });
 */
export function useVetterUpdateSessionStatusMutation(baseOptions?: Apollo.MutationHookOptions<VetterUpdateSessionStatusMutation, VetterUpdateSessionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VetterUpdateSessionStatusMutation, VetterUpdateSessionStatusMutationVariables>(VetterUpdateSessionStatusDocument, options);
      }
export type VetterUpdateSessionStatusMutationHookResult = ReturnType<typeof useVetterUpdateSessionStatusMutation>;
export type VetterUpdateSessionStatusMutationResult = Apollo.MutationResult<VetterUpdateSessionStatusMutation>;
export type VetterUpdateSessionStatusMutationOptions = Apollo.BaseMutationOptions<VetterUpdateSessionStatusMutation, VetterUpdateSessionStatusMutationVariables>;
export const SignupVetterDocument = gql`
    mutation SignupVetter($input: SignupVetterInput!) {
  signupVetter(input: $input) {
    ... on User {
      id
    }
    ... on ValidationError {
      code
      fields
      messages
    }
  }
}
    `;
export type SignupVetterMutationFn = Apollo.MutationFunction<SignupVetterMutation, SignupVetterMutationVariables>;

/**
 * __useSignupVetterMutation__
 *
 * To run a mutation, you first call `useSignupVetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupVetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupVetterMutation, { data, loading, error }] = useSignupVetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupVetterMutation(baseOptions?: Apollo.MutationHookOptions<SignupVetterMutation, SignupVetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupVetterMutation, SignupVetterMutationVariables>(SignupVetterDocument, options);
      }
export type SignupVetterMutationHookResult = ReturnType<typeof useSignupVetterMutation>;
export type SignupVetterMutationResult = Apollo.MutationResult<SignupVetterMutation>;
export type SignupVetterMutationOptions = Apollo.BaseMutationOptions<SignupVetterMutation, SignupVetterMutationVariables>;
export const AcceptVetterSessionDocument = gql`
    mutation AcceptVetterSession($sessionId: ID!) {
  acceptVetterSession(sessionId: $sessionId) {
    ... on NotFoundError {
      code
      message
    }
    ... on ValidationError {
      code
      fields
      messages
    }
  }
}
    `;
export type AcceptVetterSessionMutationFn = Apollo.MutationFunction<AcceptVetterSessionMutation, AcceptVetterSessionMutationVariables>;

/**
 * __useAcceptVetterSessionMutation__
 *
 * To run a mutation, you first call `useAcceptVetterSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptVetterSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptVetterSessionMutation, { data, loading, error }] = useAcceptVetterSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAcceptVetterSessionMutation(baseOptions?: Apollo.MutationHookOptions<AcceptVetterSessionMutation, AcceptVetterSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptVetterSessionMutation, AcceptVetterSessionMutationVariables>(AcceptVetterSessionDocument, options);
      }
export type AcceptVetterSessionMutationHookResult = ReturnType<typeof useAcceptVetterSessionMutation>;
export type AcceptVetterSessionMutationResult = Apollo.MutationResult<AcceptVetterSessionMutation>;
export type AcceptVetterSessionMutationOptions = Apollo.BaseMutationOptions<AcceptVetterSessionMutation, AcceptVetterSessionMutationVariables>;
export const RejectVetterSessionDocument = gql`
    mutation RejectVetterSession($sessionId: ID!) {
  rejectVetterSession(sessionId: $sessionId) {
    ... on NotFoundError {
      code
      message
    }
    ... on ValidationError {
      code
      fields
      messages
    }
  }
}
    `;
export type RejectVetterSessionMutationFn = Apollo.MutationFunction<RejectVetterSessionMutation, RejectVetterSessionMutationVariables>;

/**
 * __useRejectVetterSessionMutation__
 *
 * To run a mutation, you first call `useRejectVetterSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectVetterSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectVetterSessionMutation, { data, loading, error }] = useRejectVetterSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useRejectVetterSessionMutation(baseOptions?: Apollo.MutationHookOptions<RejectVetterSessionMutation, RejectVetterSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectVetterSessionMutation, RejectVetterSessionMutationVariables>(RejectVetterSessionDocument, options);
      }
export type RejectVetterSessionMutationHookResult = ReturnType<typeof useRejectVetterSessionMutation>;
export type RejectVetterSessionMutationResult = Apollo.MutationResult<RejectVetterSessionMutation>;
export type RejectVetterSessionMutationOptions = Apollo.BaseMutationOptions<RejectVetterSessionMutation, RejectVetterSessionMutationVariables>;
export const SubmitVetterInterviewDocument = gql`
    mutation SubmitVetterInterview($sessionId: ID!, $input: SubmitVetterInterviewInput!) {
  submitVetterInterview(sessionId: $sessionId, input: $input)
}
    `;
export type SubmitVetterInterviewMutationFn = Apollo.MutationFunction<SubmitVetterInterviewMutation, SubmitVetterInterviewMutationVariables>;

/**
 * __useSubmitVetterInterviewMutation__
 *
 * To run a mutation, you first call `useSubmitVetterInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVetterInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVetterInterviewMutation, { data, loading, error }] = useSubmitVetterInterviewMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitVetterInterviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitVetterInterviewMutation, SubmitVetterInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitVetterInterviewMutation, SubmitVetterInterviewMutationVariables>(SubmitVetterInterviewDocument, options);
      }
export type SubmitVetterInterviewMutationHookResult = ReturnType<typeof useSubmitVetterInterviewMutation>;
export type SubmitVetterInterviewMutationResult = Apollo.MutationResult<SubmitVetterInterviewMutation>;
export type SubmitVetterInterviewMutationOptions = Apollo.BaseMutationOptions<SubmitVetterInterviewMutation, SubmitVetterInterviewMutationVariables>;
export const GenerateStripeUrlDocument = gql`
    mutation GenerateStripeUrl($userId: ID!) {
  generateStripeUrl(userId: $userId) {
    ... on StripeUrl {
      url
    }
    ... on ValidationError {
      messages
    }
  }
}
    `;
export type GenerateStripeUrlMutationFn = Apollo.MutationFunction<GenerateStripeUrlMutation, GenerateStripeUrlMutationVariables>;

/**
 * __useGenerateStripeUrlMutation__
 *
 * To run a mutation, you first call `useGenerateStripeUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStripeUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStripeUrlMutation, { data, loading, error }] = useGenerateStripeUrlMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGenerateStripeUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateStripeUrlMutation, GenerateStripeUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateStripeUrlMutation, GenerateStripeUrlMutationVariables>(GenerateStripeUrlDocument, options);
      }
export type GenerateStripeUrlMutationHookResult = ReturnType<typeof useGenerateStripeUrlMutation>;
export type GenerateStripeUrlMutationResult = Apollo.MutationResult<GenerateStripeUrlMutation>;
export type GenerateStripeUrlMutationOptions = Apollo.BaseMutationOptions<GenerateStripeUrlMutation, GenerateStripeUrlMutationVariables>;
export const VetterLogDocument = gql`
    mutation VetterLog($userId: ID!, $globalActivityHistoryEvent: VetterDebugLogEventsEnum!, $metadata: JSON) {
  vetterLog(
    userId: $userId
    globalActivityHistoryEvent: $globalActivityHistoryEvent
    metadata: $metadata
  )
}
    `;
export type VetterLogMutationFn = Apollo.MutationFunction<VetterLogMutation, VetterLogMutationVariables>;

/**
 * __useVetterLogMutation__
 *
 * To run a mutation, you first call `useVetterLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVetterLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vetterLogMutation, { data, loading, error }] = useVetterLogMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      globalActivityHistoryEvent: // value for 'globalActivityHistoryEvent'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useVetterLogMutation(baseOptions?: Apollo.MutationHookOptions<VetterLogMutation, VetterLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VetterLogMutation, VetterLogMutationVariables>(VetterLogDocument, options);
      }
export type VetterLogMutationHookResult = ReturnType<typeof useVetterLogMutation>;
export type VetterLogMutationResult = Apollo.MutationResult<VetterLogMutation>;
export type VetterLogMutationOptions = Apollo.BaseMutationOptions<VetterLogMutation, VetterLogMutationVariables>;
export const InterviewDocument = gql`
    query Interview($sessionId: ID!) {
  sessionScriptQuestions(sessionId: $sessionId) {
    ... on SessionScriptQuestions {
      questions {
        ...Question
      }
    }
    ...OnNotFound
  }
  session(id: $sessionId) {
    ... on Session {
      id
      location
      applicant {
        id
        firstName
        lastName
        email
        phone
      }
      scriptVersion
      jobOffer {
        id
        client {
          id
          companyName
        }
        additionalDescription
        description
        locations
        salary
        title
      }
      vetter {
        id
        firstName
        lastName
      }
    }
    ...OnNotFound
  }
}
    ${QuestionFragmentDoc}
${OnNotFoundFragmentDoc}`;

/**
 * __useInterviewQuery__
 *
 * To run a query within a React component, call `useInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useInterviewQuery(baseOptions: Apollo.QueryHookOptions<InterviewQuery, InterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewQuery, InterviewQueryVariables>(InterviewDocument, options);
      }
export function useInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewQuery, InterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewQuery, InterviewQueryVariables>(InterviewDocument, options);
        }
export type InterviewQueryHookResult = ReturnType<typeof useInterviewQuery>;
export type InterviewLazyQueryHookResult = ReturnType<typeof useInterviewLazyQuery>;
export type InterviewQueryResult = Apollo.QueryResult<InterviewQuery, InterviewQueryVariables>;
export const InternalVetterStatisticsDocument = gql`
    query InternalVetterStatistics($clientId: ID!, $userId: ID!) {
  internalVetterStatistics(clientId: $clientId, userId: $userId) {
    all
    passed
    failed
    incomplete
  }
}
    `;

/**
 * __useInternalVetterStatisticsQuery__
 *
 * To run a query within a React component, call `useInternalVetterStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalVetterStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalVetterStatisticsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInternalVetterStatisticsQuery(baseOptions: Apollo.QueryHookOptions<InternalVetterStatisticsQuery, InternalVetterStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InternalVetterStatisticsQuery, InternalVetterStatisticsQueryVariables>(InternalVetterStatisticsDocument, options);
      }
export function useInternalVetterStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalVetterStatisticsQuery, InternalVetterStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InternalVetterStatisticsQuery, InternalVetterStatisticsQueryVariables>(InternalVetterStatisticsDocument, options);
        }
export type InternalVetterStatisticsQueryHookResult = ReturnType<typeof useInternalVetterStatisticsQuery>;
export type InternalVetterStatisticsLazyQueryHookResult = ReturnType<typeof useInternalVetterStatisticsLazyQuery>;
export type InternalVetterStatisticsQueryResult = Apollo.QueryResult<InternalVetterStatisticsQuery, InternalVetterStatisticsQueryVariables>;
export const VetterDashboardDocument = gql`
    query VetterDashboard($userId: ID!) {
  vetterDashboard(userId: $userId) {
    id
    allVetsCount
    stripeUser {
      id
      credit
      stripeDashboardUrl
    }
    onboardingCompleted
    approvalStatus
  }
}
    `;

/**
 * __useVetterDashboardQuery__
 *
 * To run a query within a React component, call `useVetterDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useVetterDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVetterDashboardQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVetterDashboardQuery(baseOptions: Apollo.QueryHookOptions<VetterDashboardQuery, VetterDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VetterDashboardQuery, VetterDashboardQueryVariables>(VetterDashboardDocument, options);
      }
export function useVetterDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VetterDashboardQuery, VetterDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VetterDashboardQuery, VetterDashboardQueryVariables>(VetterDashboardDocument, options);
        }
export type VetterDashboardQueryHookResult = ReturnType<typeof useVetterDashboardQuery>;
export type VetterDashboardLazyQueryHookResult = ReturnType<typeof useVetterDashboardLazyQuery>;
export type VetterDashboardQueryResult = Apollo.QueryResult<VetterDashboardQuery, VetterDashboardQueryVariables>;
export const InternalVetterSessionsDocument = gql`
    query InternalVetterSessions($clientId: ID!, $pagination: PaginationInput!) {
  sessions(clientId: $clientId, pagination: $pagination) {
    data {
      ...SessionData
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${SessionDataFragmentDoc}
${PaginationFragmentFragmentDoc}`;

/**
 * __useInternalVetterSessionsQuery__
 *
 * To run a query within a React component, call `useInternalVetterSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalVetterSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalVetterSessionsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useInternalVetterSessionsQuery(baseOptions: Apollo.QueryHookOptions<InternalVetterSessionsQuery, InternalVetterSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InternalVetterSessionsQuery, InternalVetterSessionsQueryVariables>(InternalVetterSessionsDocument, options);
      }
export function useInternalVetterSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalVetterSessionsQuery, InternalVetterSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InternalVetterSessionsQuery, InternalVetterSessionsQueryVariables>(InternalVetterSessionsDocument, options);
        }
export type InternalVetterSessionsQueryHookResult = ReturnType<typeof useInternalVetterSessionsQuery>;
export type InternalVetterSessionsLazyQueryHookResult = ReturnType<typeof useInternalVetterSessionsLazyQuery>;
export type InternalVetterSessionsQueryResult = Apollo.QueryResult<InternalVetterSessionsQuery, InternalVetterSessionsQueryVariables>;
export const VetterUpdatedDocument = gql`
    subscription VetterUpdated($userId: ID) {
  vetterUpdated(userId: $userId) {
    ... on User {
      id
      firstName
      lastName
      email
      phone
      photoHighUrl
      photoLowUrl
      clientId
      presence
      disabledAt
    }
  }
}
    `;

/**
 * __useVetterUpdatedSubscription__
 *
 * To run a query within a React component, call `useVetterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVetterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVetterUpdatedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVetterUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VetterUpdatedSubscription, VetterUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VetterUpdatedSubscription, VetterUpdatedSubscriptionVariables>(VetterUpdatedDocument, options);
      }
export type VetterUpdatedSubscriptionHookResult = ReturnType<typeof useVetterUpdatedSubscription>;
export type VetterUpdatedSubscriptionResult = Apollo.SubscriptionResult<VetterUpdatedSubscription>;